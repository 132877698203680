import { Typography, Box, Button, List, ListItem } from "@mui/material";
import { PropTypes } from "./LCARecommendations.types";
import { useTranslation } from "react-i18next";

export function LCARecommendations({ setLCARecommendations }: PropTypes) {
	const { t } = useTranslation();

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 4 }}
			>
				{t("lcaRecommendations.title")}
			</Typography>
			<Typography sx={{ marginBottom: 2 }}>
				{t("lcaRecommendations.description")}
			</Typography>

			<List>
				<ListItem
					disablePadding
					sx={{
						marginBottom: 2,
						gap: 2,
						display: "block",
						"& a": { whiteSpace: "break-spaces" },
					}}
				>
					<a
						href="https://www.environdec.com/resources/lca-consultants"
						target="_blank"
						rel="noreferrer"
					>
						{t("lcaRecommendations.linkText")}
					</a>
				</ListItem>
			</List>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
					}}
					onClick={() => {
						setLCARecommendations(false);
					}}
				>
					{t("lcaRecommendations.okButton")}
				</Button>
			</Box>
		</Box>
	);
}
