import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function NotFoundPage() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Container
			component="main"
			maxWidth="sm"
			sx={{
				mt: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
			}}
		>
			<Typography component="h1" variant="h2" gutterBottom>
				{t("notFoundPage.title")}
			</Typography>
			<Typography variant="body1" gutterBottom sx={{ marginBottom: 4 }}>
				{t("notFoundPage.description")}
			</Typography>
			<Button variant="contained" color="primary" onClick={() => navigate("/")}>
				{t("notFoundPage.button")}
			</Button>
		</Container>
	);
}
