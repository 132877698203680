import { IAnalyticsEvent } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { AnalyticsEventType } from "./analyticsEvents.types"

class AnalyticsEvent {
    async create(analyticsEvent: AnalyticsEventType.Create): Promise<IAnalyticsEvent> {
        const endpoint = "/analytics-events/"
        const response = await connectionsClient.client.get(endpoint, { params: analyticsEvent });
        return response.data;
    }
}

export const analyticsEvent = new AnalyticsEvent();