import { ICertification } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { CertificationProcessStepType } from "./certificationProcessStep.types"

class CertificationProcessStep {
    async update({ id, status, rejectReason, manualApprove }: CertificationProcessStepType.UpdateProducts): Promise<ICertification> {
        const path = "/certification-process-steps/"
        const endpoint = path + id
        const response = await connectionsClient.client.patch(endpoint, { id, status, reject_reason: rejectReason, manual_approval: manualApprove });
        return response.data;
    }
    async markItAsPaid({ id }: { id: number }): Promise<ICertification> {
        const path = "/certification-process-steps/"
        const endpoint = path + id
        const response = await connectionsClient.client.patch(endpoint, { payment_status: "COMPLETED", status: "COMPLETED" });
        return response.data;
    }

    async markItAsSigned({ id }: { id: number }): Promise<ICertification> {
        const path = "/certification-process-steps/"
        const endpoint = path + id
        const response = await connectionsClient.client.patch(endpoint, { status: "COMPLETED" });
        return response.data;
    }
}

export const certificationProcessStep = new CertificationProcessStep();