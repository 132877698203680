import { useState, useEffect } from "react";
import {
	Typography,
	Box,
	Autocomplete,
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	CircularProgress,
} from "@mui/material";
import { PropTypes } from "./MultiItemSelector.types";
import { IProduct } from "../../models";
import { ProductStatusChip } from "../ProductDetail/ProductStatusChip";
import { HiOutlineTrash as Trash } from "react-icons/hi2";
import ProductCard from "../ProductCard/ProductCard";
import { BasicModal } from "../BasicModal";
import RenderIfVisible from "react-render-if-visible";
import { useTranslation } from "react-i18next";

export function MultiItemSelector({
	title,
	placeholderAutocomplete,
	buttonTitle,
	listTitle,
	emptyState,
	elements,
	selectedElements,
	setSelectedElements,
	setFilter,
	setShowCreateProductModal,
	external,
	externalLoading,
	selectedMarketplaces,
}: PropTypes) {
	const { t } = useTranslation();
	const [availableElements, setAvailableElements] = useState(elements);
	const [selectedElement, setSelectedElement] = useState<IProduct | null>(null);
	const [tempSelectedElements, setTempSelectedElements] = useState<IProduct[]>(
		[]
	);
	const [selectAll, setSelectAll] = useState(false);
	const [showMoreProducts, setShowMoreProducts] = useState(false);

	const removeElement = (indexToRemove: number) => {
		const updatedElements = [...selectedElements];
		updatedElements.splice(indexToRemove, 1);
		setSelectedElements(updatedElements);
	};

	useEffect(() => {
		const updatedAvailableElements = elements.filter(
			(element) =>
				!selectedElements.some(
					(selectedElement) => selectedElement?.id === element.id
				)
		);
		setAvailableElements(updatedAvailableElements);
	}, [selectedElements, elements]);

	const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectAll(e.target.checked);
		if (e.target.checked) {
			setTempSelectedElements(elements);
		} else {
			setTempSelectedElements([]);
		}
	};

	const handleAddProducts = () => {
		setSelectedElements([...selectedElements, ...tempSelectedElements]);
		setTempSelectedElements([]);
		setShowMoreProducts(false);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					borderBottom: "1px solid #B1D1F0",
					paddingTop: 1,
					paddingBottom: 1,
					paddingLeft: 2,
					paddingRight: 2,
					alignItems: "center",
				}}
			>
				<Typography
					variant="body2"
					sx={{
						fontSize: "14px",
						color: "#757575",
						flex: 1,
						textAlign: "left",
					}}
				>
					{selectedElements.length} {t("multiItemSelector.totalProducts")}
				</Typography>
				<Box
					sx={{
						minHeight: 34,
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-end",
						flex: 1,
					}}
				>
					<Button
						variant="outlined"
						sx={{
							"&.Mui-disabled": {
								backgroundColor: "#fff",
								opacity: 0.4,
							},
							borderRadius: 2,
							maxWidth: 200,
						}}
						onClick={() => {
							setShowMoreProducts(true);
						}}
					>
						{t("multiItemSelector.addMoreClimeCoProducts")}
					</Button>
				</Box>
			</Box>

			<Box
				sx={{
					minHeight: 300,
					padding: 4,
					overflow: "auto",
				}}
			>
				{externalLoading ? (
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress size={20} />
					</Box>
				) : selectedElements && selectedElements.length > 0 ? (
					<Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
						{selectedElements.map((element, index) => {
							return (
								<RenderIfVisible
									defaultHeight={270}
									visibleOffset={10000}
									key={element.id}
								>
									<Box key={element.id}>
										<ProductCard
											element={element}
											index={index}
											setSelectedElements={setSelectedElements}
											selectedElements={selectedElements}
											removeElement={removeElement}
											selectedMarketplaces={selectedMarketplaces}
											availableElements={availableElements}
										/>
									</Box>
								</RenderIfVisible>
							);
						})}
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
						}}
					>
						<Typography variant="body2" sx={{ marginBottom: 2, opacity: 0.4 }}>
							{t("multiItemSelector.emptyState")}
						</Typography>
					</Box>
				)}
			</Box>
			<BasicModal
				width={800}
				showModal={showMoreProducts}
				handleClose={() => {
					setShowMoreProducts(false);
				}}
				children={
					<Box
						sx={{
							width: "100%",
							textAlign: "center",
							padding: 4,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h5"
							sx={{ fontWeight: "bold", marginBottom: 2, maxWidth: 500 }}
						>
							{t("multiItemSelector.addMoreProducts")}
						</Typography>
						<Typography variant="body1" sx={{ marginBottom: 4, maxWidth: 500 }}>
							{t("multiItemSelector.certificationTip")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: {
									xs: "column",
									sm: "row",
									md: "row",
								},
								marginBottom: 4,
							}}
						>
							<Autocomplete
								disablePortal
								id="source-autocomplete"
								options={availableElements.filter(
									(el) =>
										!tempSelectedElements.some(
											(selected) => selected.id === el.id
										)
								)}
								sx={{
									width: {
										xs: 200,
										sm: 300,
										md: 300,
									},
									maxHeight: "200px",
									overflowY: "auto",
									"& input": {
										fontSize: 14,
									},
								}}
								onChange={(_, value) => {
									setTempSelectedElements([...tempSelectedElements, value!]);
									setSelectedElement(null);
								}}
								getOptionDisabled={
									(option) =>
										option.status === "OUTDATED" ||
										!option.lca_documents ||
										option.lca_documents.length === 0 ||
										option.lca_documents.findIndex(
											(doc) =>
												new Date(doc.certification_date) <
												new Date(
													new Date().getTime() - 1095 * 24 * 60 * 60 * 1000
												)
										) > -1 // to be read from settings
								}
								value={selectedElement}
								blurOnSelect={true}
								clearOnBlur={true}
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.id}>
											{option.name +
												(option.asin
													? " - " + option.asin
													: option.upc
													? " - " + option.upc
													: "")}{" "}
											<Box sx={{ marginLeft: 2 }}>
												<ProductStatusChip status={option.status} />
											</Box>
										</li>
									);
								}}
								getOptionKey={(option) => option?.id ?? ""}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<TextField
										{...params}
										InputLabelProps={{ shrink: false }}
										fullWidth
										label=""
										placeholder={t("multiItemSelector.placeholderAutocomplete")}
										onChange={(e) => {
											setFilter(e.target.value);
										}}
									/>
								)}
							/>

							<FormControlLabel
								value="end"
								control={
									<Checkbox
										size="small"
										checked={selectAll}
										onChange={handleSelectAllChange}
									/>
								}
								label={t("multiItemSelector.selectAll")}
								labelPlacement="end"
								sx={{
									marginLeft: 2,
									marginRight: 0.5,
									span: { fontSize: 14 },
								}}
							/>
						</Box>

						<Box
							sx={{
								backgroundColor: "#FAFDFF",
								padding: 4,
								display: "flex",
								flexDirection: "column",
								borderTop: "1px solid #D7EBFF",
								borderBottom: "1px solid #D7EBFF",
								overflow: "auto",
								width: "100%",
								marginBottom: 4,
							}}
						>
							{tempSelectedElements.length > 0 ? (
								tempSelectedElements.map((element) => (
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											gap: 2,
										}}
									>
										<Typography variant="body2" key={element.id}>
											{element.name} - {element.asin || element.upc}
										</Typography>
										<Trash
											onClick={() => {
												setTempSelectedElements(
													tempSelectedElements.filter(
														(el) => el.id !== element.id
													)
												);
											}}
											style={{ cursor: "pointer" }}
										/>
									</Box>
								))
							) : (
								<Typography variant="body2" sx={{ opacity: 0.4 }}>
									{t("multiItemSelector.noProductsSelected")}
								</Typography>
							)}
						</Box>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								gap: 2,
							}}
						>
							<Button
								variant="contained"
								sx={{
									minWidth: 180,
								}}
								onClick={() => {
									handleAddProducts();
								}}
							>
								{t("multiItemSelector.addButton")}
							</Button>
							<Button
								variant="outlined"
								sx={{
									minWidth: 180,
								}}
								onClick={() => {
									setShowMoreProducts(false);
									setTempSelectedElements([]);
								}}
							>
								{t("multiItemSelector.notNowButton")}
							</Button>
						</Box>
					</Box>
				}
				hideClose
			/>
		</Box>
	);
}
