import React, { useState } from "react";
import { PropTypes } from "./InfoChart.types";
import {
	Button,
	Typography,
	Box,
	Tooltip,
	IconButton,
	CircularProgress,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InfoIcon from "@mui/icons-material/Info";
import { LineChart } from "@mui/x-charts/LineChart";
import { isSameDay, startOfYear, endOfYear } from "date-fns";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import { BasicModal } from "../BasicModal";
import { MdArrowDropDown } from "react-icons/md";
import { useTranslation } from "react-i18next";

const formatDate = (date: any) => {
	var d = new Date(date),
		month = "" + (d.getUTCMonth() + 1),
		day = "" + d.getUTCDate(),
		year = d.getUTCFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
};

export function InfoChart({ title, subtitle, data, isLoading }: PropTypes) {
	const { t } = useTranslation();
	const [showDateRangePicker, setShowDateRangePicker] = useState(false);
	const [startDateObject] = useState(
		new Date(new Date().getTime() - 28 * 24 * 60 * 60 * 1000)
	);
	const [endDateObject] = useState(new Date());
	const [dates, setDates] = useState([
		{
			startDate: startDateObject,
			endDate: endDateObject,
			key: "selection",
		},
	]);
	const [startDate] = useState(formatDate(startDateObject.getTime()));
	const [endDate] = useState(formatDate(endDateObject.getTime()));

	return (
		<>
			<Card
				variant="outlined"
				sx={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					cursor: "pointer",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						flexGrow: 1,
					}}
				>
					<CardContent>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Box>
								<Typography variant="body2" sx={{ fontWeight: 700 }}>
									{title}
								</Typography>
								{subtitle && (
									<Tooltip title={subtitle}>
										<IconButton>
											<InfoIcon sx={{ fontSize: 16 }} />
										</IconButton>
									</Tooltip>
								)}
							</Box>
							<Box
								sx={{
									cursor: "pointer",
									padding: "0px 10px 0 10px",
									display: "flex",
									alignItems: "center",
									fontSize: "14px",
									fontWeight: "600",
									borderRadius: 4,
									"&:hover": {
										background: "rgba(0,0,0,0.05)",
									},
									"& *": {
										marginLeft: 5,
									},
								}}
							>
								<span>{startDate}</span>
								<span>-</span>
								<span>{endDate}</span>
								<MdArrowDropDown />
							</Box>
						</Box>

						{isLoading ? (
							<CircularProgress size={24} />
						) : data && data.length > 0 ? (
							<LineChart
								xAxis={[
									{
										scaleType: "time",

										data: data
											? data.map((d) => new Date(d.day))
											: [1, 2, 3, 5, 8, 10],
										valueFormatter: (date) => date.toISOString().split("T")[0],
									},
								]}
								yAxis={[
									{
										min: 0,
									},
								]}
								series={[
									{
										data: data
											? data.map((d) => d.count)
											: [2, 5.5, 2, 8.5, 1.5, 5],
									},
								]}
								height={300}
							/>
						) : (
							<Typography variant="body2" sx={{ mt: 2 }}>
								{t("infoChart.noData")}
							</Typography>
						)}
					</CardContent>
				</Box>
			</Card>

			<BasicModal
				width={800}
				showModal={showDateRangePicker}
				handleClose={() => {
					setShowDateRangePicker(false);
				}}
				children={
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<DateRangePicker
							onChange={(item: any) => {
								setDates([item.selection]);
							}}
							moveRangeOnFirstSelection={false}
							months={2}
							ranges={dates}
							direction="horizontal"
							maxDate={new Date()}
							color="#25406D"
							rangeColors={["#25406D"]}
							staticRanges={[
								...defaultStaticRanges,
								{
									label: t("infoChart.thisYear"),
									hasCustomRendering: false,
									range: () => ({
										startDate: startOfYear(new Date()),
										endDate: new Date(),
									}),
									isSelected: (range: any) => {
										const definedRange = {
											startDate: startOfYear(new Date()),
											endDate: new Date(),
										};
										return (
											isSameDay(range.startDate, definedRange.startDate) &&
											isSameDay(range.endDate, definedRange.endDate)
										);
									},
								},
								{
									label: t("infoChart.lastYear"),
									hasCustomRendering: false,
									range: () => ({
										startDate: startOfYear(
											new Date().setFullYear(new Date().getUTCFullYear() - 1)
										),
										endDate: endOfYear(
											new Date().setFullYear(new Date().getUTCFullYear() - 1)
										),
									}),
									isSelected: (range: any) => {
										const definedRange = {
											startDate: startOfYear(
												new Date().setFullYear(new Date().getUTCFullYear() - 1)
											),
											endDate: endOfYear(
												new Date().setFullYear(new Date().getUTCFullYear() - 1)
											),
										};
										return (
											isSameDay(range.startDate, definedRange.startDate) &&
											isSameDay(range.endDate, definedRange.endDate)
										);
									},
								},
							]}
						/>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								marginTop: 20,
							}}
						>
							<Button
								variant={"contained"}
								color="primary"
								onClick={() => {
									setShowDateRangePicker(false);
								}}
							>
								{t("infoChart.apply")}
							</Button>
						</Box>
					</Box>
				}
			></BasicModal>
		</>
	);
}
