import logdna from '@logdna/browser';
import {
    osName,
    osVersion,
    browserName,
    fullBrowserVersion,
} from "react-device-detect";

const levels = {
    DEBUG: "debug",
    INFO: "info",
    WARN: "warn",
    ERROR: "error",
    FATAL: "fatal",
};

const types = {
    CLIENT: "client",
};

const key =
    process.env.REACT_APP_MEZMO_API_KEY || "ffc7293a2a23518c5375cf4ab0ab7b4e"; // random key for now
const environment = process.env.REACT_APP_ENV || "localhost";
const app = "App";
let options = {
    hostname: environment,
    app: app,
    indexMeta: true,
    levels: ['debug', 'info', 'warn', 'error', 'fatal'],
    console: {}
};
// Not using until all errors are fixed
if (process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "stage") {
    options.console = {
        integrations: [levels.ERROR],
    };
} else {
    options.console = { enable: false };
}

logdna.init(key, options);

const meta = {
    userId: null,
    clientVersion: process.env.REACT_APP_VERSION,
    browser: browserName,
    browserVersion: fullBrowserVersion,
    osName: osName,
    osVersion: osVersion,
    referrer: document.referrer,
    userAgent: navigator.userAgent,
    type: types.CLIENT,
};

const Logger = (message: string | object, level = levels.DEBUG, extraMeta = {}) => {
    const context = {
        ...meta,
        ...extraMeta,
        userId: localStorage.getItem("user_id") || "Not signed in",
    };
    switch (level) {
        case levels.ERROR:
        case levels.FATAL:
            logdna.error(message, { context });
            break;
        case levels.WARN:
            logdna.warn(message, { context });
            break;
        default:
            logdna.log(message, { context });
    }

};

export { levels };
export default Logger;