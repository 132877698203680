import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../contexts/user";
import { useMutation, useQuery } from "react-query";
import { certification as certificationActions } from "../../controllers";
import { ICertification } from "../../models";
import { useSnackbar } from "notistack";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import { UploadFile } from "../UploadFile";
import { useTranslation } from "react-i18next";

export function ContractDocuments({
	companyId,
	closeModal,
}: {
	companyId?: number;
	closeModal?: () => void;
}) {
	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const [selectedCertification, setSelectedCertification] =
		useState<ICertification | null>(null);
	const [rows, setRows] = useState<
		{ id: number; file: File | null; name: string }[]
	>([{ id: 0, file: null, name: "" }]);
	const [certifications, setCertifications] = useState<Array<ICertification>>(
		[]
	);

	const { refetch: refetchCertifications } = useQuery(
		"certifications-modal",
		() =>
			certificationActions.list(
				true,
				companyId ?? user?.company?.id,
				undefined,
				undefined,
				undefined,
				undefined,
				999
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCertifications(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("contractDocuments.error"), { variant: "error" });
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"certification-detail-documents",
		({ file, name }: { file: File; name: string }) =>
			certificationActions.addDocuments({
				isClimeCo: true,
				id: selectedCertification!.id,
				companyId: companyId,
				file: file,
				name: name,
			})
	);

	const uploadDocuments = async () => {
		const finalDocuments = [...rows.filter((document) => document.file)];

		const mutateDocuments = (index: number) => {
			mutate(
				{
					file: finalDocuments[index].file!,
					name: finalDocuments[index].name ?? "",
				},
				{
					onSuccess: () => {
						index += 1;
						if (index < finalDocuments.length) {
							mutateDocuments(index);
						} else {
							if (closeModal) {
								closeModal();
							}
							enqueueSnackbar(t("contractDocuments.success"), {
								variant: "success",
							});
						}
					},
					onError: (error: any) => {
						console.log(error);
						enqueueSnackbar(t("contractDocuments.error"), { variant: "error" });
					},
				}
			);
		};

		mutateDocuments(0);
	};

	useEffect(() => {
		refetchCertifications();
	}, [refetchCertifications]);

	const handleAddInput = () => {
		const newId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
		setRows([...rows, { id: newId, file: null, name: "" }]);
	};

	const handleRemoveInput = (id: number) => {
		setRows(rows.filter((row) => row.id !== id));
	};

	return (
		<Box sx={{ minHeight: 300 }}>
			<Typography variant="h6">{t("contractDocuments.title")}</Typography>
			<Divider sx={{ marginBottom: 3 }} />
			<Autocomplete
				disablePortal
				id="certification"
				options={certifications}
				getOptionKey={(option) => option?.id ?? ""}
				getOptionLabel={(certification) =>
					certification
						? certification?.name +
						  " - " +
						  certification.estimations
								.map(
									(estimation) =>
										estimation?.product?.name +
										(estimation?.product?.asin
											? " - " + estimation?.product?.asin
											: estimation?.product?.upc
											? " - " + estimation?.product?.upc
											: "")
								)
								.join(", ")
						: ""
				}
				renderOption={(props, option) => (
					<li {...props} style={{ ...props.style, minHeight: "40px" }}>
						{option.name} -{" "}
						{option.estimations
							.map(
								(estimation) =>
									estimation?.product?.name +
									(estimation?.product?.asin
										? " - " + estimation?.product?.asin
										: estimation?.product?.upc
										? " - " + estimation?.product?.upc
										: "")
							)
							.join(", ")}
					</li>
				)}
				PaperComponent={({ children }) => (
					<Paper style={{ maxHeight: 150, overflow: "auto" }}>{children}</Paper>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={t("contractDocuments.selectCertification")}
					/>
				)}
				onChange={(_, value: ICertification | null) => {
					setSelectedCertification(value);
				}}
			/>
			{selectedCertification && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: 1,
						marginTop: 4,
					}}
				>
					{rows.map((row) => (
						<Box
							key={row.id}
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: 1,
								width: "100%",
							}}
						>
							<TextField
								autoFocus
								placeholder={t("contractDocuments.linkName")}
								sx={{
									display: "flex",
									alignSelf: "start",
									minWidth: 200,
								}}
								value={row.name}
								onChange={(e) => {
									const updatedRows = rows.map((r) =>
										r.id === row.id ? { ...r, name: e.target.value } : r
									);
									setRows(updatedRows);
								}}
							/>
							<UploadFile
								padding={0}
								value={row.file}
								accept=".pdf,.docx,.doc"
								onFileChange={(file) => {
									const updatedRows = rows.map((r) =>
										r.id === row.id ? { ...r, file: file } : r
									);
									setRows(updatedRows);
								}}
								flex={1}
							/>

							<IconButton
								color="primary"
								onClick={() => handleRemoveInput(row.id)}
							>
								<RemoveIcon />
							</IconButton>
						</Box>
					))}
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							flex: 1,
							marginTop: 2,
							marginBottom: 4,
							width: "100%",
						}}
					>
						<Button variant="outlined" onClick={handleAddInput}>
							{t("contractDocuments.addFile")}
						</Button>
					</Box>
				</Box>
			)}

			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					flex: 1,
					marginTop: 2,
					width: "100%",
				}}
			>
				<Button
					variant="contained"
					sx={{
						minHeight: 34,
						minWidth: 120,
					}}
					disabled={
						!selectedCertification ||
						rows.find((document) => !document || !document.file)
							? true
							: false
					}
					onClick={() => {
						uploadDocuments();
					}}
				>
					{isLoading ? (
						<CircularProgress size={12} sx={{ color: "white" }} />
					) : (
						t("contractDocuments.save")
					)}
				</Button>
			</Box>
		</Box>
	);
}
