import { IProductLCAVersionEmission } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"


class ProductLCAVersionEmission {
    async create(emissions: number, lcaVersionId: number, productId: number, lcaId: number, companyId?: number): Promise<IProductLCAVersionEmission> {
        const endpoint = companyId ? "companies/" + companyId + "/lcas/" + lcaId + "/lca-versions/" + lcaVersionId + "/product-lca-version-emissions/" : "lcas/" + lcaId + "/lca-versions/" + lcaVersionId + "/product-lca-version-emissions/"
        const response = await connectionsClient.client.post(endpoint, {
            emissions,
            product: productId
        });
        return response.data;
    }
    async update(id: number, emissions: number, lcaVersionId: number, lcaId: number, companyId?: number): Promise<IProductLCAVersionEmission> {
        const endpoint = companyId ? "companies/" + companyId + "/lcas/" + lcaId + "/lca-versions/" + lcaVersionId + "/product-lca-version-emissions/" + id + "/" : "lcas/" + lcaId + "/lca-versions/" + lcaVersionId + "/product-lca-version-emissions/" + id + "/"
        const response = await connectionsClient.client.patch(endpoint, {
            emissions
        });
        return response.data;
    }
    async delete(id: number, lcaVersionId: number, lcaId: number, companyId?: number): Promise<{ res: string }> {
        const endpoint = companyId ? "companies/" + companyId + "/lcas/" + lcaId + "/lca-versions/" + lcaVersionId + "/product-lca-version-emissions/" + id + "/" : "lcas/" + lcaId + "/lca-versions/" + lcaVersionId + "/product-lca-version-emissions/" + id + "/"
        const response = await connectionsClient.client.delete(endpoint);
        return response.data;
    }
}

export const productLCAVersionEmission = new ProductLCAVersionEmission();