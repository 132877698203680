import { ILCAVersion } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"


class LCAVersion {
    async create(title: string, lca_document: File | string | undefined, lca_provider: string, certification_date: string, number_of_products: number, lca_standards: Array<string>, product_category: string, companyId: number, lcaId: number): Promise<ILCAVersion> {
        const endpoint = companyId ? "companies/" + companyId + "/lcas/" + lcaId + "/lca-versions/" : "lcas/" + lcaId + "/lca-versions/"

        const formData = new FormData();
        formData.append("title", title!);
        formData.append("lca_provider", lca_provider!);
        formData.append("certification_date", certification_date!);
        formData.append("number_of_products", number_of_products.toString());
        formData.append("lca_standards", JSON.stringify(lca_standards!));
        formData.append("product_category", product_category!);
        if (typeof lca_document === "object" || typeof lca_document === "string") {
            formData.append("lca_document", lca_document);
        }

        const response = await connectionsClient.client.post(endpoint, formData, { headers: { "Content-Type": "multipart/form-data" } });
        return response.data;
    }

    async update({ lcaVersionId, lcaId, approvedDate, rejectedDate, rejectReason, status, certificationDate }: { lcaVersionId: number, lcaId: number, approvedDate: string | null, rejectedDate: string | null, rejectReason: string | null, status: string, certificationDate?: string }): Promise<ILCAVersion> {
        const endpoint = "/lcas/" + lcaId + "/lca-versions/" + lcaVersionId
        const response = await connectionsClient.client.patch(endpoint, {
            certification_date: certificationDate,
            approved_date: approvedDate,
            rejected_date: rejectedDate,
            reject_reason: rejectReason,
            status
        });
        return response.data;
    }

    async updateChecklist({ lcaVersionId, lcaId, checklistDocument }: { lcaVersionId: number, lcaId: number, checklistDocument: File | string }): Promise<ILCAVersion> {
        const formData = new FormData();
        formData.append("checklist_document", checklistDocument);

        const endpoint = "/lcas/" + lcaId + "/lca-versions/" + lcaVersionId
        const response = await connectionsClient.client.patch(endpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data;
    }
}

export const lcaVersion = new LCAVersion();