import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	IconButton,
	InputAdornment,
	TextField,
} from "@mui/material";
import Avatar from "react-avatar";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/user";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
	AiOutlineEye as Visibility,
	AiOutlineEyeInvisible as VisibilityOff,
} from "react-icons/ai";
import { useMutation } from "react-query";
import { user as userActions } from "../../controllers";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 16,
		marginBottom: 4,
		fontWeight: 700,
		color: "#686868",
	},
});

export function UserProfile() {
	const { user } = useContext(UserContext);
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [username, setUsername] = useState(user?.first_name);

	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const { isLoading: isLoadingChanging, mutate: mutatePassword } = useMutation(
		"user-password-update",
		({ password, newPassword }: { password: string; newPassword: string }) =>
			userActions.changePassword({
				id: user!.id,
				original_password: password,
				new_password: newPassword,
			}),
		{
			onSuccess: () => {
				enqueueSnackbar(t("userProfile.passwordChangedSuccess"), {
					variant: "success",
				});
			},
			onError: () => {
				enqueueSnackbar(t("userProfile.passwordChangedError"), {
					variant: "error",
				});
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"user-data-update",
		({ username }: { username: string }) =>
			userActions.update({
				id: user!.id,
				first_name: username,
			}),
		{
			onSuccess: () => {
				enqueueSnackbar(t("userProfile.dataChangedSuccess"), {
					variant: "success",
				});
			},
			onError: () => {
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const handleChangePassword = () => {
		if (newPassword !== confirmNewPassword) {
			enqueueSnackbar(t("userProfile.newPasswordsMismatch"), {
				variant: "error",
			});
			return;
		}

		mutatePassword({
			password: currentPassword,
			newPassword,
		});
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					maxWidth: "800px",
					width: "100%",
				}}
			>
				<Avatar
					name={username}
					size="120"
					round="6px"
					style={{
						fontSize: "28px",
						marginRight: "12px",
						marginBottom: "12px",
					}}
				></Avatar>
				<FormControl sx={{ width: "100%" }}>
					<span className={classes.inputLabel}>{t("userProfile.name")}</span>{" "}
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="Name"
						placeholder={t("userProfile.yourName")}
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<span className={classes.inputLabel}>{t("userProfile.email")}</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="Email"
						placeholder={t("userProfile.yourEmail")}
						value={user?.email}
						aria-readonly
						disabled
					/>
					<Button
						variant="contained"
						sx={{
							marginTop: 2,
							maxWidth: 300,
							minHeight: 34,
							alignSelf: "end",
						}}
						disabled={!username || isLoading}
						onClick={() => mutate({ username: username! })}
					>
						{isLoading ? <CircularProgress size={12} /> : t("userProfile.save")}
					</Button>
				</FormControl>
			</Box>
			<Divider
				sx={{
					marginTop: 2,
					marginBottom: 2,
					height: 2,
					width: "100%",
					maxWidth: 800,
				}}
			/>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					maxWidth: "800px",
					width: "100%",
				}}
			>
				<FormControl sx={{ width: "100%" }}>
					<span className={classes.inputLabel}>
						{t("userProfile.currentPassword")}
					</span>
					<TextField
						type="password"
						fullWidth
						placeholder={t("userProfile.currentPassword")}
						value={currentPassword}
						onChange={(e) => setCurrentPassword(e.target.value)}
					/>
					<span className={classes.inputLabel}>
						{t("userProfile.newPassword")}
					</span>
					<TextField
						type={showPassword ? "text" : "password"}
						fullWidth
						placeholder={t("userProfile.newPassword")}
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<span className={classes.inputLabel}>
						{t("userProfile.confirmNewPassword")}
					</span>
					<TextField
						type={showPassword ? "text" : "password"}
						fullWidth
						placeholder={t("userProfile.confirmNewPassword")}
						value={confirmNewPassword}
						onChange={(e) => setConfirmNewPassword(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Button
						variant="contained"
						sx={{
							marginTop: 2,
							maxWidth: 300,
							alignSelf: "end",
							minHeight: 34,
						}}
						onClick={handleChangePassword}
						disabled={
							!currentPassword ||
							!newPassword ||
							!confirmNewPassword ||
							isLoadingChanging
						}
					>
						{isLoadingChanging ? (
							<CircularProgress size={12} />
						) : (
							t("userProfile.changePassword")
						)}
					</Button>
				</FormControl>
			</Box>
		</Box>
	);
}
