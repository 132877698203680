import { useState } from "react";
import {
	Typography,
	Box,
	Button,
	TextField,
	CircularProgress,
} from "@mui/material";
import { PropTypes } from "./CertificationConfirmation.types";
import { useTranslation } from "react-i18next";

export function CertificationConfirmation(props: PropTypes) {
	const { confirmationData, handleModalClose } = props;
	const [textfieldData, setTextfieldData] = useState("");
	const { t } = useTranslation();

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				{confirmationData.title}
			</Typography>
			<Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
				{confirmationData.description}
			</Typography>
			{confirmationData.textfield && (
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					placeholder={confirmationData.textfield}
					sx={{ marginBottom: 2 }}
					multiline
					rows={3}
					value={textfieldData}
					onChange={(e) => {
						setTextfieldData(e.target.value);
					}}
				/>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="outlined"
					sx={{ marginTop: 2, maxWidth: 300, alignSelf: "end", marginRight: 2 }}
					onClick={() => {
						handleModalClose();
					}}
				>
					{t("certificationConfirmation.cancel")}
				</Button>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						minHeight: 34,
					}}
					disabled={confirmationData.textfield && !textfieldData ? true : false}
					onClick={() => {
						confirmationData.callback(
							textfieldData ? textfieldData : undefined
						);
					}}
				>
					{props.isLoading ? (
						<CircularProgress size={12} style={{ color: "white" }} />
					) : (
						t("certificationConfirmation.ok")
					)}
				</Button>
			</Box>
		</Box>
	);
}
