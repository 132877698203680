import React from "react";
import { PropTypes } from "./InfoAmount.types";
import { Typography, Box, Tooltip, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import EastIcon from "@mui/icons-material/East";
import InfoIcon from "@mui/icons-material/Info";

export function InfoAmount({ title, subtitle, value, percentage }: PropTypes) {
	return (
		<Card
			variant="outlined"
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				cursor: "pointer",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flexGrow: 1,
				}}
			>
				<CardContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "baseline",
							justifyContent: "space-between",
						}}
					>
						<Typography variant="body2">{title}</Typography>
						<Tooltip title={subtitle}>
							<IconButton>
								<InfoIcon sx={{ fontSize: 16 }} />
							</IconButton>
						</Tooltip>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<Typography variant="body2" sx={{ fontSize: 20, fontWeight: 700 }}>
							{value}
						</Typography>
						{percentage !== undefined && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									marginTop: "8px",
								}}
							>
								{percentage < 0 ? (
									<SouthIcon sx={{ fontSize: 12, color: "red" }} />
								) : percentage > 0 ? (
									<NorthIcon sx={{ fontSize: 12, color: "#77c90f" }} />
								) : (
									<EastIcon sx={{ fontSize: 12, color: "gray" }} />
								)}

								<Typography
									variant="h4"
									sx={{
										fontSize: 14,
										fontWeight: 700,
										color:
											percentage < 0
												? "red"
												: percentage > 0
												? "#77c90f"
												: "gray",
									}}
								>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 0,
									}).format(percentage)}
									%
								</Typography>
							</Box>
						)}
					</Box>
				</CardContent>
			</Box>
		</Card>
	);
}
