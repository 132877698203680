import { Box, Typography, Button } from "@mui/material";
import { PropTypes } from "./AmazonCertificationComplete.types";
import { useNavigate } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTranslation } from "react-i18next";

export default function AmazonCertificationComplete({
	certification,
}: PropTypes) {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				width: "100%",
				textAlign: "center",
				padding: 4,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					marginBottom: 4,
					width: 180,
					height: 140,
					border: "1px solid #D6E9FF",
					borderRadius: 4,
					padding: 2,
					textAlign: "left",
				}}
			>
				<VerifiedIcon
					sx={{
						fontSize: 20,
						color: "#00A889",
						marginBottom: 2,
					}}
				/>
				<Typography variant="h6" sx={{ fontSize: 18 }}>
					{t("amazonCertification.title")}
				</Typography>
				<Typography
					variant="body2"
					color="textSecondary"
					sx={{ color: "#A5A5A5", textAlign: "left" }}
				>
					{certification?.estimations.length}{" "}
					{t("amazonCertification.products")}
				</Typography>
			</Box>

			<Typography
				variant="h5"
				sx={{ fontWeight: "bold", marginBottom: 2, maxWidth: 500 }}
			>
				{t("amazonCertification.congratulations", {
					count: certification?.estimations.length,
				})}
			</Typography>

			<Typography variant="body1" sx={{ marginBottom: 4, maxWidth: 500 }}>
				{t("amazonCertification.badgeMessage")}
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					gap: 2,
				}}
			>
				<Button
					variant="outlined"
					sx={{
						minWidth: 180,
					}}
					onClick={() => {
						navigate("/");
					}}
				>
					{t("amazonCertification.goToDashboard")}
				</Button>
			</Box>
		</Box>
	);
}
