import { useState } from "react";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import { PropTypes } from "./UploadContracts.types";
import { UploadFile } from "../UploadFile";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import {
	certification as certificationActions,
	certificationProcessStep as certificationProcessStepActions,
} from "../../controllers";
import { useTranslation } from "react-i18next";

export function UploadContracts({
	id,
	companyId,
	certificationProcessId,
	callback,
}: PropTypes) {
	const [rows, setRows] = useState<{ id: number; file: File | null }[]>([
		{ id: 0, file: null },
	]);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const handleAddInput = () => {
		const newId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
		setRows([...rows, { id: newId, file: null }]);
	};

	const handleRemoveInput = (id: number) => {
		setRows(rows.filter((row) => row.id !== id));
	};

	const { isLoading, mutate } = useMutation(
		"certification-detail-documents",
		({ file }: { file: File }) =>
			certificationActions.addDocuments({
				isClimeCo: true,
				id: id,
				companyId: companyId,
				file: file,
			})
	);

	const { isLoading: isLoadingSigning, mutate: mutateSigning } = useMutation(
		"certification-detail-signing",
		(_: null) =>
			certificationProcessStepActions.markItAsSigned({
				id: certificationProcessId,
			}),
		{
			onSuccess: () => {
				callback();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const filteredRows = rows.filter((r) => r.file !== null);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-start",
				alignItems: "flex-start",
				gap: 1,
			}}
		>
			{rows.map((row) => (
				<Box
					key={row.id}
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						gap: 1,
					}}
				>
					<UploadFile
						padding={0}
						value={row.file}
						accept=".pdf,.docx,.doc"
						onFileChange={(file) => {
							const updatedRows = rows.map((r) =>
								r.id === row.id ? { ...r, file: file } : r
							);
							setRows(updatedRows);
						}}
					/>

					<IconButton color="primary" onClick={() => handleRemoveInput(row.id)}>
						<RemoveIcon />
					</IconButton>
				</Box>
			))}
			<Button variant="outlined" onClick={handleAddInput} sx={{ marginTop: 2 }}>
				{t("uploadContracts.addFile")}
			</Button>
			<Button
				variant="contained"
				onClick={() => {
					const addDocument = (index: number) => {
						const file = filteredRows[index]?.file;
						if (file) {
							mutate(
								{ file },
								{
									onSuccess: () => {
										if (index === filteredRows.length - 1) {
											mutateSigning(null);
										} else {
											addDocument(index + 1);
										}
									},
									onError: (error: any) => {
										console.log(error);
										enqueueSnackbar(t("general.errorMessage"), {
											variant: "error",
										});
									},
								}
							);
						}
					};

					if (filteredRows.length > 0) {
						addDocument(0);
					} else {
						mutateSigning(null);
					}
				}}
				sx={{ marginTop: 4, alignSelf: "center", minHeight: 34 }}
				disabled={isLoading || isLoadingSigning}
			>
				{isLoading || isLoadingSigning ? (
					<CircularProgress size={12} sx={{ color: "white" }} />
				) : (
					t("uploadContracts.saveFiles")
				)}
			</Button>
		</Box>
	);
}
