import { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Heading } from "../Heading";
import { useSnackbar } from "notistack";
import { INotification } from "../../models";
import { useMutation, useQuery } from "react-query";
import {
	notification as notificationActions,
	product as productActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { Updates } from "../Updates";
import { useTranslation } from "react-i18next";

export function Notifications() {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [notifications, setNotifications] = useState<INotification[] | null>(
		null
	);
	const { user } = useContext(UserContext);
	const [productsLength, setProductsLength] = useState(0);
	const [offset, setOffset] = useState(0);
	const limit = 5;

	const {
		refetch: refetchNotifications,
		isLoading,
		isFetching,
		isRefetching,
	} = useQuery(
		"notifications",
		() =>
			notificationActions.list(
				limit,
				user?.company.id,
				undefined,
				undefined,
				offset
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setNotifications((notifications) => [
					...(notifications ?? []),
					...res.results,
				]);

				setOffset((offset) =>
					res.count > offset + limit ? offset + limit : -1
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	useQuery("products-dashboard", () => productActions.list(user?.company.id), {
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
		onSuccess: (res) => {
			setProductsLength(res.count);
		},
		onError: (error: any) => {
			console.log(error);
			enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
		},
	});

	useEffect(() => {
		refetchNotifications();
	}, [refetchNotifications]);

	const { mutate, isLoading: isMarkingLoading } = useMutation(
		(notificationId: number) =>
			notificationActions.toggleViewed(notificationId),
		{
			retry: false,
			onSuccess: (res) => {
				refetchNotifications();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const handleClearAll = () => {
		const notificationsToMarkAsViewed = notifications?.filter(
			(notification) => notification.users.length === 0
		);

		notificationsToMarkAsViewed?.forEach((notification) => {
			mutate(notification.id);
		});
	};

	return (
		<Box>
			<Heading
				title={t("notifications.title")}
				showSearch
				actions={() => <></>}
				noMarginBottom
			/>

			<Box
				sx={{
					backgroundColor: "#fff",
					marginBottom: 8,
					maxWidth: "1000px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						backgroundColor: "#fff",
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<Button
						sx={{
							minHeight: 34,
							maxWidth: "100px",
							textAlign: "center",
							width: "100%",
						}}
						onClick={handleClearAll}
					>
						{isMarkingLoading ? (
							<CircularProgress size={12} />
						) : (
							t("notifications.clearAll")
						)}
					</Button>
				</Box>
				{isLoading ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							padding: 4,
						}}
					>
						<CircularProgress size={24} />
					</Box>
				) : notifications && notifications.length > 0 ? (
					<>
						{notifications.map((notification, index, array) => {
							return (
								<Box key={notification.id}>
									{(index === 0 ||
										(index > 0 &&
											new Date(array[index - 1].created_at).getDate() !==
												new Date(array[index].created_at).getDate())) && (
										<Typography
											sx={{
												fontSize: 12,
												color: "#777",
												textTransform: "uppercase",
												marginBottom: 1,
												fontWeight: "bold",
											}}
										>
											{new Intl.DateTimeFormat("en", {
												day: "numeric",
												month: "short",
												year: "2-digit",
											}).format(new Date(notification.created_at))}
										</Typography>
									)}
									<Updates
										key={notification.id}
										notification={notification}
										productsLength={productsLength}
									/>
								</Box>
							);
						})}
						{offset > -1 ? (
							<Button
								sx={{ minHeight: 34, width: 200, alignSelf: "center" }}
								disabled={
									isLoading || isFetching || isRefetching || offset === -1
								}
								variant="contained"
								onClick={() => {
									refetchNotifications();
								}}
							>
								{isLoading || isFetching || isRefetching ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("notifications.loadMore")
								)}
							</Button>
						) : null}
					</>
				) : (
					<Box sx={{ backgroundColor: "#FAFAFA", padding: 4, fontSize: 14 }}>
						<span>{t("notifications.noNew")}</span>
					</Box>
				)}
			</Box>
		</Box>
	);
}
