import { Box, FormControl, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PropTypes } from "./LanguageSelector.types";

export function LanguageSelector(props: PropTypes) {
	const { i18n } = useTranslation();

	return (
		<Box sx={{ width: "100%" }}>
			<FormControl variant="outlined" fullWidth>
				<Select
					labelId="language-select-label"
					id="language-select"
					defaultValue="en-US"
					value={i18n.language || "en-US"}
					onChange={(event) => {
						console.log(i18n.language);
						i18n.changeLanguage(event.target.value);
					}}
					sx={{ borderRadius: 8 }}
				>
					<MenuItem value="en-US">English</MenuItem>
					<MenuItem value="es-ES">Español</MenuItem>
					<MenuItem value="zh-CN">中文</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
}
