import { Box } from "@mui/material";
import { PropTypes } from "./MainContent.types";
import Routes from "../../routes/Routes";

export function MainContent(props: PropTypes) {
	return (
		<Box
			component="main"
			sx={{
				flex: 1,
				height: "calc(100vh - 150px)",
				marginTop: "150px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				backgroundColor: "#fff",
				padding: 4,
				width: "100%",
			}}
		>
			<Routes />
		</Box>
	);
}
