import { useState, useContext } from "react";
import {
	SideBar,
	TopBar,
	MainContent,
	BasicModal,
	CompanyOnboarding,
} from "../../components";
import { PropTypes } from "./AppContent.types";
import { BrowserRouter } from "react-router-dom";
import { UserContext } from "../../contexts/user";

const drawerWidth = 280;

export function AppContent(props: PropTypes) {
	const [mobileOpen, setMobileOpen] = useState(document.body.clientWidth > 900);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const { user } = useContext(UserContext);
	const [showOnboardingModal, setShowOnboardingModal] = useState(
		user?.company ? false : true
	);

	const handleClose = () => {
		setShowOnboardingModal(false);
	};

	return (
		<BrowserRouter>
			<TopBar
				mobileOpen={mobileOpen}
				handleDrawerToggle={handleDrawerToggle}
				drawerWidth={drawerWidth}
			/>
			<SideBar
				mobileOpen={mobileOpen}
				handleDrawerToggle={handleDrawerToggle}
			/>

			<MainContent mobileOpen={mobileOpen} drawerWidth={drawerWidth} />

			<BasicModal
				width={800}
				showModal={showOnboardingModal}
				handleClose={handleClose}
				children={<CompanyOnboarding handleClose={handleClose} />}
				hideClose
			/>
		</BrowserRouter>
	);
}
