import { connectionsClient } from "../../utils/ConnectionsClient"
import { } from "./strings.types"

class Strings {
    async list(): Promise<{ count: number, results: Array<Object> }> {
        const endpoint = "strings"
        const response = await connectionsClient.client.get(endpoint);
        return response.data;
    }
}

export const strings = new Strings();