import {
	Autocomplete,
	Box,
	TextField,
	Typography,
	Button,
} from "@mui/material";
import React, { useState } from "react";
import { PropTypes } from "./ChooseProvider.types";
import { useQuery } from "react-query";
import { certification as certificationAction } from "../../controllers";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type Provider = {
	id: number;
	name: string;
	certification_type: string;
};

export default function ChooseProvider({
	selectedProvider,
	setSelectedProvider,
	mutate,
	providerTypes,
}: PropTypes) {
	const [providers, setProviders] = useState<Provider[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	useQuery(
		"providers",
		() =>
			certificationAction.listProviders(
				providerTypes ? { certification_type: providerTypes[0] } : {}
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProviders(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("chooseProvider.errorFetchingProviders"), {
					variant: "error",
				});
			},
		}
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: {
						xs: "calc(100% - 82px)",
						sm: "100%",
					},
				}}
			>
				<Typography
					variant="h5"
					sx={{
						fontWeight: 700,
					}}
				>
					{t("chooseProvider.title")}
				</Typography>
				<Typography sx={{ marginTop: 1, marginBottom: 4 }} variant="subtitle1">
					{t("chooseProvider.subtitle")}
				</Typography>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						options={providers}
						getOptionKey={(option) => option?.id ?? ""}
						getOptionLabel={(option) => option.name}
						sx={{ width: 300 }}
						value={selectedProvider}
						onChange={(_, value) => {
							setSelectedProvider(value);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={t("chooseProvider.providersPlaceholder")}
							/>
						)}
					/>
				</Box>
			</Box>
			<Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
				<Button
					variant="contained"
					onClick={() => {
						mutate();
					}}
					sx={{ marginTop: 8, justifySelf: "flex-end" }}
					disabled={!selectedProvider}
				>
					{t("chooseProvider.createButton")}
				</Button>
			</Box>
		</>
	);
}
