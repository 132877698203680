import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { PropTypes } from "./SelectMarketplaces.types";
import { useTranslation } from "react-i18next";

export default function SelectMarketplaces({
	selectedMarketplaces,
	setSelectedMarketplaces,
	selectedElements,
	setSelectedElements,
}: PropTypes) {
	const { t } = useTranslation();
	const marketplaces = [
		{ id: 1, name: t("marketplaces.United States"), flag: "🇺🇸" },
		{ id: 3, name: t("marketplaces.United Kingdom"), flag: "🇬🇧" },
		{ id: 4, name: t("marketplaces.Germany"), flag: "🇩🇪" },
		{ id: 35691, name: t("marketplaces.Italy"), flag: "🇮🇹" },
		{ id: 44551, name: t("marketplaces.Spain"), flag: "🇪🇸" },
	];

	const handleSelectMarketplace = (id: number, name: string) => {
		setSelectedMarketplaces((prevSelected) =>
			prevSelected.includes(id)
				? prevSelected.filter((marketplaceId) => marketplaceId !== id)
				: [...prevSelected, id]
		);

		const isSelected = selectedMarketplaces.includes(id);

		const productIndex = selectedElements.findIndex(
			(element) => element.id === id
		);

		if (productIndex === -1) {
			return;
		}

		const currentElement = selectedElements[productIndex];

		const updatedMarketplaces = isSelected
			? currentElement.marketplaces?.filter(
					(marketplace) => marketplace.id !== id
			  )
			: [...(currentElement.marketplaces || []), { id, name }];

		const updatedElements = [...selectedElements];
		updatedElements[productIndex] = {
			...currentElement,
			marketplaces: updatedMarketplaces,
		};

		setSelectedElements(updatedElements);
	};

	return (
		<Grid
			container
			spacing={3}
			justifyContent="center"
			sx={{ padding: 4, boxSizing: "border-box" }}
		>
			{marketplaces.map((marketplace) => (
				<Grid item xs={12} sm={6} md={4} key={marketplace.name}>
					<Box
						onClick={() => {
							handleSelectMarketplace(marketplace.id, marketplace.name);
						}}
						sx={{
							cursor: "pointer",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							outline: selectedMarketplaces.includes(marketplace.id)
								? "2px solid #34918E"
								: "1px solid #B1D1F0",
							borderRadius: 2,
							padding: 2,
							paddingTop: 4,
							minHeight: 100,
							textAlign: "left",
							boxSizing: "border-box",
						}}
					>
						<span style={{ fontSize: 20, marginRight: 8 }}>
							{marketplace.flag}
						</span>
						<Typography variant="h6" sx={{ fontSize: 16 }}>
							{marketplace.name}
						</Typography>
					</Box>
				</Grid>
			))}
		</Grid>
	);
}
