import {
	Button,
	CircularProgress,
	TextField,
	Box,
	Typography,
	InputAdornment,
	IconButton,
	Divider,
} from "@mui/material";
import { PropTypes } from "./Auth.types";
import { useState, useRef, useEffect } from "react";
import { token as tokenActions, user as userActions } from "../../controllers";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { UserContext } from "../../contexts/user";
import { makeStyles } from "@mui/styles";
import {
	AiOutlineEye as Visibility,
	AiOutlineEyeInvisible as VisibilityOff,
} from "react-icons/ai";
import Logo from "../../assets/logo-vertical.png";
import ReactCodeInput from "react-code-input";
import { BasicModal } from "../BasicModal";
import { useWindowSize } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";

const useStyles = makeStyles({
	authContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		flex: 1,
		height: "100vh",
		padding: 20,
		"@media (max-width: 600px)": {
			height: "auto",
		},
	},
	authContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		maxWidth: "800px",
		border: "1px solid #B1D1F0",
		borderRadius: "40px",
	},
	mainButton: {
		height: 40,
		minWidth: "120px",
	},
	inputLabel: {
		display: "flex",
		marginTop: 8,
		marginBottom: "14px",
		fontSize: 14,
		fontWeight: 700,
	},
	inputLabelRequired: {
		fontSize: 18,
		display: "inline-block",
		height: "5px",
		verticalAlign: "sub",
		color: "#B61313",
	},
});

export function Auth(props: PropTypes) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [token, setToken] = useState("");
	const size = useWindowSize();
	const [isSmallHeight, setIsSmallHeight] = useState(window.innerHeight < 740);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
	const passwordInputRef = useRef<HTMLInputElement | null>(null);
	const [resendDisabled, setResendDisabled] = useState(false);
	const [countdown, setCountdown] = useState(30);
	const [showPassword, setShowPassword] = useState(false);
	const [showWelcomeModal, setShowWelcomeModal] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const [auth, setAuth] = useState("login");

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const { enqueueSnackbar } = useSnackbar();
	const { getUser } = useContext(UserContext);
	const { isLoading, mutate: mutateLogin } = useMutation(
		"token",
		() => {
			return tokenActions.login({ email: email, password: password });
		},
		{
			onSuccess: () => {
				getUser();
			},
			onError: (error) => {
				enqueueSnackbar(t("auth.wrongCredentials"), { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingStartReset, mutate: mutateStartReset } =
		useMutation(
			"start-reset",
			() => {
				return tokenActions.startReset({ email: email, password: "" });
			},
			{
				onSuccess: () => {
					enqueueSnackbar(t("auth.resetLinkSent"), { variant: "success" });
					setAuth("create_password");
					setShowPassword(false);
				},
				onError: (error) => {
					enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
				},
			}
		);

	const { isLoading: isLoadingReset, mutate: mutateReset } = useMutation(
		"reset",
		() => {
			return tokenActions.reset({ token: token, password: password });
		},
		{
			onSuccess: () => {
				enqueueSnackbar(t("auth.passwordResetSuccess"), { variant: "success" });
				setAuth("login");
				setShowPassword(false);
			},
			onError: (error) => {
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const { mutate: mutateRegister } = useMutation(
		"register",
		() => {
			return userActions.create({
				first_name: name,
				email: email,
				password: password,
				username: email,
			});
		},
		{
			onSuccess: () => {
				mutateLogin();
			},
			onError: (error: any) => {
				const capitalizeFirstLetter = (text: string) => {
					return text.charAt(0).toUpperCase() + text.slice(1);
				};
				if (error.response.data.email) {
					enqueueSnackbar(capitalizeFirstLetter(error.response.data.email[0]), {
						variant: "error",
					});
				} else {
					enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
				}
			},
		}
	);

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const validatePasswords = (password: string) => {
		const minLength = 8;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumber = /\d/.test(password);
		// eslint-disable-next-line no-useless-escape
		const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

		return (
			password.length >= minLength &&
			hasUpperCase &&
			hasLowerCase &&
			hasNumber &&
			hasSpecialChar
		);
	};

	const login = () => {
		if (validateEmail(email)) {
			mutateLogin();
		}
	};

	const register = () => {
		let errorMessage = "";

		if (!name) {
			errorMessage = t("auth.nameRequired");
		} else if (!validateEmail(email)) {
			errorMessage = t("auth.invalidEmail");
		} else if (!validatePasswords(password)) {
			errorMessage = t("auth.passwordCriteria");
			setPasswordError(true);
		} else if (password !== repeatPassword) {
			errorMessage = t("auth.passwordMismatch");
		}

		if (errorMessage) {
			enqueueSnackbar(errorMessage, {
				variant: "error",
			});
		} else {
			mutateRegister();
		}
	};

	const onKeyPressName = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault();
			if (passwordInputRef.current) {
				passwordInputRef.current.focus();
			}
		}
	};

	const onKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault();
			login();
		}
	};

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has("welcome")) {
			setShowWelcomeModal(true);
		}
	}, []);

	useEffect(() => {
		if (size.height && size.width) {
			setIsSmallHeight(size.height < 740);
			setIsSmallScreen(size.width < 600);
		}
	}, [size]);

	return (
		<Box
			className={classes.authContainer}
			sx={{
				justifyContent: isSmallHeight ? "flex-start" : "center",
			}}
		>
			<Box className={classes.authContent}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						padding: 3,
					}}
				>
					<img
						src={Logo}
						alt="climeco"
						style={{ width: isSmallScreen ? 100 : 130, marginBottom: 20 }}
					/>

					<Typography
						variant="body2"
						sx={{ textAlign: "center", marginBottom: 2 }}
					>
						{t("auth.portalTitle")}
					</Typography>
					<Typography
						variant="h4"
						sx={{
							fontWeight: 700,
							textAlign: "center",
						}}
					>
						{auth === "login"
							? t("auth.signIn")
							: auth === "register"
							? t("auth.signUp")
							: t("auth.forgotPassword")}
					</Typography>
				</Box>
				<Divider sx={{ borderBottom: "1px solid #B1D1F0", width: "100%" }} />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: { xs: "20px 20px", md: "20px 160px" },
						width: "100%",
						alignItems: "center",
					}}
				>
					{auth === "login" ? (
						<>
							<Box
								sx={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									width: "100%",
								}}
							>
								<span className={classes.inputLabel}>
									{t("auth.emailLabel")}
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="email"
									placeholder={t("auth.emailPlaceholder")}
									value={email}
									sx={{ marginBottom: 1 }}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									onKeyDown={onKeyPressName}
								/>
								<span className={classes.inputLabel}>
									{t("auth.passwordLabel")}
								</span>
								<TextField
									inputRef={passwordInputRef}
									type={showPassword ? "text" : "password"}
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="password"
									value={password}
									placeholder={t("auth.passwordPlaceholder")}
									onKeyDown={onKeyPress}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									InputProps={{
										sx: {
											paddingRight: "20px",
											border: "1px solid #C9C9C9",
											borderRadius: "8px",
											"& input": {
												border: "none",
											},
											"& .MuiInputBase-input": {
												border: "none",
											},
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
										},
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Box>
							<Box
								sx={{ display: "flex", spacing: 2, flexDirection: "column" }}
							>
								<Typography
									variant="body2"
									sx={{ textAlign: "center", marginTop: 4, marginRight: 1 }}
								>
									{t("auth.noAccount")}
								</Typography>
								<Typography
									variant="body2"
									onClick={() => {
										setPassword("");
										setRepeatPassword("");
										setShowPassword(false);
										setAuth("register");
									}}
									sx={{
										textAlign: "center",
										fontWeight: "bold",
										color: "#183A67",
										"&:hover": {
											cursor: "pointer",
											textDecoration: "underline",
										},
									}}
								>
									{t("auth.signUp")}
								</Typography>
							</Box>
							<Button
								sx={{ marginTop: 2, minHeight: 34 }}
								disabled={!email || !password}
								variant="contained"
								onClick={() => {
									login();
								}}
								className={classes.mainButton}
							>
								{isLoading ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("auth.login")
								)}
							</Button>
							<Typography
								variant="body2"
								sx={{
									textAlign: "center",
									marginTop: 2,
									"&:hover": {
										cursor: "pointer",
										textDecoration: "underline",
									},
								}}
								onClick={() => {
									setAuth("password");
									setShowPassword(false);
								}}
							>
								{t("auth.forgotPassword")}
							</Typography>
						</>
					) : auth === "register" ? (
						<>
							<Box
								sx={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									width: "100%",
								}}
							>
								<span className={classes.inputLabel}>
									{t("auth.fullNameLabel")}
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="name"
									placeholder={t("auth.namePlaceholder")}
									value={name}
									sx={{ marginBottom: 1 }}
									onChange={(e) => {
										setName(e.target.value);
									}}
									onKeyDown={onKeyPressName}
								/>
								<span className={classes.inputLabel}>
									{t("auth.emailLabel")}
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="email"
									placeholder={t("auth.emailPlaceholder")}
									value={email}
									sx={{ marginBottom: 1 }}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									onKeyDown={onKeyPressName}
								/>
								<span className={classes.inputLabel}>
									{t("auth.passwordLabel")}
								</span>
								<TextField
									inputRef={passwordInputRef}
									type={showPassword ? "text" : "password"}
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="password"
									placeholder={t("auth.passwordPlaceholder")}
									value={password}
									onKeyDown={onKeyPress}
									sx={{
										marginBottom: 1,
										"& .MuiFormHelperText-root": {
											color: passwordError ? "#eb4343" : "inherit",
										},
									}}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									helperText={t("auth.passwordCriteriaMessage")}
									InputProps={{
										sx: {
											paddingRight: "20px",
											border: "1px solid #C9C9C9",
											borderRadius: "8px",
											"& input": {
												border: "none",
											},
											"& .MuiInputBase-input": {
												border: "none",
											},
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
											"& .MuiFormHelperText-root": {
												color: passwordError ? "#eb4343" : "inherit",
											},
										},
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<span className={classes.inputLabel}>
									{t("auth.repeatPasswordLabel")}
								</span>
								<TextField
									inputRef={passwordInputRef}
									type={showPassword ? "text" : "password"}
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="repeatPassword"
									placeholder={t("auth.repeatPasswordPlaceholder")}
									onKeyDown={onKeyPress}
									sx={{
										marginBottom: isSmallScreen ? 2 : 4,
									}}
									onChange={(e) => {
										setRepeatPassword(e.target.value);
									}}
									InputProps={{
										sx: {
											paddingRight: "20px",
											border: "1px solid #C9C9C9",
											borderRadius: "8px",
											"& input": {
												border: "none",
											},
											"& .MuiInputBase-input": {
												border: "none",
											},
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
											"& .MuiFormHelperText-root": {
												color: passwordError ? "#eb4343" : "inherit",
											},
										},
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Box>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography
									variant="body2"
									sx={{
										textAlign: "center",
										marginRight: 1,
										marginBottom: 0,
									}}
								>
									{t("auth.alreadyAccount")}
								</Typography>
								<Typography
									variant="body2"
									onClick={() => {
										setPassword("");
										setRepeatPassword("");
										setShowPassword(false);
										setAuth("login");
									}}
									sx={{
										color: "#183A67",
										textAlign: "center",
										fontWeight: "bold",
										marginBottom: 2,
										"&:hover": {
											cursor: "pointer",
											textDecoration: "underline",
										},
									}}
								>
									{t("auth.logIn")}
								</Typography>
							</Box>
							<Button
								disabled={!email || !password}
								variant="contained"
								onClick={() => {
									setPasswordError(false);
									register();
								}}
								sx={{ minHeight: 34, padding: "0px 24px" }}
								className={classes.mainButton}
							>
								{isLoading ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("auth.createAccount")
								)}
							</Button>
						</>
					) : auth === "create_password" ? (
						<>
							<Box
								sx={{
									"& input": {
										boxShadow: "none!important",
										"&:focus-visible": {
											outline: "#25406D auto 1px",
										},
									},
								}}
							>
								<ReactCodeInput
									type="text"
									fields={6}
									name=""
									inputMode="numeric"
									value={token}
									onChange={(value) => setToken(value)}
								/>
							</Box>
							<Typography
								variant="body2"
								sx={{
									marginTop: 1,
									marginBottom: 2,
									cursor: resendDisabled ? "default" : "pointer",
									"&:hover": {
										textDecoration: resendDisabled ? "none" : "underline",
									},
								}}
								onClick={() => {
									if (!resendDisabled) {
										if (validateEmail(email)) {
											mutateStartReset();
											setResendDisabled(true);
											let seconds = 30;
											const interval = setInterval(() => {
												seconds -= 1;
												setCountdown(seconds);
												if (seconds <= 0) {
													setCountdown(30);
													clearInterval(interval);
													setResendDisabled(false);
												}
											}, 1000);
										} else {
											enqueueSnackbar(t("auth.invalidEmail"), {
												variant: "error",
											});
										}
									}
								}}
							>
								{resendDisabled
									? t("auth.resendCodeMessage", { countdown })
									: t("auth.resendLink")}
							</Typography>

							<TextField
								inputRef={passwordInputRef}
								type={showPassword ? "text" : "password"}
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="password"
								placeholder={t("auth.passwordPlaceholder")}
								value={password}
								onKeyDown={onKeyPress}
								sx={{
									marginBottom: 2,
									"& .MuiFormHelperText-root": {
										color: passwordError ? "#eb4343" : "inherit",
									},
								}}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
								helperText={t("auth.passwordCriteriaMessage")}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
									sx: {
										"& .MuiFormHelperText-root": {
											color: passwordError ? "#eb4343" : "inherit",
										},
									},
								}}
							/>
							<TextField
								inputRef={passwordInputRef}
								type={showPassword ? "text" : "password"}
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="repeatPassword"
								placeholder={t("auth.repeatPasswordPlaceholder")}
								onKeyDown={onKeyPress}
								sx={{
									marginBottom: 1,
								}}
								onChange={(e) => {
									setRepeatPassword(e.target.value);
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<Button
								sx={{ marginTop: 8, minHeight: 34 }}
								disabled={
									isLoadingReset ||
									!token ||
									token.length < 6 ||
									!password ||
									!repeatPassword
								}
								variant="contained"
								onClick={() => {
									if (validatePasswords(password)) {
										mutateReset();
									} else {
										setPasswordError(true);
										let errorMessage = t("auth.passwordErrorMessage");
										if (password !== repeatPassword) {
											errorMessage = t("auth.passwordMismatchMessage");
										}
										enqueueSnackbar(errorMessage, {
											variant: "error",
										});
									}
								}}
								className={classes.mainButton}
							>
								{isLoadingReset ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("auth.changePassword")
								)}
							</Button>

							<Box sx={{ display: "flex", spacing: 2 }}>
								<Typography
									variant="body2"
									sx={{
										textAlign: "center",
										marginTop: isSmallScreen ? 2 : 4,
										marginRight: 1,
									}}
								>
									{t("auth.alreadyAccount")}
								</Typography>
								<Typography
									variant="body2"
									onClick={() => {
										setAuth("login");
										setShowPassword(false);
									}}
									sx={{
										textAlign: "center",
										marginTop: 4,
										"&:hover": {
											cursor: "pointer",
											textDecoration: "underline",
										},
									}}
								>
									{t("auth.logIn")}
								</Typography>
							</Box>
						</>
					) : (
						<>
							<Box
								sx={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									width: "100%",
								}}
							>
								<span className={classes.inputLabel}>
									{t("auth.emailLabel")}
								</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="email"
									value={email}
									placeholder={t("auth.emailPlaceholder")}
									sx={{ marginBottom: 0 }}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									onKeyDown={onKeyPressName}
								/>
							</Box>
							<Button
								sx={{ marginTop: 2, minHeight: 34 }}
								disabled={isLoadingStartReset || !email}
								variant="contained"
								onClick={() => {
									if (validateEmail(email)) {
										mutateStartReset();
									} else {
										enqueueSnackbar(t("auth.invalidEmail"), {
											variant: "error",
										});
									}
								}}
								className={classes.mainButton}
							>
								{isLoadingStartReset ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("auth.send")
								)}
							</Button>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography
									variant="body2"
									sx={{ textAlign: "center", marginTop: 2 }}
								>
									{t("auth.alreadyAccount")}
								</Typography>
								<Typography
									variant="body2"
									onClick={() => {
										setAuth("login");
										setShowPassword(false);
									}}
									sx={{
										color: "#183A67",
										textAlign: "center",
										fontWeight: "bold",
										marginBottom: 2,
										"&:hover": {
											cursor: "pointer",
											textDecoration: "underline",
										},
									}}
								>
									{t("auth.logIn")}
								</Typography>
							</Box>
						</>
					)}
				</Box>
			</Box>
			<BasicModal
				width={800}
				showModal={showWelcomeModal}
				handleClose={() => {
					setShowWelcomeModal(false);
				}}
				children={
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography
							id="welcome-modal"
							variant="h6"
							component="h2"
							sx={{ textAlign: "center" }}
						>
							{t("auth.welcomeModal.title")}
						</Typography>
						<Typography id="welcome-modal" variant="body2" component="h2">
							<p>{t("auth.welcomeModal.intro")}</p>
							<p>{t("auth.welcomeModal.stepsIntro")}</p>
							<ul>
								<li>{t("auth.welcomeModal.step1")}</li>
								<li>{t("auth.welcomeModal.step2")}</li>
								<li>{t("auth.welcomeModal.step3")}</li>
							</ul>
							<p>{t("auth.welcomeModal.conclusion")}</p>
							<p>{t("auth.welcomeModal.clickToStart")}</p>
						</Typography>
						<br />
						<Typography
							id="welcome-modal-note"
							variant="body2"
							component="h2"
							sx={{ fontSize: 11 }}
						>
							{t("auth.welcomeModal.note")}
						</Typography>
						<Button
							variant="contained"
							sx={{ marginTop: 2 }}
							onClick={() => {
								const searchParams = new URLSearchParams(
									window.location.search
								);
								searchParams.delete("welcome");
								const newUrl = searchParams.toString()
									? `${window.location.pathname}?${searchParams}`
									: window.location.pathname;
								window.history.pushState({}, "", newUrl);
								setShowWelcomeModal(false);
							}}
						>
							{t("auth.welcomeModal.ok")}
						</Button>
					</Box>
				}
			/>
			<Box sx={{ position: "absolute", bottom: "70px", left: "10px" }}>
				<LanguageSelector />
			</Box>
		</Box>
	);
}
