import { Box, Button, Typography } from "@mui/material";
import { PropTypes } from "./QuickActions.types";

export function QuickActions({
	title,
	description,
	icon,
	callback,
	buttonTitle,
	buttonType,
}: PropTypes) {
	return (
		<Box
			sx={{
				marginBottom: 2,
				display: "flex",
				flexDirection: {
					xs: "column",
					sm: "row",
				},
				flex: 1,
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					border: "1px solid #D6E9FF",
					borderRadius: 4,
					flexDirection: "column",
					flexBasis: "calc(50% - 16px)",
					flexGrow: 1,
					padding: 4,
					maxWidth: "100%",
					marginBottom: {
						xs: 2,
						sm: 0,
					},
				}}
			>
				{icon}
				<Typography
					variant="body2"
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						marginBottom: 2,
						fontSize: 18,
						fontWeight: "bold",
						marginTop: 2,
					}}
				>
					{title}
				</Typography>
				<Typography
					variant="body2"
					sx={{
						fontSize: 14,
						overflow: "hidden",
						textOverflow: "ellipsis",
						marginBottom: 2,
					}}
				>
					{description}
				</Typography>

				<Button
					sx={{
						maxHeight: 34,
						width: 210,
						minWidth: 210,
						marginTop: 2,
					}}
					variant={buttonType}
					onClick={() => {
						callback();
					}}
				>
					{buttonTitle}
				</Button>
			</Box>
		</Box>
	);
}
