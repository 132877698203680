import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { PropTypes } from "./Basicmodal.types";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export function BasicModal(props: PropTypes) {
	const { width, showModal, handleClose, hideClose, children } = props;

	const close = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		reason: string
	) => {
		if (reason && reason === "backdropClick") return;
		handleClose();
	};
	return (
		<Modal
			open={showModal}
			onClose={close}
			disableAutoFocus={true}
			BackdropProps={{
				sx: {
					backgroundColor: "rgba(35, 65, 145, 0.9)",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					p: 4,
					"@media (max-width: 600px)": {
						p: 2,
					},
				}}
			>
				<Box
					sx={{
						position: "relative",
						width: "100%",
						maxWidth: width,
						bgcolor: "#fff",
						borderRadius: 4,
						p: 4,
						"@media (max-width: 600px)": {
							p: 2,
						},
						maxHeight: "calc(100vh - 64px)",
						overflow: "auto",
					}}
				>
					{!hideClose && (
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
								zIndex: 1,
								backgroundColor: "white",
								borderRadius: "50%",
							}}
						>
							<CloseIcon />
						</IconButton>
					)}
					{children}
				</Box>
			</Box>
		</Modal>
	);
}
