import { useContext, useState, useEffect } from "react";
import { PropTypes } from "./ProductActionBar.types";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	TextField,
	MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BasicModal } from "../BasicModal";
import { DeleteConfirmation } from "../DeleteConfirmation";
import { ICertification, ICompany, IEstimation, IProduct } from "../../models";
import {
	product as productActions,
	company as companyActions,
	certification as certificationActions,
	estimation as estimationsActions,
} from "../../controllers";
import { useMutation, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import ChooseProvider from "../ChooseProvider/ChooseProvider";
import { useTranslation } from "react-i18next";

type Provider = {
	id: number;
	name: string;
	certification_type: string;
};

export function ProductActionBar({
	selectedRows,
	refetch,
	filters,
	setFilters,
	setPaginationModel,
}: PropTypes) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showDelete, setShowDelete] = useState(false);
	const { user } = useContext(UserContext);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [companiesSearch, setCompaniesSearch] = useState("");
	const [providerTypes, setProviderTypes] = useState<string[] | undefined>(
		undefined
	);

	const handleSearchChange = (value: string) => {
		setCompaniesSearch(value);
	};

	const handleDeleteModalClose = () => {
		setShowDelete(false);
	};

	const deleteItem = async () => {
		for (const selectedRow of selectedRows) {
			await productActions.delete(
				selectedRow as IProduct,
				!user?.is_superuser ? user?.company.id : undefined
			);
		}
		handleDeleteModalClose();
		refetch();
	};

	const decertifyItem = async () => {
		for (const selectedRow of selectedRows) {
			await productActions.update({
				id: selectedRow.id,
				companyId: user?.company.id,
				decertifying_request: new Date().toISOString(),
			});
		}
		handleDeleteModalClose();
		refetch();
	};

	const getSelectionRowsTitle = () => {
		let title = "";
		selectedRows.forEach((selectedRow) => {
			title =
				title.length > 0
					? title + ", " + (selectedRow as IProduct).name
					: (selectedRow as IProduct).name;
		});

		return title;
	};

	const { refetch: refetchCompanies, isLoading: isLoadingCompanies } = useQuery(
		"companies-filter",
		() => companyActions.list(companiesSearch, undefined, "name", 10),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("productActionBar.somethingWrong"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		refetchCompanies();
	}, [refetchCompanies, companiesSearch]);

	const { refetch: refetchClimecoCertifications } = useQuery(
		"all-certifications",
		() =>
			certificationActions.list(
				false,
				user?.is_superuser ? undefined : user?.company.id,
				undefined,
				filters.companies
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("productActionBar.somethingWrong"), {
					variant: "error",
				});
			},
		}
	);

	const {
		isLoading: isLoadingCreateEstimation,
		mutate: createEstimationMutate,
	} = useMutation(
		"start-estimations",
		({
			estimation,
			certification,
		}: {
			estimation: IEstimation;
			certification: ICertification;
		}) =>
			estimationsActions.create({
				amount: estimation.amount,
				product: estimation.product.id,
				certificationProcess: certification!.id,
				companyId: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading, mutate } = useMutation(
		"certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo:
					selectedProvider?.certification_type ===
					"CLIMECO_INTERNAL_CERTIFICATION",
				name: "New",
				certification: selectedProvider?.id,
				companyId: (selectedRows[0] as IProduct).company!.id,
			}),
		{
			retry: false,
		}
	);

	useEffect(() => {
		setFilters((prev) => ({ ...prev, certifications: [] }));
		refetchClimecoCertifications();
	}, [refetchClimecoCertifications, filters.companies, setFilters]);

	const [showProviderModal, setShowProviderModal] = useState(false);

	const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
		null
	);

	const handleProvider = () => {
		if (
			selectedProvider?.certification_type === "CLIMECO_INTERNAL_CERTIFICATION"
		) {
			mutate(null, {
				onSuccess: (res) => {
					let index = 0;
					const createEstimation = (
						id: number,
						amount: number,
						product: IProduct,
						index: number
					) => {
						createEstimationMutate(
							{
								estimation: {
									id: id,
									amount: amount,
									product: product,
								},
								certification: res,
							},
							{
								onSuccess: () => {
									index += 1;
									if (index < selectedRows.length) {
										createEstimation(
											index,
											0,
											selectedRows[index] as IProduct,
											index
										);
									} else {
										navigate("/climeco-certificationss/" + res.id);
									}
								},
								onError: (error) => {
									const errorObj = error as any;
									let message = t("productActionBar.somethingWrong");
									if (
										(errorObj["response"]["data"] as Array<any>).length > 0 &&
										(errorObj["response"]["data"] as Array<any>)[0] ===
											"The product is outdated"
									) {
										message = t("productActionBar.productOutdated");
									}

									enqueueSnackbar(message, {
										variant: "error",
									});
								},
							}
						);
					};

					createEstimation(
						index,
						0,
						//0,
						selectedRows[index] as IProduct,
						index
					);
				},
				onError: (error) => {
					console.log(error);
					enqueueSnackbar(t("productActionBar.somethingWrong"), {
						variant: "error",
					});
				},
			});
		} else {
			navigate("/certifications/create", {
				state: { products: selectedRows as IProduct[] },
			});
		}
	};

	return (
		<Box sx={{ alignItems: "stretch", display: "flex", width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					flex: 1,
					alignItems: "stretch",
					justifyContent: "space-between",
					flexDirection: {
						xs: "column",
						sm: "column",
						md: "column",
						lg: "column-reverse",
					},
					gap: {
						xs: 2,
						sm: 2,
						md: 2,
						lg: 0,
					},
					marginTop: {
						xs: 2,
						sm: 2,
						md: 2,
						lg: 0,
					},
					width: "100%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column",
							sm: "column",
							md: "column",
							lg: "row",
						},
						flex: 1,
						alignItems: {
							xs: "flex-start",
							sm: "flex-start",
							md: "flex-start",
							lg: "flex-end",
						},
						justifyContent: "flex-start",
						marginTop: "0px",
						alignSelf: "flex-start",
						gap: 2,
						width: "100%",
					}}
				>
					{filters.companies && (
						<Autocomplete
							disablePortal
							id="company-box"
							options={companies}
							sx={{
								width: {
									xs: 240,
									sm: 240,
									md: 240,
									lg: 240,
									xl: 300,
								},
								borderRadius: "24px",
								borderColor: "#ececec",
								"& .MuiFormControl-root": {
									border: "none!important",
								},
								"& .MuiInputBase-root": {
									padding: "8px",
									overflow: "auto",
									maxHeight: "38px",
									border: "none!important",
								},
							}}
							multiple
							getOptionKey={(company) => company?.id ?? ""}
							getOptionLabel={(company) => company?.name ?? ""}
							onChange={(_, value) => {
								setPaginationModel({
									page: 0,
									pageSize: 10,
								});
								setFilters((prev) => ({
									...prev,
									companies: value.map((company) => company!.id),
								}));
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									placeholder={t("productActionBar.company")}
									sx={{
										maxHeight: 56,
										overflow: "auto!important",
										border: "1px solid #ececec",
										borderRadius: 2,
										backgroundColor: "#fff",
										"& input": {
											fontSize: 14,
										},
									}}
									onChange={(ev) => {
										if (ev.target.value !== "" || ev.target.value !== null) {
											handleSearchChange(ev.target.value);
										}
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{isLoadingCompanies ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					)}
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						select
						value={filters.certification}
						placeholder={t("productActionBar.typeOfCertification")}
						sx={{
							width: {
								xs: 240,
								sm: 240,
								md: 240,
								lg: 240,
								xl: 300,
							},
							maxHeight: "38.13px",
							overflow: "auto!important",
							border: "1px solid #ececec",
							borderRadius: 2,
							backgroundColor: "#fff",
							"& input": {
								fontSize: 14,
							},
							"& .MuiSelect-select": {
								paddingTop: "6px!important",
								paddingBottom: "6px!important",
								backgroundColor: "#fff",
							},
							"&.MuiFormControl-root": {
								borderRadius: "20px",
							},
							"& .MuiOutlinedInput-root": {
								borderRadius: "20px",
								height: "38px",
								backgroundColor: "#fff",
							},
						}}
						onChange={(e) => {
							setPaginationModel({
								page: 0,
								pageSize: 10,
							});
							setFilters((prev) => ({
								...prev,
								certification: e.target.value,
							}));
						}}
					>
						{[
							t("productActionBar.certifications.all"),
							t("productActionBar.certifications.none"),
							t("productActionBar.certifications.climeco"),
							t("productActionBar.certifications.amazon"),
						].map((certification) => {
							return (
								<MenuItem value={certification} key={certification}>
									{certification}
								</MenuItem>
							);
						})}
					</TextField>
				</Box>

				<Box
					sx={{
						display: "flex",
						flex: 1,
						alignItems: "center",
						justifyContent: "flex-end",
						flexDirection: {
							xs: "row",
							sm: "row",
							md: "row",
							lg: "row",
						},
						gap: {
							xs: 0.5,
							sm: 0.5,
							md: 0.5,
							lg: 0.5,
						},
						marginTop: {
							xs: 0,
							sm: 0,
							md: 0,
							lg: 0,
						},
						width: "100%",
					}}
				>
					{selectedRows && selectedRows.length ? (
						<>
							<Button
								variant="contained"
								sx={{ marginRight: 2, maxWidth: "200px", minHeight: 34 }}
								disabled={
									isLoading ||
									isLoadingCreateEstimation ||
									selectedRows.find((row) => {
										return (row as IProduct).certification_processes?.find(
											(certificationProcess) =>
												certificationProcess.certification
													?.certification_type ===
													"DEFAULT_EXTERNAL_CERTIFICATION" ||
												(certificationProcess.certification
													?.certification_type ===
													"CLIMECO_INTERNAL_CERTIFICATION" &&
													certificationProcess.status !== "COMPLETED")
										);
									})
										? true
										: false
								}
								onClick={() => {
									const hasClimeCo = selectedRows.find((row) => {
										return (row as IProduct).certification_processes?.find(
											(certificationProcess) =>
												certificationProcess.certification
													?.certification_type ===
												"CLIMECO_INTERNAL_CERTIFICATION"
										);
									});
									setProviderTypes(
										hasClimeCo
											? ["DEFAULT_EXTERNAL_CERTIFICATION"]
											: ["CLIMECO_INTERNAL_CERTIFICATION"]
									);
									setShowProviderModal(true);
								}}
							>
								{isLoading || isLoadingCreateEstimation ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("productActionBar.certify")
								)}
							</Button>
							{user?.is_superuser ? (
								<Button
									variant="outlined"
									sx={{ marginRight: 2, maxWidth: "200px" }}
									onClick={() => {
										setShowDelete(true);
									}}
								>
									{t("productActionBar.delete")}
								</Button>
							) : (
								<Button
									variant="outlined"
									sx={{ marginRight: 2, maxWidth: "200px" }}
									disabled={
										selectedRows.find((row) => {
											return (
												(row as IProduct).certification_processes?.length === 0
											);
										})
											? true
											: false
									}
									onClick={() => {
										setShowDelete(true);
									}}
								>
									{t("productActionBar.decertifying")}
								</Button>
							)}
						</>
					) : (
						<></>
					)}

					<Button
						variant="outlined"
						sx={{ marginLeft: 1, textTransform: "none!important" }}
						onClick={() => {
							navigate("/lca");
						}}
					>
						{t("productActionBar.addProducts")}
					</Button>
				</Box>
			</Box>
			<BasicModal
				width={600}
				showModal={showDelete}
				handleClose={handleDeleteModalClose}
				children={
					<DeleteConfirmation
						title={getSelectionRowsTitle()}
						handleDeleteModalClose={handleDeleteModalClose}
						callback={!user?.is_superuser ? decertifyItem : deleteItem}
						decertifying={!user?.is_superuser}
					/>
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showProviderModal}
				handleClose={() => {
					setShowProviderModal(false);
				}}
				children={
					<ChooseProvider
						selectedProvider={selectedProvider}
						setSelectedProvider={setSelectedProvider}
						mutate={handleProvider}
						providerTypes={providerTypes}
					/>
				}
			/>
		</Box>
	);
}
