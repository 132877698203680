import { Box, Tabs, Tab, Typography, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { PropTypes } from "./MyApplications.types";
import CertificationInfo from "../CertificationInfo/CertificationInfo";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export function MyApplications({ certifications, loading }: PropTypes) {
	const { t } = useTranslation();
	const completedCertifications = certifications.filter((certification) =>
		certification.certification_process_steps?.every(
			(step) => step.status === "COMPLETED"
		)
	);

	const pendingCertifications = certifications.filter((certification) => {
		const stepType = certification.certification_process_steps?.find(
			(step) => step.status === "PENDING"
		)?.step?.step_type;

		return stepType && ["CERTIFICATION_SUMMARY"].includes(stepType);
	});

	const actionRequiredCertifications = certifications.filter(
		(certification) => {
			const stepType = certification.certification_process_steps?.find(
				(step) => step.status === "PENDING"
			)?.step?.step_type;
			return (
				stepType &&
				["CHOOSE_PRODUCTS", "PURCHASE_OFFSETS", "REDIRECTION"].includes(
					stepType
				)
			);
		}
	);

	const initialTabValue =
		actionRequiredCertifications.length > 0
			? 0
			: pendingCertifications.length > 0
			? 1
			: 2;
	const [value, setValue] = useState(initialTabValue);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	function CustomTabPanel(props: TabPanelProps) {
		const { children, value, index } = props;

		return (
			<Box>
				{value === index && (
					<Box
						sx={{
							p: 0,
							pt: 0,
							display: "flex",
							flexDirection: "column",
							gap: 2,
						}}
					>
						{children}
					</Box>
				)}
			</Box>
		);
	}

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				gap: 2,
				maxWidth: 800,
				width: "100%",
				marginBottom: 6,
				maxHeight: { xs: 600, md: 400 },
			}}
		>
			{loading ? (
				<CircularProgress size={12} />
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						width: {
							xs: "100%",
						},
					}}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="tabs"
						sx={{
							"& .MuiTab-root": {
								textTransform: "capitalize",
								paddingBottom: 0,
							},
						}}
					>
						<Tab
							label={t("myApplications.tabs.actionRequired")}
							{...a11yProps(0)}
						/>
						<Tab label={t("myApplications.tabs.inReview")} {...a11yProps(1)} />
						<Tab label={t("myApplications.tabs.completed")} {...a11yProps(2)} />
					</Tabs>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							overflow: "auto",
							mt: 3,
						}}
					>
						<CustomTabPanel value={value} index={0}>
							{actionRequiredCertifications &&
							actionRequiredCertifications.length > 0 ? (
								actionRequiredCertifications.map((certification) => {
									return (
										<CertificationInfo
											key={certification.id}
											certification={certification}
										/>
									);
								})
							) : (
								<Typography variant="body2" sx={{ ml: 2 }}>
									{t("myApplications.noActionRequired")}
								</Typography>
							)}
						</CustomTabPanel>
						<CustomTabPanel value={value} index={1}>
							{pendingCertifications && pendingCertifications.length > 0 ? (
								pendingCertifications.map((certification) => {
									return (
										<CertificationInfo
											key={certification.id}
											certification={certification}
										/>
									);
								})
							) : (
								<Typography variant="body2" sx={{ ml: 2 }}>
									{t("myApplications.noPendingReview")}
								</Typography>
							)}
						</CustomTabPanel>
						<CustomTabPanel value={value} index={2}>
							{completedCertifications && completedCertifications.length > 0 ? (
								completedCertifications.map((certification) => {
									return (
										<CertificationInfo
											key={certification.id}
											certification={certification}
											completed
										/>
									);
								})
							) : (
								<Box>
									<Typography variant="body2" sx={{ ml: 2 }}>
										{t("myApplications.noCompleted")}
									</Typography>
								</Box>
							)}
						</CustomTabPanel>
					</Box>
				</Box>
			)}
		</Box>
	);
}
