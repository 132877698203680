import { Box, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import { PropTypes } from "./ProductCard.types";
import MarketplaceSelector from "../MarketplaceSelector/MarketplaceSelector";
import { HiOutlineTrash as Trash } from "react-icons/hi2";
import { useTranslation } from "react-i18next";

export default function ProductCard({
	element,
	index,
	setSelectedElements,
	selectedElements,
	removeElement,
	selectedMarketplaces,
	availableElements,
}: PropTypes) {
	const { t } = useTranslation();
	const isValidASIN = (asin: string): boolean => {
		const asinRegex = /^(B[\dA-Z]{9}|\d{9}[\dX])$/;

		const duplicatedIndex = selectedElements.findIndex((element, auxIndex) => {
			return element.asin === asin && auxIndex !== index;
		});

		return asinRegex.test(asin) && duplicatedIndex === -1;
	};
	return (
		<Box
			sx={{
				border: "1px solid #B1D1F0",
				borderRadius: "12px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					gap: 2,
					padding: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						flex: 1,
						width: "100%",
						marginBottom: 1,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: 2,
							justifyContent: "flex-start",
							alignItems: "center",
							flex: 1,
						}}
					>
						<Typography
							variant="body2"
							sx={{
								fontSize: "14px",
								color: "#757575",
							}}
						>
							{String(index + 1).padStart(2, "0")}
						</Typography>

						<Typography
							variant="body2"
							sx={{
								display: "flex",
								width: "100%",
								alignSelf: "center",
								fontSize: 16,
								fontWeight: "bold",
							}}
						>
							{element?.name}
						</Typography>
					</Box>
					<IconButton
						color="primary"
						aria-label="remove source"
						size="small"
						onClick={() => {
							removeElement(index);
						}}
					>
						<Trash />
					</IconButton>
				</Box>
				<Box sx={{ flex: 1, marginLeft: "26px", width: "calc(100% - 26px)" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: 2,
							flex: 1,
							alignItems: "flex-start",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								flexGrow: 1,
								width: "100%",
								minWidth: 150,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<span
								style={{
									textTransform: "capitalize",
									fontSize: 14,
									fontWeight: 700,
									marginBottom: "10px",
									minHeight: "24px",
									textAlign: "left",
									width: "100%",
								}}
							>
								{t("productCard.brand")}
								<span style={{ color: "#B61313" }}>*</span>
							</span>
							<Typography
								variant="body2"
								sx={{
									display: "flex",
									width: "100%",
									alignSelf: "center",
								}}
							>
								{element?.brand}
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								flexGrow: 1,
								width: "100%",
								minWidth: 150,
								textAlign: "left",
							}}
						>
							<span
								style={{
									textTransform: "capitalize",
									fontSize: 14,
									fontWeight: 700,
									marginBottom: "10px",
									minHeight: "24px",
									textAlign: "left",
									alignSelf: "flex-start",
								}}
							>
								{t("productCard.upc")}
							</span>
							<Typography
								variant="body2"
								sx={{
									display: "flex",
									alignSelf: "center",
									width: "100%",
								}}
							>
								{element?.upc ? element?.upc : "-"}
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: 2,
							flex: 1,
							alignItems: "flex-start",
							marginTop: 4,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								flexGrow: 1,
								width: "100%",
								minWidth: 150,
							}}
						>
							<span
								style={{
									textTransform: "capitalize",
									fontSize: 14,
									fontWeight: 700,
									marginBottom: "10px",
									minHeight: "24px",
									textAlign: "left",
									width: "100%",
								}}
							>
								{t("productCard.asin")}
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="asin"
								value={element?.asin}
								placeholder={t("productCard.asinPlaceholder")}
								onChange={(e) => {
									const sources = [...selectedElements];
									sources[index].asin = e.target.value;
									setSelectedElements(sources);
								}}
								sx={{
									display: "flex",
									width: "100%",
									borderRadius: "8px",
									"& .MuiInputBase-input": {
										borderRadius: "8px",
										padding: "11.5px 14px",
										borderColor:
											element?.asin && isValidASIN(element?.asin)
												? "#C9C9C9"
												: "#eb4343",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
										borderRadius: "8px",
										borderColor:
											element?.asin && isValidASIN(element?.asin)
												? "#C9C9C9"
												: "#eb4343",
									},
								}}
								disabled={
									availableElements.find(
										(aElement) => aElement.id === element.id && aElement.asin
									) !== undefined
								}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								flexGrow: 1,
								width: "100%",
								minWidth: 150,
							}}
						>
							<span
								style={{
									textTransform: "capitalize",
									fontSize: 14,
									fontWeight: 700,
									marginBottom: "10px",
									minHeight: "24px",
									textAlign: "left",
									width: "100%",
								}}
							>
								{t("productCard.marketplaces")}
							</span>
							<MarketplaceSelector
								index={index}
								setSelectedElements={setSelectedElements}
								selectedElements={selectedElements}
								removeElement={removeElement}
								selectedMarketplaces={selectedMarketplaces}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
