import { Box, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { MdOutlineUploadFile as UploadFileIcon } from "react-icons/md";
import {
	BsFillCheckCircleFill as CheckIcon,
	BsDashCircleFill as RemoveIcon,
} from "react-icons/bs";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
	fileContainer: {
		textAlign: "center",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "8px",
		"&:hover": {
			borderColor: "#003899",
		},
	},
	fileName: {
		textAlign: "center",
		width: 150,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		marginTop: "10px",
	},

	inputHidden: {
		display: "none",
	},
});

export function UploadFile({
	padding,
	flex,
	accept,
	value,
	disabled,
	loading,
	onFileChange,
	id,
	smallGrid,
}: {
	padding: number;
	flex?: number;
	accept: string;
	value: File | string | null | undefined;
	disabled?: boolean;
	loading?: boolean;
	onFileChange: (file: File | null, id?: number) => void;
	id?: number;
	smallGrid?: boolean;
}) {
	const classes = useStyles();
	const [draggingOver, setDraggingOver] = useState<boolean>(false);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [uniqueId] = useState(Math.random());
	const { enqueueSnackbar } = useSnackbar();
	const input = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();

	const handleFileChange = (files: FileList | null) => {
		if (files && files.length > 0) {
			const file = files[0];

			if (file.size > 10485760) {
				enqueueSnackbar(t("uploadFile.fileTooLarge"), {
					variant: "error",
				});
			} else {
				const renameFile = (originalFile: File, newName: string): File => {
					return new File([originalFile], newName, {
						type: originalFile.type,
						lastModified: originalFile.lastModified,
					});
				};
				const renamedFile = renameFile(file, file.name.slice(0, 85));
				setSelectedFile(renamedFile);
				onFileChange(renamedFile, id);
			}
		} else {
			setSelectedFile(null);
			onFileChange(null, id);
		}
	};

	const handleFileChangeEvent = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const files = event.target.files;
		handleFileChange(files);
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const droppedFiles = event.dataTransfer.files;
		handleFileChange(droppedFiles);
		setDraggingOver(false);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDraggingOver(true);
	};

	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDraggingOver(false);
	};

	useEffect(() => {
		if (!value && input.current) {
			setSelectedFile(null);
			(input.current as HTMLInputElement).value = "";
		}
	}, [value, id]);

	const handleClick = () => {
		const inputElement = document.getElementById(
			`file-upload${uniqueId}${id ?? 0}`
		);
		if (inputElement) {
			inputElement.click();
		}
	};

	return (
		<>
			<Box
				className={classes.fileContainer}
				onClick={handleClick}
				onDrop={(event: React.DragEvent<HTMLDivElement>) => {
					if (!disabled) {
						handleDrop(event);
						setDraggingOver(false);
					}
				}}
				onDragOver={(event: React.DragEvent<HTMLDivElement>) => {
					event.preventDefault();
					if (!disabled) {
						handleDragOver(event);
						setDraggingOver(true);
					}
				}}
				onDragLeave={(event: React.DragEvent<HTMLDivElement>) => {
					if (!disabled) {
						handleDragLeave(event);
						setDraggingOver(false);
					}
				}}
				sx={{
					padding: padding || "12px",
					flex: flex,
					backgroundColor: draggingOver
						? "#F5FAFF"
						: selectedFile || value
						? "#F5FAFF"
						: "transparent",
					border: draggingOver
						? "2px solid #c5dffa"
						: selectedFile || value
						? "none"
						: "2px dashed #ccc",

					width: "100%",
					height: !smallGrid ? "150px" : "auto",
				}}
			>
				{selectedFile || value ? (
					!smallGrid && (
						<Box sx={{ width: "100%" }}>
							<CheckIcon
								style={{ color: "#34918E", fontSize: "24px", marginBottom: 4 }}
							/>
						</Box>
					)
				) : (
					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<UploadFileIcon
							style={{ color: "#003899", fontSize: "24px", marginBottom: 4 }}
						/>
						{draggingOver ? (
							<span style={{ color: "#3498db" }}>
								{t("uploadFile.dropHere")}
							</span>
						) : (
							<>
								<span style={{ color: "#003899" }}>
									{t("uploadFile.clickToUpload")}
								</span>{" "}
								{t("uploadFile.dragAndDrop")}
							</>
						)}
					</Box>
				)}
				<input
					type="file"
					id={"file-upload" + uniqueId + "" + (id ?? 0)}
					accept={accept}
					className={classes.inputHidden}
					onChange={handleFileChangeEvent}
					disabled={disabled}
					ref={input}
				/>
				<Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
					<Typography
						variant="body2"
						sx={{ textAlign: "center", marginLeft: 1 }}
						className={classes.fileName}
					>
						{selectedFile ? (
							selectedFile.name
						) : value ? (
							typeof value === "object" && "name" in value ? (
								(value as File).name
							) : (
								<a href={value} target="_blank" rel="noreferrer noopener">
									{value.split("/")[value.split("/").length - 1]}
								</a>
							)
						) : (
							t("uploadFile.noFile")
						)}
					</Typography>
				</Box>
				{(value || selectedFile) && !smallGrid && (
					<Box
						component="button"
						onClick={(event) => {
							event.stopPropagation();
							setSelectedFile(null);
							onFileChange(null, id);
							if (input.current) {
								(input.current as HTMLInputElement).value = "";
							}
						}}
						sx={{
							display: "flex",
							alignItems: "center",
							alignSelf: "flex-end",
							background: "none",
							border: "none",
							cursor: "pointer",
							padding: 0,
							marginTop: 3,
							marginRight: 2,
						}}
					>
						<RemoveIcon
							size={12}
							style={{
								marginRight: 4,
							}}
						/>
						<Typography
							sx={{
								fontSize: 12,
							}}
							variant="body1"
						>
							{t("uploadFile.removeAttachment")}
						</Typography>
					</Box>
				)}

				{loading && <CircularProgress size={24} sx={{ marginTop: 2 }} />}
			</Box>
		</>
	);
}
