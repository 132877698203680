import { IProduct, IProductLCA } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { ProductTypes } from "./product.types"

class Product {
    async list(companyId?: number, search?: string, companies?: Array<number>, certificationProcesses?: string | null, page?: number, sort: string = "-updated_at", pageSize: number = 10): Promise<{ count: number, results: Array<IProduct> }> {
        const endpoint = companyId ? "companies/" + companyId + "/products" : "products"
        const params = new URLSearchParams();
        params.append("page", page?.toString() ?? "1");
        params.append("search", search ?? "");
        params.append("ordering", sort);
        params.append("page_size", pageSize.toString());
        if (companies && companies.length > 0) {
            params.append("company__in", companies.join(","));
        }
        if (certificationProcesses && certificationProcesses !== "All certifications") {
            if (certificationProcesses === "Not certified") {
                params.append("certification_processes__certification__certification_type__isnull", "True")
            }
            else {
                params.append("certification_processes__certification__certification_type", certificationProcesses === "ClimeCo certified" ? "CLIMECO_INTERNAL_CERTIFICATION" : "DEFAULT_EXTERNAL_CERTIFICATION")
            }
        }
        const response = await connectionsClient.client.get(endpoint, {
            params
        });
        return response.data;
    }

    async getOne(productId: number, companyId?: number): Promise<IProduct> {
        const endpoint = companyId ? "companies/" + companyId + "/products/" + productId : "products/" + productId
        const response = await connectionsClient.client.get(endpoint);
        return response.data;
    }

    async create({ name, description, companyId, external_id, sku, asin, ean, isbn, upc, gtin, websites, status, brand }: ProductTypes.Create): Promise<IProduct> {
        const endpoint = companyId ? "companies/" + companyId + "/products" : "products";

        const response = await connectionsClient.client.post(endpoint, {
            name: name, description: description, external_id: external_id, sku: sku, asin: asin, ean: ean, isbn: isbn, upc: upc, gtin: gtin, websites: websites, brand: brand, status: status,
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data;
    }

    async update({ id, name, description, companyId, external_id, sku, asin, ean, isbn, upc, gtin, websites, status, marketplaces, brand, decertifying_request }: ProductTypes.Update): Promise<IProduct> {
        const endpoint = companyId ? "companies/" + companyId + "/products/" + id : "products/" + id

        const response = await connectionsClient.client.patch(endpoint, {
            name: name, description: description, external_id: external_id, sku: sku, asin: asin, ean: ean, isbn: isbn, upc: upc, gtin: gtin, websites: websites, brand: brand, status: status, marketplaces, decertifying_request
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data;
    }

    async delete(product: IProduct, companyId?: number): Promise<{ res: string }> {
        const endpoint = companyId ? "companies/" + companyId + "/products/" + product.id : "products/" + product.id
        const response = await connectionsClient.client.delete(endpoint);
        return response.data;
    }

    async createLCA({ lca_document, lca_provider, certification_date, emissions, companyId, productId }: ProductTypes.CreateLCA): Promise<IProductLCA> {
        const endpoint = companyId ? "companies/" + companyId + "/products/" + productId + "/lca-documents/" : "products/" + productId + "/lca-documents/"

        const formData = new FormData();
        formData.append("lca_provider", lca_provider!);
        formData.append("certification_date", certification_date!);
        formData.append("emissions", emissions.toString());
        if (typeof lca_document === "object") {
            formData.append("lca_document", lca_document);
        }

        const response = await connectionsClient.client.post(endpoint, formData, { headers: { "Content-Type": "multipart/form-data" } });
        return response.data;
    }

    async updateLCA({ id, lca_document, lca_provider, certification_date, emissions, companyId, productId }: ProductTypes.UpdateLCA): Promise<IProductLCA> {
        const endpoint = companyId ? "companies/" + companyId + "/products/" + productId + "/lca-documents/" + id : "products/" + productId + "/lca-documents/" + id

        const formData = new FormData();
        if (lca_provider && lca_provider.length > 0) {
            formData.append("lca_provider", lca_provider!);
        }
        formData.append("certification_date", certification_date!);
        formData.append("emissions", emissions.toString());
        if (typeof lca_document === "object") {
            formData.append("lca_document", lca_document);
        }

        const response = await connectionsClient.client.patch(endpoint, formData, { headers: { "Content-Type": "multipart/form-data" } });
        return response.data;
    }

    async deleteLCA(id: number, productId: number, companyId?: number): Promise<{ res: string }> {
        const endpoint = companyId ? "companies/" + companyId + "/products/" + productId + "/lca-documents/" + id : "products/" + productId + "/lca-documents/" + id
        const response = await connectionsClient.client.delete(endpoint);
        return response.data;
    }
}

export const product = new Product();