import { ICohort } from "../../models";
import { connectionsClient } from "../../utils/ConnectionsClient";
import { DashboardTypes } from "./dashboard.types";

class Dashboard {
    async productCount(companyId?: number): Promise<{ current_value: number, past_value: number }> {
        const endpoint = "dashboard/products-count/";
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                company_id: companyId
            }
        });
        return response.data;
    }

    async productLCACount(companyId?: number): Promise<{ current_value: number, past_value: number }> {
        const endpoint = "dashboard/products-lca-count/";
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                company_id: companyId
            }
        });
        return response.data;
    }

    async climecoCertifiedSum(companyId?: number): Promise<{ current_value: number, past_value: number }> {
        const endpoint = "dashboard/climeco-certified-sum/";
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                company_id: companyId
            }
        });
        return response.data;
    }

    async amazonCertifiedSum(companyId?: number): Promise<{ current_value: number, past_value: number }> {
        const endpoint = "dashboard/amazon-certified-sum/";
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                company_id: companyId
            }
        });
        return response.data;
    }

    async productEmissionsSum(companyId?: number): Promise<{ current_value: number, past_value: number }> {
        const endpoint = "dashboard/products-emissions-sum/";
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                company_id: companyId
            }
        });
        return response.data;
    }

    async offsetsSum(companyId?: number): Promise<{ current_value: number, past_value: number }> {
        const endpoint = "dashboard/offsets-sum/";
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                company_id: companyId
            }
        });
        return response.data;
    }
    async productCreationToRequestApprovalAverageTime(cohorts?: Array<number>): Promise<{ current: number, previous: number }> {
        const endpoint = "dashboard/products-creation-to-request-approval-average-time/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return { current: response.data["average_time"], previous: response.data["past_average_time"] };
    }

    async productsInCertification(cohorts?: Array<number>): Promise<{ current: number, previous: number }> {
        const endpoint = "dashboard/products-in-certification/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return { current: response.data["total_products"], previous: response.data["past_total_products"] };
    }

    async productRequestApprovalToApprovalAverageTime(cohorts?: Array<number>): Promise<{ current: number, previous: number }> {
        const endpoint = "dashboard/products-request-approval-to-approval-average-time/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return { current: response.data["certification_steps_time"], previous: response.data["past_certification_steps_time"] };
    }

    async adminProductApprovalAverageTime(cohorts?: Array<number>): Promise<{ current: number, previous: number }> {
        const endpoint = "dashboard/products-approval-average-time/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return { current: response.data["average_time"], previous: response.data["past_average_time"] };
    }

    async productsCertified(cohorts?: Array<number>): Promise<Array<{ day: string, count: number }>> {
        const endpoint = "dashboard/products-certified/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return response.data["certification_steps"];
    }

    async offsetsSold(cohorts?: Array<number>): Promise<Array<{ day: string, count: number }>> {
        const endpoint = "dashboard/offsets-sold/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return response.data["offsets"];
    }

    async revenue(cohorts?: Array<number>): Promise<{ current: number, previous: number }> {
        const endpoint = "dashboard/revenue/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return { current: response.data["offsets_revenue"], previous: response.data["past_offsets_revenue"] };
    }

    async offsetsRevenue(cohorts?: Array<number>): Promise<{ current: number, previous: number }> {
        const endpoint = "dashboard/offsets-revenue/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return { current: response.data["total_offsets_revenue"], previous: response.data["past_offsets_revenue"] };
    }

    async productsRevenue(cohorts?: Array<number>): Promise<{ current: number, previous: number }> {
        const endpoint = "dashboard/products-revenue/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return { current: response.data["total_offsets_revenue"], previous: response.data["past_offsets_revenue"] };
    }

    async registrationRevenue(cohorts?: Array<number>): Promise<{ current: number, previous: number }> {
        const endpoint = "dashboard/registration-revenue/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return { current: response.data["total_offsets_revenue"], previous: response.data["past_offsets_revenue"] };
    }

    async cohorts(name?: string): Promise<Array<ICohort>> {
        const endpoint = "cohorts/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                name
            }
        });
        return response.data.results;
    }

    async companiesSteps(cohorts?: Array<number>): Promise<DashboardTypes.CompanySteps> {
        const endpoint = "dashboard/companies-steps/"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                cohorts: cohorts
            }
        });
        return response.data;
    }
}

export const dashboard = new Dashboard();