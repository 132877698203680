import React, { useState } from "react";
import { DataContextType, PropTypes } from "./DataContext.types";

export const DataContext = React.createContext<DataContextType>({
	isEditing: true,
	setIsEditing: (isEditing: boolean) => {},
});

export const DataProvider: React.FC<PropTypes> = ({ children }) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);

	return (
		<DataContext.Provider value={{ isEditing, setIsEditing }}>
			{children}
		</DataContext.Provider>
	);
};
