import { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Heading } from "../Heading";
import {
	ICertification,
	ICertificationSteps,
	ICompany,
	IEntity,
	IEstimation,
} from "../../models";
import { useNavigate } from "react-router-dom";
import DataTable from "../DataTable/DataTable";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
	GridValueFormatterParams,
	GridValueGetterParams,
} from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { certification as certificationActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { Filters } from "../../routes/Routes.types";
import { ExternalCertificationActionBar } from "../ExternalCertificationActionBar";
import { PropTypes } from "./Certifications.types";
import { useTranslation } from "react-i18next";

export function Certifications({ companyId }: PropTypes) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const [certifications, setCertifications] = useState<Array<ICertification>>(
		[]
	);
	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser ? { companies: [] } : {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: "updated_at",
			sort: "desc",
		})
	);
	const [rowCount, setRowCount] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"certifications",
		() =>
			certificationActions.list(
				false,
				companyId
					? companyId
					: user?.is_superuser
					? undefined
					: user?.company.id,
				search,
				filters.companies,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field === "estimations"
								? "estimations__product__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCertifications(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.somethingWentWrong"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	const navigate = useNavigate();
	const columns = [
		{
			field: "id",
			headerName: t("certifications.id"),
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
		},
		{
			field: "name",
			headerName: t("certifications.title"),
			flex: 1,
			minWidth: 150,
		},
		{
			field: "estimations",
			headerName: t("certifications.products"),
			sortable: false,
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<any>) => {
				if (params.value == null) {
					return "";
				}

				return (
					params.value &&
					params.value
						.map((estimation: IEstimation) => estimation.product?.name)
						.join(", ")
				);
			},
		},
		{
			field: "incomplete_steps",
			headerName: t("certifications.step"),
			sortable: true,
			flex: 1,
			minWidth: 150,
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				const getStep = () => {
					if (
						row.row.certification &&
						row.row.certification_process_steps &&
						row.row.certification_process_steps.length > 0
					) {
						const rejectedStep = row.row.certification_process_steps.findIndex(
							(step: ICertificationSteps) => step.status === "REJECTED"
						);

						const pendingStep = row.row.certification_process_steps.findIndex(
							(step: ICertificationSteps) => step.status === "PENDING"
						);

						return rejectedStep > -1
							? rejectedStep
							: pendingStep > -1
							? pendingStep
							: row.row.certification_process_steps.length - 1;
					} else {
						return 0;
					}
				};

				return (
					<Typography variant="body2">
						{getStep() < row.row.certification_process_steps.length &&
						row.row.certification_process_steps.length > 0 &&
						row.row.certification_process_steps[getStep()].step
							? row.row.certification_process_steps[getStep()].step.step_title
							: "--"}
					</Typography>
				);
			},
		},
		{
			field: "company",
			headerName: t("certifications.company"),
			valueFormatter: (params: GridValueFormatterParams<ICompany>) => {
				if (params.value == null) {
					return "";
				}
				return params.value.name;
			},
			flex: 1,
			minWidth: 150,
		},
		{
			field: "certification_date",
			headerName: t("certifications.certificationDate"),
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.certification_process_steps;
			},
			valueFormatter: (
				params: GridValueFormatterParams<Array<ICertificationSteps>>
			) => {
				if (
					params.value &&
					params.value.length > 0 &&
					params.value[params.value.length - 1] &&
					params.value[params.value.length - 1].status === "COMPLETED" &&
					params.value[params.value.length - 1].step?.step_type === "OTHER"
				) {
					return new Intl.DateTimeFormat("en", {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
						timeZone: "UTC",
					}).format(
						new Date(params.value[params.value.length - 1].updated_at!)
					);
				} else {
					return t("certifications.notCertifiedYet");
				}
			},
			flex: 1,
			minWidth: 150,
		},
		{
			field: "action",
			headerName: t("certifications.actions"),
			sortable: false,
			headerAlign: "right",
			align: "right",
			minWidth: 220,
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					navigate("/certifications/" + row.id);
				};

				return (
					<Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
						<Button onClick={onClickEdit}>{t("certifications.edit")}</Button>
						{row.row.csv && (
							<Button
								variant="contained"
								sx={{ maxWidth: 150 }}
								onClick={(e) => {
									e.stopPropagation();
									const link = document.createElement("a");
									link.download = row.row.csv!.split("/").pop()!;
									link.href = row.row.csv!;
									link.click();
								}}
							>
								{t("certifications.downloadCsv")}
							</Button>
						)}
					</Box>
				);
			},
		},
	];

	if (!user?.is_superuser) {
		columns.splice(4, 1);
		columns.splice(4, 1);
	} else if (companyId) {
		columns.splice(4, 1);
	}
	return (
		<Box>
			{!companyId && (
				<Heading
					title={t("certifications.headingTitle")}
					showSearch={true}
					setSearch={setSearch}
					actions={() => (
						<ExternalCertificationActionBar
							selectedRows={selectedRows}
							refetch={refetch}
							filters={filters}
							setFilters={setFilters}
						/>
					)}
				/>
			)}
			<DataTable
				rows={certifications}
				columns={columns}
				setSelectedRows={setSelectedRows}
				rowCount={rowCount}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "28px",
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-columnHeaders": {},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none!important",
					},
				}}
			/>
		</Box>
	);
}
