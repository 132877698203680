import { IService } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"

class Service {
    async list(search?: string): Promise<Array<IService>> {
        const endpoint = "services/"
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: "-created_at" }
        });
        return response.data.results;
    }

}

export const service = new Service();