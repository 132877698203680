import { Typography, Box, Button } from "@mui/material";
import { PropTypes } from "./PurchaseAgree.types";
import { useTranslation } from "react-i18next";

export function PurchaseAgree({
	title,
	text,
	button1,
	button1Callback,
	button2,
	button2Callback,
	amount,
	balance = 0,
}: PropTypes) {
	const { t } = useTranslation();

	return (
		<Box sx={{ width: "100%" }}>
			<Typography variant="h6" sx={{ textAlign: "center", marginBottom: 2 }}>
				{title}
			</Typography>
			<Typography
				variant="body2"
				sx={{
					borderTop: "1px solid #D7EBFF",
					paddingTop: 4,
					textAlign: "center",
					marginBottom: 2,
				}}
			>
				{text}
			</Typography>

			<Box
				sx={{
					backgroundColor: "#FAFDFF",
					padding: 4,
					minHeight: "256px",
					display: "flex",
					flexDirection: "column",
					borderTop: "1px solid #D7EBFF",
					borderBottom: "1px solid #D7EBFF",
				}}
			>
				<Box
					sx={{
						minHeight: "256px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: {
								xs: "column",
								sm: "row",
							},
						}}
					>
						<Typography>{t("certificationAgree.priceOfOffsets")}:</Typography>

						<Typography>
							{Intl.NumberFormat("en", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
								style: "currency",
								currency: "usd",
							}).format(12.5)}{" "}
							x{" "}
							{Intl.NumberFormat("en", {
								maximumFractionDigits: 0,
								minimumFractionDigits: 0,
							}).format(amount)}{" "}
							={" "}
							{Intl.NumberFormat("en", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
								style: "currency",
								currency: "usd",
							}).format(amount * 12.5)}
						</Typography>
					</Box>

					{false && (balance ?? 0) > 0 && (
						<Box
							sx={{
								display: "flex",
								marginTop: 1,
								justifyContent: "space-between",
								flexDirection: {
									xs: "column",
									sm: "row",
								},
							}}
						>
							<Typography>{t("certificationAgree.currentBalance")}</Typography>
							<Typography textAlign={"right"}>
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2,
									style: "currency",
									currency: "usd",
								}).format(0 /*balance ? -balance : 0*/)}
							</Typography>
						</Box>
					)}
					<Box
						sx={{
							display: "flex",
							marginTop: 4,
							padding: 0,
							justifyContent: "space-between",
						}}
					>
						<Typography sx={{ fontWeight: 500 }}>
							{t("certificationAgree.totalPrice")}
						</Typography>
						<Typography sx={{ fontWeight: 500, color: "#34918E" }}>
							{Intl.NumberFormat("en", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
								style: "currency",
								currency: "usd",
							}).format(amount * 12.5)}
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="outlined"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						marginRight: 2,
					}}
					onClick={button1Callback}
				>
					{button1}
				</Button>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						minHeight: 34,
					}}
					onClick={button2Callback}
				>
					{button2}
				</Button>
			</Box>
		</Box>
	);
}
