import React, { useEffect } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface LoadingBarProps {
	progress: number;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ progress }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	useEffect(() => {
		if (progress === 100) {
			enqueueSnackbar(t("loadingBar.processCompleted"), {
				variant: "success",
			});
		}
	}, [enqueueSnackbar, progress, t]);

	return (
		<Box
			sx={{
				width: "100%",
				textAlign: "center",
				padding: 6,
			}}
		>
			<Typography variant="body1" sx={{ marginBottom: 1 }}>
				{Math.ceil(progress)}% {t("loadingBar.complete")}
			</Typography>
			<LinearProgress
				variant="determinate"
				value={progress}
				sx={{
					height: 10,
					borderRadius: 5,
					transition: "all 0.5s ease",
					"& .MuiLinearProgress-bar": {
						borderRadius: 5,
						backgroundColor: "#6e8edb",
						transition: "width 0.5s ease",
					},
					backgroundColor: "#f0f0f0",
				}}
			/>
		</Box>
	);
};
export default LoadingBar;
