import React, { useContext, useEffect, useState } from "react";
import {
	Alert,
	Box,
	CircularProgress,
	Typography,
	Button,
	useMediaQuery,
	TableBody,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TextField,
} from "@mui/material";
import { PropTypes } from "./ProductSummarys.types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { UserContext } from "../../contexts/user";
import { UploadFile } from "../UploadFile";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import {
	ICertification,
	IEstimation,
	ILCAVersion,
	IProductLCA,
	IProductLCAVersionEmission,
} from "../../models";
import { useSnackbar } from "notistack";
import {
	estimation as estimationsActions,
	certificationProcessStep as certificationProcessStepActions,
	sale as saleActions,
	product as productActions,
	productLCAVersionEmission as productLCAVersionEmissionActions,
	lcaVersion as lcaVersionActions,
} from "../../controllers";
import theme from "../../Theme";
import LCACollapsible from "../LCACollapsible/LCACollapsible";
import { BasicModal } from "../BasicModal";
import { useTranslation } from "react-i18next";

export function ProductSummarys({
	certification,
	setCertification,
	refetchCertification,
	setShowConfirmation,
	setConfirmationData,
	currentStep,
	showSales,
	setShowHistoricalInfo,
	isHistorical,
	setIsLoadingAdminApproval,
}: PropTypes) {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [documentLoading, setDocumentLoading] = useState(-1);
	const [approveLoading, setApproveLoading] = useState(-1);
	const [rejectLoading, setRejectLoading] = useState(-1);
	const { t } = useTranslation();

	const [approveAll, setApproveAll] = useState(false);
	const [approveAllLoading, setApproveAllLoading] = useState(false);
	const [manualApproving, setManualApproving] = useState(false);
	const [selectedLCAVersion, setSelectedLCAVersion] = useState(-1);
	const onlyLargeScreen = useMediaQuery(theme.breakpoints.up(1300));
	const onlyMediumScreen = useMediaQuery(theme.breakpoints.down(1200));
	const onlySmallScreen = useMediaQuery(theme.breakpoints.down(600));

	const getLCAVersionProducts = (certification: ICertification) => {
		const products = Array<IProductLCAVersionEmission>();
		(certification.lca_versions ?? []).forEach((lcaVersion) => {
			lcaVersion.products.forEach((product) => {
				if (
					product &&
					product.product_lca_version_emissions &&
					product.product_lca_version_emissions.length > 0
				) {
					products.push(product.product_lca_version_emissions[0]);
				}
			});
		});

		return products;
	};
	const [initialEmissions, setInitialEmissions] = useState(
		getLCAVersionProducts(certification)
	);

	useEffect(() => {
		if (certification) {
			setInitialEmissions(getLCAVersionProducts(certification));
		}
	}, [certification]);

	const {
		isLoading: isLoadingDeleteEstimation,
		mutate: deleteEstimationMutate,
	} = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
			onSuccess: () => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const {
		isLoading: isLoadingUpdateCertificationStep,
		mutateAsync: updateCertificationStepMutateAsync,
	} = useMutation(
		"certification-detail",
		({
			status,
			rejectReason,
			manualApprove,
		}: {
			status: string;
			rejectReason?: string | undefined;
			manualApprove?: boolean;
		}) =>
			certificationProcessStepActions.update({
				id: certification!.certification_process_steps[currentStep].id,
				status: status,
				rejectReason: rejectReason,
				manualApprove: manualApprove,
			}),
		{
			retry: false,
			onSuccess: async (res) => {
				await refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { isLoading: isLoadingToggleEstimation, mutate: updateToggleMutate } =
		useMutation(
			"estimations-toggle",
			(estimation: IEstimation) =>
				estimationsActions.toggle({
					id: estimation.id,
					certificationProcessId: certification!.id,
					companyId: certification!.company.id,
					toggle: estimation.approved_date
						? "approve"
						: estimation.rejected_date
						? "reject"
						: "reset",
					reject_reason: estimation.reject_reason ?? null,
					overwrite_certification_date: showSales
						? undefined
						: estimation.overwrite_certification_date
						? estimation.overwrite_certification_date.split("T")[0]
						: undefined,
					pcf_amount:
						estimation.product_lca_version_emission?.emissions ?? undefined,
				}),
			{
				retry: false,
				onSuccess: async () => {
					if (!approveAllLoading) {
						await refetchCertification();
					}
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				},
			}
		);

	const { isLoading: isLoadingLCA, mutate: updateLCA } = useMutation(
		"lca-version-update",
		({
			lcaVersion,
			approvedDate,
			rejectedDate,
			rejectReason,
			status,
		}: {
			lcaVersion: ILCAVersion;
			approvedDate: string | null;
			rejectedDate: string | null;
			rejectReason: string | null;
			status: string;
		}) =>
			lcaVersionActions.update({
				lcaVersionId: lcaVersion.id!,
				lcaId: lcaVersion.lca_id!,
				certificationDate: lcaVersion.certification_date,
				approvedDate,
				rejectedDate,
				rejectReason,
				status,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				const auxCertification = { ...certification };
				let lcaVersionIndex = (auxCertification.lca_versions ?? []).findIndex(
					(l) => l.id === res.id
				);
				if (lcaVersionIndex > -1) {
					auxCertification.lca_versions![lcaVersionIndex].approved_date =
						res.approved_date;
					auxCertification.lca_versions![lcaVersionIndex].rejected_date =
						res.rejected_date;
					auxCertification.lca_versions![lcaVersionIndex].rejected_date =
						res.reject_reason;
				}
				setCertification(auxCertification);
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const {
		isLoading: isLoadingChecklistDocument,
		mutate: updateChecklistDocument,
	} = useMutation(
		"lca-version-document",
		({
			certification,
			checklistDocument,
			lcaVersion,
		}: {
			certification: ICertification;
			checklistDocument: File;
			lcaVersion: ILCAVersion;
		}) =>
			lcaVersionActions.updateChecklist({
				lcaVersionId: lcaVersion.id,
				lcaId: lcaVersion.lca_id!,
				checklistDocument: checklistDocument,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				const auxCertification = { ...certification };
				let index = (auxCertification.lca_versions ?? []).findIndex(
					(l) => l.id === res.id
				);
				if (index > -1) {
					if (!auxCertification.lca_versions) {
						auxCertification.lca_versions = [];
					}
					auxCertification.lca_versions[index].checklist_document =
						res.checklist_document;
				}
				setCertification(auxCertification);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const {
		isLoading: isLoadingUpdateProductLCAVersionEmission,
		mutate: mutateUpdateProductLCAVersionEmission,
	} = useMutation(
		"update-product-lca-version-emission",
		({
			id,
			productLcaVersionEmission,
			lcaVersionId,
			lcaId,
			companyId,
		}: {
			id: number;
			productLcaVersionEmission: number;
			lcaVersionId: number;
			lcaId: number;
			companyId?: number;
		}) =>
			productLCAVersionEmissionActions.update(
				id,
				productLcaVersionEmission,
				lcaVersionId,
				lcaId,
				companyId
			),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingUpdateSale } = useMutation(
		"historical-sales-update",
		({ id, amount }: { id: number; amount: number }) =>
			saleActions.update(id, amount),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingUpdateEstimation } = useMutation(
		"historical-estimation-update",
		({ id, amount }: { id: number; amount: number }) =>
			estimationsActions.update({
				id,
				companyId: certification.company.id,
				amount,
				certificationProcess: certification.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingDocuments } = useMutation(
		"products-lca-update",
		({
			productLCAId,
			productLCA,
			productId,
		}: {
			productLCAId: number;
			productLCA: IProductLCA;
			productId: number;
		}) =>
			productActions.updateLCA({
				id: productLCAId,
				companyId: !user?.is_superuser ? user?.company.id : undefined,
				productId: productId,
				emissions: productLCA.emissions!,
				certification_date: productLCA.certification_date,
				lca_provider: "",
				lca_document: undefined,
			}),
		{
			retry: false,
		}
	);

	const areAllEstimationsApproved = () => {
		return (certification?.lca_versions ?? []).every((lcaVersion) => {
			const filteredEstimations = certification?.estimations.filter(
				(estimation) =>
					estimation.product_lca_version_emission?.lca_version?.id ===
					lcaVersion.id
			);

			return filteredEstimations.every(
				(estimation) =>
					estimation.approved_date !== null &&
					estimation.approved_date !== undefined
			);
		});
	};

	const handleApproveAll = async (selectedLCAVersion: number) => {
		setApproveAllLoading(true);

		try {
			const auxCertification = { ...certification };
			const lcaVersions = (auxCertification?.lca_versions ?? []).filter(
				(lcaVersion) => lcaVersion.id === selectedLCAVersion
			);

			for (const lcaVersion of lcaVersions) {
				const filteredEstimations = auxCertification?.estimations
					.filter(
						(estimation) =>
							estimation.product_lca_version_emission?.lca_version?.id ===
							lcaVersion.id
					)
					.sort(
						(a, b) =>
							new Date(a.created_at!).getTime() -
							new Date(b.created_at!).getTime()
					);

				for (const estimation of filteredEstimations) {
					for (const emissions of initialEmissions.filter(
						(e) =>
							lcaVersion &&
							lcaVersion?.products.find(
								(p) => e.product && p.id === e.product.id
							) !== undefined
					)) {
						if (emissions && auxCertification) {
							let changed = false;

							for (const product of lcaVersion?.products ?? []) {
								if (
									product.product_lca_version_emission?.emissions !==
									emissions?.emissions
								) {
									changed = true;
								}
							}

							if (changed) {
								await new Promise((resolve, reject) => {
									mutateUpdateProductLCAVersionEmission(
										{
											id: emissions.id,
											productLcaVersionEmission: emissions.emissions!,
											lcaVersionId: lcaVersion!.id,
											lcaId: lcaVersion!.lca_id!,
										},
										{
											onSuccess: () => {
												resolve(true);
											},
											onError: (error: any) => {
												console.log(error);
												enqueueSnackbar(t("general.errorMessage"), {
													variant: "error",
												});
												reject(error);
											},
										}
									);
								});
							}
						}
					}

					await new Promise((resolve, reject) => {
						updateToggleMutate(
							{
								...estimation,
								approved_date: new Date().toISOString(),
								rejected_date: null,
								reject_reason: null,
								overwrite_certification_date:
									estimation.overwrite_certification_date,
							},
							{
								onSuccess: (res) => {
									estimation.approved_date = new Date().toISOString();
									resolve(true);
								},
								onError: (error: any) => {
									console.log(error);
									reject();
									enqueueSnackbar(t("general.errorMessage"), {
										variant: "error",
									});
								},
							}
						);
					});
				}
			}

			setCertification({ ...auxCertification });

			setTimeout(async () => {
				await refetchCertification();

				setApproveAll(false);
				enqueueSnackbar(t("productSummary.allProductsApproved"), {
					variant: "success",
				});
			}, 500);
		} catch (error) {
			console.error("Error approving all products:", error);
			enqueueSnackbar(t("general.errorMessageApprovingProducts"), {
				variant: "error",
			});
		} finally {
			setApproveAllLoading(false);
		}
	};

	return (
		<>
			{(certification?.lca_versions ?? []).map((lcaVersion, index) => {
				return (
					<Box
						sx={{
							marginTop: 1,
							marginBottom:
								index === (certification?.lca_versions ?? []).length - 1
									? 4
									: 2,
						}}
						key={lcaVersion.id}
					>
						<LCACollapsible
							title={
								<Box
									sx={{
										width: "100%",
										justifyContent: "space-between",
										display: "flex",
									}}
								>
									<Typography
										variant="h6"
										sx={{
											fontWeight: "bold",
											color: "#333",
											fontSize: 14,
										}}
									>
										{t("productSummary.lca") + ": " + lcaVersion.title ??
											lcaVersion.lca?.title ??
											t("productSummary.lcaToBeCertified") +
												" - " +
												lcaVersion?.lca_provider}
									</Typography>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											gap: 2,
											justifyContent: "flex-end",
											alignItems: "center",
										}}
									>
										<Typography
											variant="h6"
											sx={{
												fontSize: 14,
												color: "#777777",
											}}
										>
											{lcaVersion.products.filter(
												(p) =>
													!certification.is_add_products ||
													(certification.is_add_products &&
														certification.estimations.find(
															(estimation) => estimation.product.id === p.id
														)?.is_add_products)
											).length +
												" " +
												t("productSummary.products")}
										</Typography>
										{lcaVersion?.approved_date ? (
											<CheckCircleIcon
												sx={{
													border: "1px solid #25406D",
													borderRadius: "50%",
													color: "#25406D",
													marginLeft: 2,
													marginRight: 2,
													verticalAlign: "middle",
													fontSize: 18,
												}}
											/>
										) : lcaVersion?.rejected_date ? (
											<CancelIcon
												sx={{
													border: "1px solid #EB4B4B",
													borderRadius: "50%",
													color: "#EB4B4B",
													marginLeft: 2,
													marginRight: 2,
													verticalAlign: "middle",
													fontSize: 18,
												}}
											/>
										) : (
											<WatchLaterIcon
												sx={{
													border: "1px solid #f1c40f",
													borderRadius: "50%",
													color: "#f1c40f",
													marginLeft: 2,
													marginRight: 2,
													verticalAlign: "middle",
													fontSize: 18,
												}}
											/>
										)}
									</Box>
								</Box>
							}
							defaultExpanded={index === 0 && user?.is_superuser}
						>
							<Box
								key={lcaVersion.id}
								sx={{
									marginBottom: "12px",
									width: "100%",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
									}}
								>
									{lcaVersion?.reject_reason && (
										<Alert severity="error" sx={{ marginBottom: 2 }}>
											{lcaVersion?.reject_reason}
											{" -> "}
											<span
												style={{
													textDecoration: "underline",
													cursor: "pointer",
												}}
												onClick={() => navigate(`/lcas/${lcaVersion?.lca_id}`)}
											>
												{t("productSummary.goToLCA")}
											</span>
										</Alert>
									)}
								</Box>

								{user?.is_superuser && lcaVersion?.changes_in_version && (
									<Box
										sx={{
											marginTop: 2,
											marginBottom: 2,
											textAlign: "left",
											padding: 2,
											borderRadius: 2,
											border: "1px solid #D7EBFF",
										}}
									>
										<Typography
											variant="h6"
											sx={{
												fontWeight: "bold",
												color: "#333",
												fontSize: 12,
											}}
										>
											{t("lcaTable.changesInVersion")}
										</Typography>{" "}
										<Typography
											variant="body1"
											sx={{
												color: "#333",
												fontSize: 12,
											}}
										>
											{lcaVersion.changes_in_version}
										</Typography>
									</Box>
								)}

								{user?.is_superuser && (
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											flex: 1,
											width: "100%",
										}}
									>
										<Box
											sx={{
												display: "flex",
												gap: { xs: 2, sm: 2, md: 0 },
												overflow: "hidden",
												justifyContent: "space-between",
												width: "100%",
												flexDirection: onlyMediumScreen ? "column" : "row",
											}}
										>
											<Box
												sx={{
													backgroundColor: "#FAFDFF",
													padding: 2,
													display: "flex",
													flexDirection: "column",
													borderTop: "1px solid #D7EBFF",
													borderBottom: "1px solid #D7EBFF",
													overflow: "auto",
													width: "100%",
													marginBottom: 4,
													flex: 1,
												}}
											>
												<Box
													sx={{
														display: "flex",
														flexDirection: {
															xs: "column",
															md: "row",
														},
														justifyContent: "space-between",
														gap: 2,
														width: "100%",
														flexWrap: "nowrap",
													}}
												>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															flexShrink: 0,
															minWidth: "210px",
															gap: 2,
														}}
													>
														<Box
															sx={{
																display: "flex",
																flexDirection: "column",
																maxHeight: 77,
																alignItems: "flex-start",
															}}
														>
															<Typography
																variant="subtitle1"
																fontWeight="bold"
																sx={{ fontSize: 12 }}
															>
																{t("productSummary.lcaDocument")}
															</Typography>
															<Typography
																sx={{
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																	width: 150,
																	textAlign: "left",
																	fontSize: 14,
																	whiteSpace: "nowrap",
																	marginBottom: { xs: 1, md: 0 },
																}}
															>
																<a
																	href={
																		lcaVersion?.lca_document?.toString() ?? "#"
																	}
																	style={{ whiteSpace: "nowrap" }}
																	target="_blank"
																	rel="noreferrer"
																	download
																>
																	...
																	{lcaVersion?.lca_document?.toString()
																		? "..." +
																		  lcaVersion!
																				.lca_document!.toString()
																				.split("/")
																				[
																					lcaVersion!
																						.lca_document!.toString()
																						.split("/").length - 1
																				].slice(-15)
																		: "--"}
																</a>
															</Typography>
														</Box>
														{lcaVersion?.certification_date && (
															<Box
																sx={{
																	alignItems: "flex-start",
																	marginBottom: 0,
																	marginRight: 0,
																	display: "flex",
																	flexDirection: "column",
																	textAlign: "left",
																}}
															>
																<Typography
																	variant="subtitle1"
																	fontWeight="bold"
																	sx={{ fontSize: 12 }}
																>
																	{t("productSummary.lcaCompletionDate")}
																</Typography>
																<TextField
																	placeholder={t(
																		"productSummary.lcaCompletionDate"
																	)}
																	type="date"
																	variant="outlined"
																	InputProps={{
																		inputProps: {
																			max: `${
																				new Date().toISOString().split("T")[0]
																			}`,
																			min: "2021-01-01",
																			style: { minWidth: "150px" },
																		},
																	}}
																	sx={{
																		flex: 1,
																		width: "100%",
																		minWidth: "150px",
																	}}
																	disabled={
																		lcaVersion?.approved_date ||
																		lcaVersion?.rejected_date
																			? true
																			: false
																	}
																	value={
																		lcaVersion?.certification_date
																			? new Date(lcaVersion?.certification_date)
																					.toISOString()
																					.split("T")[0]
																			: ""
																	}
																	onChange={(event) => {
																		let certificationDate = event.target.value;
																		try {
																			const inputDate = new Date(
																				event.target.value
																			);
																			if (inputDate > new Date()) {
																				certificationDate = new Date()
																					.toISOString()
																					.split("T")[0];
																			}
																		} catch (error) {
																			console.log(error);
																		}

																		const auxCertification = {
																			...certification,
																		};
																		let index = (
																			auxCertification.lca_versions ?? []
																		).findIndex((l) => l.id === lcaVersion.id);
																		if (index > -1) {
																			if (!auxCertification.lca_versions) {
																				auxCertification.lca_versions = [];
																			}
																			auxCertification.lca_versions[
																				index
																			].certification_date = certificationDate;
																		}
																		setCertification(auxCertification);
																	}}
																/>
															</Box>
														)}
													</Box>

													{user?.is_superuser && !showSales && (
														<Box
															sx={{
																display: "flex",
																flexDirection: "column",
																flexGrow: 1,
																minWidth: "150px",
																textAlign: "left",
															}}
														>
															<Typography
																variant="subtitle1"
																fontWeight="bold"
																sx={{ fontSize: 12, marginBottom: "8px" }}
															>
																{t("productSummary.checklistDocument")}
															</Typography>
															<UploadFile
																padding={1}
																accept=".pdf,.csv,.xlsx,.xls,.doc,.docx,.numbers,.txt"
																value={lcaVersion?.checklist_document ?? null}
																onFileChange={(file) => {
																	if (file) {
																		setDocumentLoading(-1);
																		updateChecklistDocument({
																			certification: { ...certification },
																			checklistDocument: file,
																			lcaVersion: lcaVersion,
																		});
																	} else {
																		enqueueSnackbar(
																			t("productSummary.fileError"),
																			{
																				variant: "error",
																			}
																		);
																	}
																}}
																loading={
																	isLoadingChecklistDocument &&
																	documentLoading === -1
																}
																smallGrid
															/>
														</Box>
													)}

													{user?.is_superuser && (
														<Box
															sx={{
																display: "flex",
																flexDirection: { xs: "row", md: "column" },
																gap: 2,
																marginTop: { xs: 2, md: 0 },
																flexShrink: 0,
																marginLeft: 2,
															}}
														>
															<Button
																variant="contained"
																size="small"
																disabled={
																	(!showSales &&
																		!lcaVersion?.checklist_document) ||
																	isLoadingLCA ||
																	(lcaVersion?.approved_date ? true : false)
																}
																sx={{ minHeight: "29px" }}
																onClick={() => {
																	setShowConfirmation(true);
																	setConfirmationData({
																		title: t(
																			"productSummary.approvalLCAConfirmationTitle"
																		),
																		description: t(
																			"productSummary.approvalLCAConfirmationDescription"
																		),
																		textfield: null,
																		callback: async () => {
																			setApproveLoading(-1);
																			setShowConfirmation(false);

																			updateLCA({
																				lcaVersion: { ...lcaVersion },
																				approvedDate: new Date().toISOString(),
																				rejectedDate: null,
																				rejectReason: null,
																				status: "ACTIVE",
																			});
																		},
																	});
																}}
															>
																{(isLoadingLCA || isLoadingDocuments) &&
																approveLoading === -1 ? (
																	<CircularProgress
																		size={12}
																		sx={{ color: "white" }}
																	/>
																) : (
																	t("productSummary.approveButton")
																)}
															</Button>
															<Button
																variant="outlined"
																size="small"
																disabled={
																	(!showSales &&
																		!lcaVersion?.checklist_document) ||
																	isLoadingLCA ||
																	(lcaVersion?.rejected_date ? true : false)
																}
																sx={{
																	minHeight: "29px",
																	"&.Mui-disabled": {
																		color: "#25406D",
																		background: "white",
																		opacity: 0.5,
																	},
																}}
																onClick={() => {
																	setShowConfirmation(true);
																	setConfirmationData({
																		title: t("productSummary.approveButton"),
																		description: t(
																			"productSummary.rejectionLCAConfirmationDescription"
																		),
																		textfield: t(
																			"productSummary.rejectReasonLabel"
																		),
																		callback: (rejectReason) => {
																			setRejectLoading(-1);
																			updateLCA({
																				lcaVersion: { ...lcaVersion },
																				approvedDate: null,
																				rejectedDate: new Date().toISOString(),
																				rejectReason: rejectReason ?? null,
																				status: "REJECTED",
																			});
																			setShowConfirmation(false);
																		},
																	});
																}}
															>
																{isLoadingLCA && rejectLoading === -1 ? (
																	<CircularProgress
																		size={12}
																		sx={{ color: "white" }}
																	/>
																) : (
																	t("productSummary.rejectButton")
																)}
															</Button>
														</Box>
													)}
												</Box>
											</Box>
										</Box>
									</Box>
								)}

								<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>
													<Typography
														variant="subtitle1"
														fontWeight="bold"
														sx={{ fontSize: 12 }}
													>
														{t("productSummary.product")}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography
														variant="subtitle1"
														fontWeight="bold"
														sx={{ fontSize: 12, textAlign: "center" }}
													>
														{t("productSummary.productCarbonFootprintValue")}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography
														variant="subtitle1"
														fontWeight="bold"
														sx={{ fontSize: 12, textAlign: "center" }}
													>
														{t("productSummary.estimatedTotalSales12months")}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography
														variant="subtitle1"
														fontWeight="bold"
														sx={{ fontSize: 12, textAlign: "center" }}
													>
														{t("productSummary.offsetsNeeded")}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography
														variant="subtitle1"
														fontWeight="bold"
														sx={{ fontSize: 12, textAlign: "center" }}
													>
														{t("productSummary.costToOffset")}
													</Typography>
												</TableCell>
												{user?.is_superuser && (
													<TableCell sx={{ textAlign: "center" }}>
														<Typography
															variant="subtitle1"
															fontWeight="bold"
															sx={{ fontSize: 12, textAlign: "center" }}
														>
															{t("productSummary.actions")}
														</Typography>
														<Button
															variant="text"
															disabled={
																isLoadingUpdateProductLCAVersionEmission ||
																lcaVersion?.approved_date === undefined ||
																lcaVersion?.approved_date === null ||
																isLoadingToggleEstimation ||
																areAllEstimationsApproved()
															}
															onClick={() => {
																setApproveAll(true);
																setSelectedLCAVersion(lcaVersion.id);
															}}
															sx={{
																textAlign: "center",
																":disabled": {
																	backgroundColor: "#fff",
																	opacity: 0.4,
																	color: "#34918E",
																},
																"&:not(.MuiButton-outlined)": {
																	":disabled": {
																		backgroundColor: "#fff",
																		opacity: 0.4,
																		color: "#34918E",
																	},
																},
															}}
														>
															{t("productSummary.approveAll")}
														</Button>
													</TableCell>
												)}
											</TableRow>
										</TableHead>
										<TableBody>
											{certification?.estimations
												.filter(
													(estimation) =>
														estimation.product_lca_version_emission?.lca_version
															?.id === lcaVersion.id &&
														(!certification.is_add_products ||
															(certification.is_add_products &&
																estimation.is_add_products))
												)
												.sort(
													(a, b) =>
														new Date(a.created_at!).getTime() -
														new Date(b.created_at!).getTime()
												)
												.map((estimation, index) => (
													<TableRow key={index}>
														<TableCell>
															<Box sx={{ display: "flex", gap: 2 }}>
																<Box>
																	{estimation.approved_date ? (
																		<CheckCircleIcon
																			sx={{
																				border: "1px solid #25406D",
																				borderRadius: "50%",
																				color: "#25406D",
																			}}
																		/>
																	) : estimation.rejected_date ? (
																		<CancelIcon
																			sx={{
																				border: "1px solid #EB4B4B",
																				borderRadius: "50%",
																				color: "#EB4B4B",
																			}}
																		/>
																	) : (
																		<WatchLaterIcon
																			sx={{
																				border: "1px solid #f1c40f",
																				borderRadius: "50%",
																				color: "#f1c40f",
																			}}
																		/>
																	)}
																</Box>
																{estimation.product.asin
																	? estimation.product.name +
																	  " - " +
																	  estimation.product.asin
																	: estimation.product.upc
																	? estimation.product.name +
																	  " - " +
																	  estimation.product.upc
																	: estimation.product.name}
															</Box>
														</TableCell>
														<TableCell>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 8,
																minimumFractionDigits: 0,
															}).format(
																estimation.product_lca_version_emission
																	?.emissions ?? 0
															)}{" "}
															{t("productSummary.kgCO2e")}
														</TableCell>
														<TableCell>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 0,
																minimumFractionDigits: 0,
															}).format(estimation.amount ?? 0)}
														</TableCell>
														<TableCell>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 2,
																minimumFractionDigits: 0,
															}).format(
																process.env.REACT_APP_ENV === "stage" ||
																	process.env.REACT_APP_DO_NOT_CEIL_BALANCES ===
																		"TRUE"
																	? ((estimation.amount ?? 0) *
																			(estimation.product_lca_version_emission
																				?.emissions ?? 0)) /
																			1000
																	: Math.ceil(
																			((estimation.amount ?? 0) *
																				(estimation.product_lca_version_emission
																					?.emissions ?? 0)) /
																				1000
																	  )
															)}
														</TableCell>
														<TableCell>
															{Intl.NumberFormat("en", {
																style: "currency",
																currency: "usd",
															}).format(
																process.env.REACT_APP_ENV === "stage" ||
																	process.env.REACT_APP_DO_NOT_CEIL_BALANCES ===
																		"TRUE"
																	? (((estimation.amount ?? 0) *
																			(estimation.product_lca_version_emission
																				?.emissions ?? 0)) /
																			1000) *
																			12.5
																	: Math.ceil(
																			((estimation.amount ?? 0) *
																				(estimation.product_lca_version_emission
																					?.emissions ?? 0)) /
																				1000
																	  ) * 12.5
															)}
														</TableCell>
														{user?.is_superuser && (
															<TableCell>
																<Box
																	sx={{
																		display: "flex",
																		flexDirection: onlySmallScreen
																			? "column"
																			: "row",
																		alignItems: "center",
																	}}
																>
																	{user?.is_superuser && (
																		<Box
																			sx={{
																				display: "flex",
																				flexDirection: isHistorical
																					? onlyLargeScreen
																						? "column"
																						: "row"
																					: onlyMediumScreen
																					? "row"
																					: "column",
																				gap: 2,
																				overflow: "hidden",
																				marginTop: onlySmallScreen ? 2 : 0,
																				marginLeft: 2,
																			}}
																		>
																			<Button
																				variant="contained"
																				size="small"
																				disabled={
																					isLoadingUpdateProductLCAVersionEmission ||
																					lcaVersion?.approved_date ===
																						undefined ||
																					lcaVersion?.approved_date === null ||
																					isLoadingToggleEstimation ||
																					(estimation.approved_date
																						? true
																						: false)
																				}
																				sx={{ minHeight: "29px" }}
																				onClick={async () => {
																					setApproveLoading(index);
																					setShowConfirmation(false);

																					for (const emissions of initialEmissions.filter(
																						(e) =>
																							lcaVersion &&
																							lcaVersion?.products.find(
																								(p) =>
																									e.product &&
																									p.id === e.product.id
																							) !== undefined
																					)) {
																						if (emissions && certification) {
																							let changed = false;

																							for (const product of lcaVersion?.products ??
																								[]) {
																								if (
																									product
																										.product_lca_version_emission
																										?.emissions !==
																									emissions?.emissions
																								) {
																									changed = true;
																								}
																							}
																							if (changed) {
																								await new Promise(
																									(resolve, reject) => {
																										mutateUpdateProductLCAVersionEmission(
																											{
																												id: emissions.id,
																												productLcaVersionEmission:
																													emissions.emissions!,
																												lcaVersionId:
																													lcaVersion!.id,
																												lcaId:
																													lcaVersion!.lca_id!,
																											},
																											{
																												onSuccess: () => {
																													resolve(true);
																												},
																												onError: (
																													error: any
																												) => {
																													console.log(error);
																													enqueueSnackbar(
																														"Something went wrong.",
																														{ variant: "error" }
																													);
																													reject(error);
																												},
																											}
																										);
																									}
																								);
																							}
																						}
																					}

																					updateToggleMutate({
																						...estimation,
																						approved_date:
																							new Date().toISOString(),
																						rejected_date: null,
																						reject_reason: null,
																						overwrite_certification_date:
																							estimation.overwrite_certification_date,
																					});
																				}}
																			>
																				{(isLoadingToggleEstimation ||
																					isLoadingUpdateEstimation ||
																					isLoadingUpdateSale ||
																					isLoadingUpdateProductLCAVersionEmission ||
																					isLoadingDocuments) &&
																				approveLoading === index ? (
																					<CircularProgress
																						size={12}
																						sx={{ color: "white" }}
																					/>
																				) : (
																					t("productSummary.approveButton")
																				)}
																			</Button>

																			{user?.is_superuser && (
																				<>
																					<Button
																						variant="outlined"
																						size="small"
																						sx={{
																							minHeight: "29px",
																							"&.Mui-disabled": {
																								color: "#25406D",
																								background: "white",
																								opacity: 0.5,
																							},
																						}}
																						disabled={
																							lcaVersion?.approved_date ===
																								undefined ||
																							lcaVersion?.approved_date === null
																						}
																						onClick={() => {
																							setShowConfirmation(true);

																							setConfirmationData({
																								title: t(
																									"productSummary.removeConfirmationTitle"
																								),
																								description: t(
																									"productSummary.removeConfirmationDescription"
																								),
																								textfield: "",
																								callback: () => {
																									deleteEstimationMutate(
																										estimation
																									);
																									setShowConfirmation(false);
																								},
																							});
																						}}
																					>
																						{isLoadingDeleteEstimation ? (
																							<CircularProgress
																								size={12}
																								sx={{ color: "white" }}
																							/>
																						) : (
																							t("productSummary.removeButton")
																						)}
																					</Button>
																				</>
																			)}
																		</Box>
																	)}
																</Box>
															</TableCell>
														)}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</LCACollapsible>
					</Box>
				);
			})}
			{user &&
				user.is_superuser &&
				!certification.certification_process_steps[currentStep]
					.reject_reason && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginBottom: 4,
						}}
					>
						{certification?.certification_process_steps[currentStep].step
							.step_type === "CERTIFICATION_SUMMARY" &&
							user?.is_superuser &&
							(!certification.company.first_time_fee_paid ||
								(certification.company.first_time_fee_paid &&
									new Date(
										certification.company.first_time_fee_paid
									).getTime() <
										new Date().getTime() - 365 * 24 * 60 * 60 * 1000)) &&
							!certification?.requested_approval &&
							Math.ceil(
								process.env.REACT_APP_ENV === "stage" ||
									process.env.REACT_APP_DO_NOT_CEIL_BALANCES === "TRUE"
									? (certification?.estimations
											.filter(
												(estimation) =>
													!certification.is_add_products ||
													(certification.is_add_products &&
														estimation.is_add_products)
											)
											.reduce((prev, current) => {
												return (
													prev +
													(current.product_lca_version_emission?.emissions ??
														0) *
														(!isNaN(current.amount ?? 0) && current.amount
															? current.amount
															: 0)
												);
											}, 0) ?? 0) * 0.001
									: certification?.estimations
											.filter(
												(estimation) =>
													!certification.is_add_products ||
													(certification.is_add_products &&
														estimation.is_add_products)
											)
											.reduce((prev, current) => {
												return (
													prev +
													Math.ceil(
														(current.product_lca_version_emission?.emissions ??
															0) *
															(!isNaN(current.amount ?? 0) && current.amount
																? current.amount
																: 0) *
															0.001
													)
												);
											}, 0) ?? 0
							) *
								12.5 +
								certification?.estimations.length * 250 +
								1000 <
								5000 && (
								<Typography sx={{ marginRight: 2 }}>
									<small
										style={{
											color: "#EB4B4B",
											fontWeight: "bold",
										}}
									>
										{t("productSummary.userNotAgree")}
									</small>
								</Typography>
							)}
						{!showSales && (
							<Button
								variant="contained"
								sx={{ minHeight: 34 }}
								disabled={
									isLoadingUpdateCertificationStep ||
									(certification.lca_versions ?? []).find(
										(l) =>
											l.approved_date === undefined || l.approved_date === null
									) !== undefined ||
									certification.estimations.length === 0 ||
									certification.estimations.find(
										(estimation) => !estimation.approved_date
									)
										? true
										: false
								}
								onClick={() => {
									setShowConfirmation(true);
									setManualApproving(false);
									setConfirmationData({
										title: t("productSummary.approvalConfirmationTitle"),
										description: t(
											"productSummary.approvalConfirmationDescription"
										),
										textfield: null,
										callback: async () => {
											if (setIsLoadingAdminApproval) {
												setIsLoadingAdminApproval(true);
											}
											await updateCertificationStepMutateAsync({
												status: "COMPLETED",
											});
											if (setIsLoadingAdminApproval) {
												setIsLoadingAdminApproval(true);
											}
											setShowConfirmation(false);
										},
									});
								}}
							>
								{(isLoadingUpdateCertificationStep ||
									isLoadingUpdateSale ||
									isLoadingUpdateEstimation) &&
								!manualApproving ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("productSummary.approveButton")
								)}
							</Button>
						)}
						<Button
							variant="outlined"
							sx={{
								minHeight: 34,
								minWidth: 141,
								marginLeft: 2,
								"&.Mui-disabled": {
									color: "#25406D",
									background: "white",
									opacity: 0.5,
								},
							}}
							disabled={
								isLoadingUpdateCertificationStep ||
								(certification.lca_versions ?? []).find(
									(l) =>
										l.approved_date === undefined || l.approved_date === null
								) !== undefined ||
								certification.estimations.length === 0 ||
								certification.estimations.find(
									(estimation) => !estimation.approved_date
								)
									? true
									: false
							}
							onClick={() => {
								setShowConfirmation(true);
								setManualApproving(true);
								setConfirmationData({
									title: t("productSummary.manualApprovalConfirmationTitle"),
									description: t(
										"productSummary.manualApprovalConfirmationDescription"
									),
									textfield: null,
									callback: async () => {
										if (setIsLoadingAdminApproval) {
											setIsLoadingAdminApproval(true);
										}
										await updateCertificationStepMutateAsync({
											status: "COMPLETED",
											rejectReason: undefined,
											manualApprove: true,
										});
										setShowConfirmation(false);
										if (setIsLoadingAdminApproval) {
											setIsLoadingAdminApproval(false);
										}

										if (!user.is_superuser && setShowHistoricalInfo) {
											setShowHistoricalInfo(true);
										}
									},
								});
							}}
						>
							{isLoadingUpdateCertificationStep && manualApproving ? (
								<CircularProgress size={12} sx={{ color: "#25406D" }} />
							) : (
								t("productSummary.manualApproveButton")
							)}
						</Button>
					</Box>
				)}
			<BasicModal
				width={800}
				showModal={approveAll}
				handleClose={() => {
					setApproveAll(false);
				}}
				children={
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							backgroundColor: "#ffffff",
							padding: 2,
						}}
					>
						<Typography variant="h6" gutterBottom>
							{t("productSummary.approvalProductConfirmationTitle")}
						</Typography>
						<Typography variant="body1" gutterBottom>
							{t("productSummary.approvalProductConfirmationDescription")}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => setApproveAll(false)}
								sx={{ marginRight: 1 }}
							>
								{t("productSummary.cancel")}
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									handleApproveAll(selectedLCAVersion);
								}}
							>
								{approveAllLoading ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("productSummary.approveAll")
								)}
							</Button>
						</Box>
					</Box>
				}
			/>
		</>
	);
}
