import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
	order: ["navigator", "querystring", "cookie", "localStorage", "htmlTag"],
	lookupQuerystring: "lng",
	lookupCookie: "i18next",
	lookupLocalStorage: "i18nextLng",
	caches: ["localStorage", "cookie"],
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: options,
		compatibilityJSON: "v3",
		fallbackLng: "en",
		supportedLngs: ["en", "es", "zh"],
		resources: {
			en: {
				translations: require("./locales/en/translations.json"),
			},
			es: {
				translations: require("./locales/es/translations.json"),
			},
			zh: {
				translations: require("./locales/zh/translations.json"),
			},
		},
		ns: ["translations"],
		defaultNS: "translations",
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
		lng: navigator.language,
	});
i18n.languages = ["en", "es", "zh"];

export default i18n;
