import { useContext } from "react";
import { Box } from "@mui/material";
import { AppContent, Auth, OverlayLoading } from "../../components";
import { PropTypes } from "./MainLayout.types";
import { UserContext } from "../../contexts/user";

export function MainLayout(props: PropTypes) {
	const { user, isLoading } = useContext(UserContext);

	return (
		<Box sx={{ display: "flex" }}>
			{isLoading ? <OverlayLoading /> : user ? <AppContent /> : <Auth />}
		</Box>
	);
}
