import React, { useEffect, useState, useContext } from "react";
import { loadSpace } from "@usersnap/browser";
import { UserSnapContextType, PropTypes } from "./UserSnapContext.types";

const USERSNAP_GLOBAL_API_KEY = "c6f18dd9-cb65-4d4e-a794-9ce16b39a6da";

export const UserSnapContext = React.createContext<UserSnapContextType>(null);

export const UserSnapProvider = ({ initParams, children }: PropTypes) => {
	const [userSnapApi, setUserSnapApi] = useState<{} | null>(null);

	useEffect(() => {
		loadSpace(USERSNAP_GLOBAL_API_KEY).then((api) => {
			api.init(initParams);
			setUserSnapApi(api);
		});
	}, [initParams]);

	return (
		<UserSnapContext.Provider value={userSnapApi}>
			{children}
		</UserSnapContext.Provider>
	);
};

export function useUserSnapApi() {
	return useContext(UserSnapContext);
}
