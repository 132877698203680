export enum IGetCertifiedStep {
    CHOOSE_ACTION = "CHOOSE_ACTION",
    ADD_LCAS = "ADD_LCAS",
    UPLOAD_LCAS = "UPLOAD_LCAS",
    ADD_PRODUCTS = "ADD_PRODUCTS",
    LOADING_PRODUCTS = "LOADING_PRODUCTS",
    LCA_LIST = "LCA_LIST",
    CONFIRM_OFFSETS = "CONFIRM_OFFSETS",
    SIGN_AGREEMENT = "SIGN_AGREEMENT",
    PURCHASE_OFFSETS = "PURCHASE_OFFSETS",
    COMPLETED = "COMPLETED"
}