import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { user as userActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { IUser } from "../../models";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 16,
		marginBottom: 4,
		fontWeight: 500,
		color: "#686868",
	},
});

export function UserDetail() {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const navigate = useNavigate();
	const { id } = useParams();
	const { t } = useTranslation();
	const [userDetail, setUserDetail] = useState<IUser | null>(null);
	const [password, setPassword] = useState<string | undefined>(undefined);
	const { user } = useContext(UserContext);

	const { refetch } = useQuery(
		"users",
		() =>
			userActions.getOne(
				parseInt(id!),
				!user?.is_superuser ? user?.company.id : undefined
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setUserDetail(res);
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
				}
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"users",
		() =>
			id
				? userActions.update({
						id: parseInt(id),
						username: userDetail?.email || "",
						companyId: !user?.is_superuser ? user?.company.id : undefined,
						email: user?.email || "",
						password: password,
						first_name: userDetail?.first_name,
						last_name: userDetail?.last_name,
				  })
				: userActions.create({
						username: userDetail?.email || "",
						email: userDetail?.email || "",
						companyId: !user?.is_superuser ? user?.company.id : undefined,
						password: password,
						first_name: userDetail?.first_name,
						last_name: userDetail?.last_name,
				  }),

		{
			retry: false,
			onSuccess: (res) => {
				navigate("/users");
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (id) {
			refetch();
		}
	}, [id, refetch]);

	return (
		<Box>
			<Heading
				title={id ? t("userDetail.editUser") : t("userDetail.createUser")}
				showSearch={false}
			/>
			<FormControl sx={{ width: "100%" }}>
				<span className={classes.inputLabel}>{t("userDetail.email")}</span>{" "}
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="email"
					value={userDetail?.email}
					disabled={id ? true : false}
					placeholder={t("userDetail.email")}
					onChange={(e) => {
						setUserDetail(
							(prevState) =>
								({
									...prevState,
									email: e.target.value,
								} as IUser)
						);
					}}
				/>
				<span className={classes.inputLabel}>{t("userDetail.name")}</span>
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="name"
					value={userDetail?.first_name}
					placeholder={t("userDetail.name")}
					onChange={(e) => {
						setUserDetail(
							(prevState) =>
								({
									...prevState,
									first_name: e.target.value,
								} as IUser)
						);
					}}
				/>
				<span className={classes.inputLabel}>{t("userDetail.lastName")}</span>
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="last_name"
					value={userDetail?.last_name}
					placeholder={t("userDetail.lastName")}
					onChange={(e) => {
						setUserDetail(
							(prevState) =>
								({
									...prevState,
									last_name: e.target.value,
								} as IUser)
						);
					}}
				/>
				<span className={classes.inputLabel}>{t("userDetail.password")}</span>
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="password"
					type="password"
					value={password}
					disabled={id ? true : false}
					placeholder={t("userDetail.password")}
					onChange={(e) => {
						setPassword(e.target.value);
					}}
				/>
				<Button
					variant="contained"
					onClick={() => {
						mutate();
					}}
					sx={{ marginTop: 2, maxWidth: 300, alignSelf: "end", minHeight: 34 }}
				>
					{isLoading ? (
						<CircularProgress size={12} sx={{ color: "white" }} />
					) : id ? (
						t("userDetail.save")
					) : (
						t("userDetail.create")
					)}
				</Button>
			</FormControl>
		</Box>
	);
}
