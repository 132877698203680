import React from "react";
import { PropTypes } from "./CertificationInfo.types";
import { Box, Chip, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RiShieldStarFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

export default function CertificationInfo({
	certification,
	completed,
}: PropTypes) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const pendingStep = certification.certification_process_steps?.find(
		(step) => step.status === "PENDING"
	);

	const getLabelAndColor = (pendingStep: String) => {
		switch (pendingStep) {
			case "CERTIFICATION_SUMMARY":
				return {
					label: t("certificationInfo.inReview"),
					color: "#EE9D52",
					description: t("certificationInfo.inReviewDescription"),
				};

			case "CHOOSE_PRODUCTS":
				return {
					label: t("certificationInfo.actionRequiredProducts"),
					color: "#8697F2",
					description: t("certificationInfo.actionRequiredProductsDescription"),
				};

			case "PURCHASE_OFFSETS":
				return {
					label: t("certificationInfo.actionRequiredPurchaseOffsets"),
					color: "#8697F2",
					description: t(
						"certificationInfo.actionRequiredPurchaseOffsetsDescription"
					),
				};
			case "REDIRECTION":
				return {
					label: t("certificationInfo.actionRequiredSignContract"),
					color: "#8697F2",
					description: t(
						"certificationInfo.actionRequiredSignContractDescription"
					),
				};
			case "COMPLETED":
				return {
					label: t("certificationInfo.completed"),
					color: "#00A889",
				};
			default:
				return {
					label: t("certificationInfo.inProgress"),
					color: "default",
					description: "",
				};
		}
	};

	return (
		<Box
			sx={{
				border: "1px solid #E3E3E3",
				borderRadius: 2,
				padding: 2,
				cursor: "pointer",
			}}
			onClick={() => {
				if (
					certification?.certification?.certification_type ===
					"CLIMECO_INTERNAL_CERTIFICATION"
				) {
					navigate("/climeco-certificationss/" + certification.id);
				} else {
					navigate("/certifications/" + certification.id);
				}
			}}
		>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					alignItems: "center",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						alignItems: !completed ? "center" : "flex-start",
						flexDirection: !completed ? "row" : "column",
					}}
				>
					{completed && (
						<RiShieldStarFill
							style={{
								fontSize: 20,
								color: "#00A889",
								marginBottom: 2,
							}}
						/>
					)}
					<Typography variant="body1" sx={{ fontWeight: "bold" }}>
						{certification?.certification?.certification_type ===
						"CLIMECO_INTERNAL_CERTIFICATION" ? (
							completed ? (
								<>
									ClimeCo
									<br />
									{t("certificationInfo.certification")}
								</>
							) : (
								"ClimeCo Certification"
							)
						) : completed ? (
							<>
								Amazon
								<br />
								{t("certificationInfo.certification")}
							</>
						) : (
							"Amazon Certification"
						)}
					</Typography>

					{!completed && (
						<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
							<Chip
								label={
									getLabelAndColor(pendingStep?.step?.step_type ?? "").label
								}
								sx={{
									backgroundColor: getLabelAndColor(
										pendingStep?.step?.step_type ?? ""
									).color,
									color: "#ffffff",
									paddingLeft: 1,
									paddingRight: 1,
									height: "26px",
									fontSize: "12px",
								}}
							/>
						</Box>
					)}
				</Box>
				{!completed &&
					pendingStep?.step.step_type !== "CERTIFICATION_SUMMARY" && (
						<Button
							sx={{ fontWeight: "bold" }}
							onClick={() => {
								if (
									certification?.certification?.certification_type ===
									"CLIMECO_INTERNAL_CERTIFICATION"
								) {
									navigate("/climeco-certificationss/" + certification.id);
								} else {
									navigate("/certifications/" + certification.id);
								}
							}}
						>
							{t("certificationInfo.continue")}
						</Button>
					)}
			</Box>
			{completed ? (
				<Box
					sx={{
						display: "flex",
						paddingBottom: completed ? 0 : 2,
						color: "#8A8A8A",
						gap: 1,
						alignItems: "center",
					}}
				>
					{certification.lca_versions &&
						certification.lca_versions.length > 0 && (
							<Typography variant="body1" sx={{ fontSize: 14 }}>
								{certification.lca_versions.length +
									t("certificationInfo.lcas")}
							</Typography>
						)}
					<span style={{ fontSize: 10 }}>|</span>
					<Typography variant="body1" sx={{ fontSize: 14 }}>
						{certification.estimations && certification.estimations.length > 0
							? certification.estimations.length +
							  t("certificationInfo.products")
							: ""}
					</Typography>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						borderBottom: "1px solid #C7D8FA",
						paddingBottom: 2,
					}}
				>
					<Typography
						variant="body1"
						sx={{ fontWeight: "bold", color: "#003899", fontSize: 14 }}
					>
						{t("certificationInfo.forLca")}:{" "}
						{certification.lca_versions?.map((lca, index) => {
							return `${lca.title}${
								index < (certification.lca_versions?.length || 0) - 1
									? ", "
									: ""
							}`;
						})}
					</Typography>
					<Typography variant="body1" sx={{ color: "#8A8A8A", fontSize: 14 }}>
						{certification.estimations && certification.estimations.length > 0
							? certification.estimations.length +
							  t("certificationInfo.products")
							: ""}
					</Typography>
				</Box>
			)}
			{!completed && (
				<Typography variant="body1" sx={{ marginTop: 2, fontSize: 14 }}>
					{getLabelAndColor(pendingStep?.step?.step_type ?? "").description}
				</Typography>
			)}
			{completed && (
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Button
						sx={{
							fontWeight: "bold",
							paddingRight: 0,
							paddingBottom: 0,
							marginRight: "-8px",
							marginBottom: "-8px",
						}}
						onClick={() => {
							if (
								certification?.certification?.certification_type ===
								"CLIMECO_INTERNAL_CERTIFICATION"
							) {
								navigate("/climeco-certificationss/" + certification.id);
							} else {
								navigate("/certifications/" + certification.id);
							}
						}}
					>
						{t("certificationInfo.check")}
					</Button>
				</Box>
			)}
		</Box>
	);
}
