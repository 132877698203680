import InputBase from "@mui/material/InputBase";
import { HiOutlineSearch as SearchIcon } from "react-icons/hi";
import { PropTypes } from "./Search.types";
import { styled, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const SearchInput = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: 20,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		marginLeft: 0,
		width: "auto",
	},
	border: "1px solid #ececec",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		fontSize: 14,
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",

		[theme.breakpoints.up("sm")]: {
			width: "12ch",
			"&:focus": {
				width: "20ch",
			},
		},
	},
}));

export function Search(props: PropTypes) {
	const { t } = useTranslation();
	return (
		<SearchInput>
			<SearchIconWrapper>
				<SearchIcon />
			</SearchIconWrapper>
			<StyledInputBase
				onChange={(e) => {
					props.setSearch(e.target.value);
					props.setPaginationModel &&
						props.setPaginationModel({
							page: 0,
							pageSize: 10,
						});
				}}
				placeholder={t("general.search")}
				inputProps={{ "aria-label": "search" }}
			/>
		</SearchInput>
	);
}
