import { PropTypes } from "./UserActionBar.types";
import { Box, Button } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BasicModal } from "../BasicModal";
import { DeleteConfirmation } from "../DeleteConfirmation";
import { IUser } from "../../models";
import { user as userActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";

export function UserActionBar({ selectedRows, refetch }: PropTypes) {
	const navigate = useNavigate();
	const [showDelete, setShowDelete] = useState(false);
	const { user } = useContext(UserContext);
	const { t } = useTranslation();

	const handleDeleteModalClose = () => {
		setShowDelete(false);
	};

	const deleteItem = () => {
		selectedRows.forEach((selectedRow) => {
			userActions.delete(
				selectedRow as IUser,
				!user?.is_superuser ? user?.company.id : undefined
			);
		});
		handleDeleteModalClose();
		refetch();
	};

	const getSelectionRowsTitle = () => {
		let title = "";
		selectedRows.forEach((selectedRow) => {
			title =
				title.length > 0
					? title + ", " + (selectedRow as IUser).username
					: (selectedRow as IUser).username;
		});

		return title;
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					flex: 1,
				}}
			>
				{selectedRows && selectedRows.length && user!.is_staff ? (
					<>
						<Button
							variant="outlined"
							sx={{ marginRight: 2, maxWidth: "200px" }}
							onClick={() => {
								setShowDelete(true);
							}}
						>
							{t("userActionBar.delete")}
						</Button>
					</>
				) : (
					<></>
				)}
				<Button
					variant="contained"
					sx={{ marginRight: 2 }}
					onClick={() => {
						navigate("/user");
					}}
				>
					{t("userActionBar.create")}
				</Button>
			</Box>
			<BasicModal
				width={600}
				showModal={showDelete}
				handleClose={handleDeleteModalClose}
				children={
					<DeleteConfirmation
						title={getSelectionRowsTitle()}
						handleDeleteModalClose={handleDeleteModalClose}
						callback={deleteItem}
					/>
				}
				hideClose
			/>
		</>
	);
}
