import { useContext, useEffect, useState } from "react";
import {
	Alert,
	Box,
	Typography,
	TextField,
	Divider,
	IconButton,
	//Button,
	Tooltip,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import {
	sale as saleActions,
	strings as stringActions,
} from "../../controllers";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
} from "@mui/x-data-grid";
import { IBalance, IEntity, IEstimation, IPayment, ISale } from "../../models";
import { UserContext } from "../../contexts/user";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { SaleActionBar } from "../SaleActionBar";
import { Filters } from "../../routes/Routes.types";
import { BasicModal, PurchaseAgree } from "../../components";
import { PurchaseOffsets } from "../PurchaseOffsets";
import { NumericFormat } from "react-number-format";
//import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
	createStyles({
		customHeader: {
			paddingLeft: "28px!important",
		},
	})
);

let loadingOffsetsInterval: NodeJS.Timer | null = null;

export function SalesReport() {
	const navigate = useNavigate();
	const classes = useStyles();
	const { user } = useContext(UserContext);
	const { t } = useTranslation();
	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser ? { companies: [] } : {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: "updated_at",
			sort: "desc",
		})
	);
	const [sales, setSales] = useState<Array<IEstimation>>([]);
	const [rowCount, setRowCount] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);
	const [balance, setBalance] = useState<IBalance | null>(null);
	const [payments, setPayments] = useState<IPayment[] | null>(null);
	const [showPurchaseOffsetsModal, setShowPurchaseOffsetsModal] =
		useState(false);
	const [showPurchaseOffsets, setShowPurchaseOffsets] = useState(false);
	const [loadingOffsetsResult, setLoadingOffsetsResult] = useState(false);
	const [salesToBeFilled, setSalesToBeFilled] = useState(false);
	const [messagesIndex, setMessagesIndex] = useState(0);
	const [offsetsLoadingMessages, setOffsetsLoadingMessages] = useState([
		t("salesReport.confirmingPayment"),
		t("salesReport.reservingOffsets"),
		t("salesReport.checkingDetails"),
		t("salesReport.finalizingCertification"),
		t("salesReport.aboutToFinish"),
	]);
	const { enqueueSnackbar } = useSnackbar();
	const currentYear = new Date().getUTCFullYear();
	const currentMonth = new Date().getUTCMonth();
	const currentQuarter = Math.ceil((currentMonth + 1) / 3);
	//const [editing, setEditing] = useState(false);
	//const [showEditingModal, setShowEditingModal] = useState(false);
	//const [selectedSale, setSelectedSale] = useState<number | null>(null);

	useEffect(() => {
		if (loadingOffsetsResult) {
			loadingOffsetsInterval = setInterval(() => {
				setMessagesIndex((prevIndex) =>
					prevIndex === offsetsLoadingMessages.length - 1 ? 0 : prevIndex + 1
				);
			}, 5000);
		} else if (loadingOffsetsInterval) {
			clearInterval(loadingOffsetsInterval);
		}

		return () => {
			if (loadingOffsetsInterval) {
				clearInterval(loadingOffsetsInterval);
			}
		};
	}, [loadingOffsetsResult, offsetsLoadingMessages.length]);

	useQuery("strings", () => stringActions.list(), {
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
		onSuccess: (res) => {
			if (res.results.length > 0) {
				setOffsetsLoadingMessages([
					(res.results[0] as any)["offsets_loading_1"],
					(res.results[0] as any)["offsets_loading_2"],
					(res.results[0] as any)["offsets_loading_3"],
					(res.results[0] as any)["offsets_loading_4"],
					(res.results[0] as any)["offsets_loading_5"],
				]);
			}
		},
		onError: (error: any) => {
			console.log(error);
		},
	});

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"sales",
		() =>
			saleActions.list(
				!user?.is_superuser ? user?.company.id : undefined,
				search,
				undefined,
				undefined,
				filters.companies,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setSales(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { refetch: refetchBalance } = useQuery(
		"balance",
		() =>
			saleActions.getBalance(
				user?.is_superuser && filters.companies ? filters.companies : [],
				!user?.is_superuser ? user?.company.id : undefined
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setBalance(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const { refetch: refetchPendingPayments } = useQuery(
		"pending-payments",
		() => saleActions.getPayments(user!.company.id, true),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setPayments(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const { mutate } = useMutation(
		"sales-detail-update",
		({
			saleId,
			amount,
			companyId,
		}: {
			saleId: number;
			amount: number;
			companyId?: number;
		}) => saleActions.update(saleId, amount, companyId),
		{
			retry: false,
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	useEffect(() => {
		if (sales && sales.length > 0) {
			let saleToBeFilled = false;
			sales.forEach((s) => {
				(s.product?.sales ?? []).forEach((sale) => {
					const saleYear = new Date(sale.start_date).getUTCFullYear();
					const saleMonth = new Date(sale.start_date).getUTCMonth();
					const saleQuarter = Math.ceil((saleMonth + 1) / 3);
					const isPastQuarter =
						saleYear < currentYear ||
						(saleYear === currentYear && saleQuarter < currentQuarter);
					if (isPastQuarter && !sale.amount) {
						saleToBeFilled = true;
					}
				});
			});
			setSalesToBeFilled(saleToBeFilled);
		}
	}, [sales, currentYear, currentQuarter]);

	useEffect(() => {
		refetchBalance();
	}, [filters, refetchBalance]);

	const handleSaleAmountChange = (
		estimationId: number,
		newValue: number,
		saleId: number
	) => {
		console.log(estimationId);
		setSales((currentSales) => {
			console.log(currentSales);
			return currentSales.map((sale) => {
				if (sale.id === estimationId && sale.product.sales) {
					const index = sale.product.sales.findIndex((s) => s.id === saleId);
					if (index !== -1) {
						sale.product.sales[index]!.amount = newValue;
					}
				}
				return sale;
			});
		});
	};

	const columns = [
		{
			field: "id",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<Typography sx={{ marginLeft: 2, fontSize: 14 }}>
						{Intl.NumberFormat("en", {
							maximumFractionDigits: 0,
						}).format(params.row.id)}
					</Typography>
				);
			},
			headerName: t("salesReport.id"),
			headerClassName: classes.customHeader,
		},
		{
			field: "product",
			headerName: t("salesReport.product"),
			flex: 1,
			minWidth: 200,
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<Typography
						variant="body2"
						onClick={() => {
							navigate("/sales/" + params.row.id);
						}}
						sx={{
							color: "#25406D",
							fontWeight: "bold",
							cursor: "pointer",
							"&:hover": { cursor: "pointer", textDecoration: "underline" },
						}}
					>
						{params.row.product.name +
							(params.row.product.asin
								? " - " + params.row.product.asin
								: params.row.product.upc
								? " - " + params.row.product.upc
								: "")}
					</Typography>
				);
			},
		},
		{
			field: "asin",
			headerName: "ASIN/UPC/SKU",
			sortable: false,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<Typography variant="body2">
						{params.row.product.asin
							? params.row.product.asin
							: params.row.product.upc
							? params.row.product.upc
							: params.row.product.sku
							? params.row.product.sku
							: ""}
					</Typography>
				);
			},
		},
		{
			field: "emissions_product",
			headerAlign: "right",
			sortable: false,
			minWidth: 200,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(params.row.product_lca_version_emission?.emissions ?? 0);
			},
			headerName: t("salesReport.emissionsPerProduct"),
			headerClassName: classes.customHeader,
		},
		{
			field: "product.sales",
			headerName: t("salesReport.unitSales"),
			sortable: false,
			minWidth: 350,
			renderHeader: () => {
				return (
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						<Typography variant="body2" sx={{ fontWeight: 500 }}>
							{t("salesReport.unitSales")}
						</Typography>
						{/*user?.is_superuser && (
							<IconButton
								onClick={() => {
									setShowEditingModal(true);
								}}
							>
								<EditIcon sx={{ fontSize: 14 }} />
							</IconButton>
						)*/}
					</Box>
				);
			},
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<Box sx={{ display: "flex", gap: 3 }}>
						{[...(params.row.product?.sales ?? [])]
							.filter(
								(sale, index) =>
									!(params.row.product?.sales ?? []).find(
										(s, sIndex) =>
											s.id !== sale.id &&
											s.start_date === sale.start_date &&
											(s.amount > sale.amount ||
												(s.amount === 0 && sIndex < index))
									)
							)
							.sort((a, b) =>
								a.start_date === b.start_date
									? (b.amount ?? 0) - (a.amount ?? 0)
									: new Date(a.start_date).getTime() -
									  new Date(b.start_date).getTime()
							)
							.filter((_, index, array) => index >= array.length - 4)
							.map((sale: ISale, index) => {
								const saleYear = new Date(sale.start_date).getUTCFullYear();
								const saleMonth = new Date(sale.start_date).getUTCMonth();
								const saleQuarter = Math.ceil((saleMonth + 1) / 3);
								const isPreviousQuarter =
									(saleYear === currentYear - 1 &&
										saleQuarter === 3 &&
										currentQuarter === 0) ||
									(saleYear === currentYear &&
										saleQuarter === currentQuarter - 1);

								return (
									<Box>
										<Typography fontSize={12} fontWeight={"bold"}>
											{"Q" +
												Math.ceil(
													((new Date(sale.start_date).getUTCMonth() + 1) / 12) *
														4
												).toString() +
												"-" +
												new Date(sale.start_date).getUTCFullYear().toString()}
										</Typography>
										<NumericFormat
											value={sale.amount}
											displayType={"input"}
											thousandSeparator={true}
											allowNegative={false}
											decimalScale={0}
											onValueChange={({ value }) => {
												const numericValue = parseFloat(value);
												console.log(numericValue);
												handleSaleAmountChange(
													params.row.id,
													isNaN(numericValue) ? 0 : numericValue,
													sale.id
												);
											}}
											onBlur={async () => {
												if (isPreviousQuarter) {
													console.log(sale);
													await new Promise((resolve) => {
														mutate(
															{
																saleId: sale.id,
																amount: sale.amount,
																companyId: params.row.product.company!.id,
															},
															{
																onSuccess: () => {
																	resolve(true);
																},
																onError: (error) => {
																	console.log(error);
																	resolve(true);
																},
															}
														);
													});
													refetchBalance();
												}
												//setSelectedSale(null);
											}}
											onFocus={() => {
												if (!sale.amount) {
													//setSelectedSale(sale.id);
												}
											}}
											disabled={
												!user?.is_superuser && !isPreviousQuarter /*||
													(sale.amount && sale.amount > 0)*/ /*||
												(user?.is_superuser && !editing)*/
											}
											customInput={TextField}
											inputProps={{
												style: {
													padding: 0,
													border:
														isPreviousQuarter && !sale.amount
															? "1px solid red"
															: "1px solid #ececec",
												},
												readOnly: !user?.is_superuser && !isPreviousQuarter /*||
														(sale.amount && sale.amount > 0)*/ /*||
													(user?.is_superuser && !editing)*/,
											}}
										/>
									</Box>
								);
							})}
					</Box>
				);
			},
		},
		{
			field: "total",
			headerName: t("salesReport.totalUnitsSold"),
			headerAlign: "right",
			sortable: false,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 0,
				}).format(
					params.row.product?.sales?.reduce((prev, current) => {
						return prev + current.amount;
					}, 0) ?? 0
				);
			},
		},
		/*{
			field: "emissions",
			headerName: t("salesReport.forecastedEmissions"),
			headerAlign: "right",
			sortable: false,
			minWidth: 160,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 0,
				}).format(
					Math.ceil(
						((params.row.amount ?? 0) *
							(params.row.product_lca_version_emission?.emissions ?? 0)) /
							1000
					)
				);
			},
		},
		{
			field: "actual_emissions",
			headerName: t("salesReport.actualEmissions"),
			headerAlign: "right",
			sortable: false,
			minWidth: 160,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 0,
				}).format(
					Math.ceil(
						((params.row.product?.sales?.reduce((prev, current) => {
							return prev + current.amount;
						}, 0) ?? 0) *
							(params.row.product_lca_version_emission?.emissions ?? 0)) /
							1000
					)
				);
			},
		},
		{
			field: "balance",
			headerName: t("salesReport.balance"),
			headerAlign: "right",
			sortable: false,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				const finalAmount = Math.ceil(
					Math.ceil(
						((params.row.amount ?? 0) *
							(params.row.product_lca_version_emission?.emissions ?? 0)) /
							1000
					) -
						Math.ceil(
							((params.row.product?.sales?.reduce((prev, current) => {
								return prev + current.amount;
							}, 0) ?? 0) *
								(params.row.product_lca_version_emission?.emissions ?? 0)) /
								1000
						)
				);
				return (
					<span style={{ color: finalAmount < 0 ? "#EB4B4B" : "inherit" }}>
						{Intl.NumberFormat("en", {
							maximumFractionDigits: 0,
						}).format(finalAmount === 0 ? Math.abs(finalAmount) : finalAmount)}
					</span>
				);
			},
		},*/
	];

	return (
		<Box>
			<Heading
				title={t("salesReport.salesReport")}
				showSearch
				setSearch={setSearch}
				actions={() => (
					<SaleActionBar
						selectedRows={selectedRows}
						refetch={refetch}
						filters={filters}
						setFilters={setFilters}
					/>
				)}
			/>
			<Box
				sx={{
					padding: 2,
					background: "#efefef",
					display: "flex",
					gap: 8,
					justifyContent: "flex-end",
					borderRadius: 1,
					overflowX: "auto",
					flexDirection: {
						xs: "column",
						sm: "row",
					},
					textAlign: {
						xs: "right",
						sm: "left",
					},
					marginBottom: 2,
				}}
			>
				{/*<Box sx={{ textAlign: "center" }}>
					<Typography fontWeight={"bold"} fontSize={14}>
						{t("salesReport.forecastedEmissionsTitle")}{" "}
						<span style={{ fontWeight: "normal" }}>
							{t("salesReport.mtCO2e")}
						</span>
						<Tooltip
							sx={{ padding: 0 }}
							title={t("salesReport.productEmissionsBasedOnForecasted")}
						>
							<IconButton sx={{ height: 12 }}>
								<InfoIcon sx={{ fontSize: 14 }} />
							</IconButton>
						</Tooltip>
					</Typography>
					<Typography fontSize={14}>
						{balance?.estimated_amount
							? Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
							  }).format(Math.ceil(balance.estimated_amount))
							: "--"}
					</Typography>
				</Box>*/}
				<Box sx={{ textAlign: "center" }}>
					<Typography fontWeight={"bold"} fontSize={14}>
						{t("salesReport.actualEmissionsTitle")}{" "}
						<span style={{ fontWeight: "normal" }}>
							{t("salesReport.mtCO2e")}
						</span>
						<Tooltip
							sx={{ padding: 0 }}
							title={t("salesReport.offsetsUsedActualSales")}
						>
							<IconButton sx={{ height: 12 }}>
								<InfoIcon sx={{ fontSize: 14 }} />
							</IconButton>
						</Tooltip>
					</Typography>
					<Typography fontSize={14}>
						{balance?.sales_amount
							? Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
							  }).format(Math.ceil(balance.sales_amount))
							: "--"}
					</Typography>
				</Box>

				<Box sx={{ textAlign: "center" }}>
					<Typography fontWeight={"bold"} fontSize={14}>
						{t("salesReport.purchasedOffsets")}{" "}
						<span style={{ fontWeight: "normal" }}>
							{t("salesReport.mtCO2e")}
						</span>
						<Tooltip sx={{ padding: 0 }} title={t("salesReport.totalOffsets")}>
							<IconButton sx={{ height: 12 }}>
								<InfoIcon sx={{ fontSize: 14 }} />
							</IconButton>
						</Tooltip>
					</Typography>
					<Typography fontSize={14}>
						{balance?.offsets_amount || balance?.purchased_offsets
							? Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
							  }).format(
									Math.ceil(
										(balance?.offsets_amount ?? 0) +
											(balance?.purchased_offsets ?? 0)
									)
							  )
							: "--"}
					</Typography>
				</Box>
				<Box sx={{ textAlign: "center" }}>
					<Typography fontWeight={"bold"} fontSize={14}>
						{t("salesReport.offsetBalance")}{" "}
						<span style={{ fontWeight: "normal" }}>
							{t("salesReport.mtCO2e")}
						</span>
						<Tooltip
							sx={{ padding: 0 }}
							title={t("salesReport.purchasedOffsetsMinusForecastedOffsets")}
						>
							<IconButton sx={{ height: 12 }}>
								<InfoIcon sx={{ fontSize: 14 }} />
							</IconButton>
						</Tooltip>
					</Typography>
					<Typography
						fontSize={14}
						sx={{
							color:
								Math.ceil(
									(balance?.purchased_offsets ?? 0) -
										(balance?.sales_amount ?? 0) +
										(balance?.offsets_amount ?? 0) /*-
										(balance?.estimated_amount ?? 0)*/
								) >= -0
									? "inherit"
									: "red",
						}}
					>
						{Intl.NumberFormat("en", {
							maximumFractionDigits: 0,
						}).format(
							(() => {
								const num =
									Math.sign(
										(balance?.purchased_offsets ?? 0) -
											(balance?.sales_amount ?? 0) +
											(balance?.offsets_amount ?? 0) /*-
											(balance?.estimated_amount ?? 0)*/
									) *
									Math.ceil(
										Math.abs(
											(balance?.purchased_offsets ?? 0) -
												(balance?.sales_amount ?? 0) +
												(balance?.offsets_amount ?? 0) /*-
												(balance?.estimated_amount ?? 0)*/
										)
									);
								return Math.ceil(num) === -0
									? 0
									: Math.sign(num) * Math.ceil(Math.abs(num));
							})()
						)}
					</Typography>
				</Box>
			</Box>
			{!user?.is_superuser && (
				<Alert
					sx={{ marginBottom: 2 }}
					severity={
						Math.ceil(
							(() => {
								const num =
									Math.sign(
										(balance?.purchased_offsets ?? 0) -
											(balance?.sales_amount ?? 0) +
											(balance?.offsets_amount ?? 0) /*-
											(balance?.estimated_amount ?? 0)*/
									) *
									Math.ceil(
										Math.abs(
											(balance?.purchased_offsets ?? 0) -
												(balance?.sales_amount ?? 0) +
												(balance?.offsets_amount ?? 0) /*-
												(balance?.estimated_amount ?? 0)*/
										)
									);
								return Math.ceil(num) === -0
									? 0
									: Math.sign(num) * Math.ceil(Math.abs(num));
							})()
						) >= -0
							? "success"
							: "error"
					}
				>
					{Math.ceil(
						(() => {
							const num =
								Math.sign(
									(balance?.purchased_offsets ?? 0) -
										(balance?.sales_amount ?? 0) +
										(balance?.offsets_amount ?? 0) /*-
										(balance?.estimated_amount ?? 0)*/
								) *
								Math.ceil(
									Math.abs(
										(balance?.purchased_offsets ?? 0) -
											(balance?.sales_amount ?? 0) +
											(balance?.offsets_amount ?? 0) /*-
											(balance?.estimated_amount ?? 0)*/
									)
								);
							return Math.ceil(num) === -0
								? 0
								: Math.sign(num) * Math.ceil(Math.abs(num));
						})()
					) >= -0 ? (
						t("salesReport.balancePositive")
					) : (
						<>
							{t("salesReport.balanceNegative")}{" "}
							<b
								style={{ cursor: "pointer" }}
								onClick={() => {
									setShowPurchaseOffsetsModal(true);
								}}
							>
								{t("salesReport.clickHereToPurchase")}
							</b>
						</>
					)}
				</Alert>
			)}
			{!user?.is_superuser && (payments ?? []).length > 0 && (
				<>
					<Divider />
					<Alert sx={{ marginTop: 2, marginBottom: 2 }} severity={"info"}>
						{t("salesReport.paymentSubmited")}
						<br />
						<b>{t("salesReport.paymentSubmitedInfo")}</b>
					</Alert>
				</>
			)}
			{!user?.is_superuser && salesToBeFilled && (
				<>
					<Divider />
					<Alert sx={{ marginTop: 2, marginBottom: 2 }} severity={"warning"}>
						{t("salesReport.fillSalesReports")}
						<br />
						<br />
						<b>{t("salesReport.fillSalesReports2")}</b>
					</Alert>
				</>
			)}
			<DataTable
				rows={sales}
				columns={columns}
				setSelectedRows={setSelectedRows}
				showCheckbox={false}
				rowCount={rowCount}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "28px",
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-columnHeaders": {},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none!important",
					},
					"& .MuiDataGrid-columnHeaderTitle": {
						textOverflow: "clip",
						whiteSpace: "normal",
						lineHeight: 1,
					},
					"& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitle":
						{
							textAlign: "right",
						},
				}}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
			/>
			<BasicModal
				width={600}
				showModal={showPurchaseOffsets}
				handleClose={() => {
					setShowPurchaseOffsets(false);
				}}
				hideClose={false}
				children={
					<PurchaseOffsets
						quantity={
							Math.abs(
								Math.sign(
									(balance?.purchased_offsets ?? 0) -
										(balance?.sales_amount ?? 0) +
										(balance?.offsets_amount ?? 0) /*-
										(balance?.estimated_amount ?? 0)*/
								) *
									Math.ceil(
										Math.abs(
											(balance?.purchased_offsets ?? 0) -
												(balance?.sales_amount ?? 0) +
												(balance?.offsets_amount ?? 0) /*-
												(balance?.estimated_amount ?? 0)*/
										)
									)
							) + 1
						}
						productsCount={0}
						needsFirstTimeFee={false}
						height={"auto"}
						inModal={true}
						closeModal={() => {
							setShowPurchaseOffsets(false);
						}}
						callback={() => {
							enqueueSnackbar("Payment created!", {
								variant: "success",
							});
							refetch();
							refetchBalance();
							refetchPendingPayments();
							setTimeout(() => {
								setShowPurchaseOffsets(false);
							}, 5000);
						}}
						offsetsLoadingMessages={offsetsLoadingMessages}
						messagesIndex={messagesIndex}
						setLoadingOffsetsResult={setLoadingOffsetsResult}
					/>
				}
			/>
			<BasicModal
				width={800}
				showModal={showPurchaseOffsetsModal}
				handleClose={() => {
					setShowPurchaseOffsetsModal(false);
				}}
				children={
					<PurchaseAgree
						title={t("salesReport.purchaseSummary")}
						text={t("salesReport.agreeText")}
						button1={t("climecoCertificationProcess.cancelButton")}
						button1Callback={() => {
							setShowPurchaseOffsetsModal(false);
						}}
						button2={t("climecoCertificationProcess.agreeButton")}
						button2Callback={() => {
							setShowPurchaseOffsetsModal(false);
							setShowPurchaseOffsets(true);
						}}
						amount={
							Math.abs(
								Math.sign(
									(balance?.purchased_offsets ?? 0) -
										(balance?.sales_amount ?? 0) +
										(balance?.offsets_amount ?? 0) /*-
										(balance?.estimated_amount ?? 0)*/
								) *
									Math.ceil(
										Math.abs(
											(balance?.purchased_offsets ?? 0) -
												(balance?.sales_amount ?? 0) +
												(balance?.offsets_amount ?? 0) /* -
												(balance?.estimated_amount ?? 0)*/
										)
									)
							) + 1
						}
						balance={0}
					/>
				}
				hideClose
			/>
			{/*<BasicModal
				width={800}
				showModal={showEditingModal}
				handleClose={() => {
					setShowEditingModal(false);
				}}
				hideClose={false}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("salesReport.editSalesConfirmation")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowEditingModal(false);
									setEditing(false);
								}}
							>
								{t("salesReport.cancel")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									setShowEditingModal(false);
									setEditing(true);
								}}
							>
								{t("salesReport.edit")}
							</Button>
						</Box>
					</Box>
				}
			/>*/}
		</Box>
	);
}
