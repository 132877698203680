import React from "react";
import ReactDOM from "react-dom/client";
import CacheBuster from "react-cache-buster";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<CacheBuster
			currentVersion={process.env.REACT_APP_VERSION || "localhost"}
			isEnabled={
				process.env.REACT_APP_ENV === "prod" ||
				process.env.REACT_APP_ENV === "stage"
			}
			loadingComponent={
				<div className="loading-container">
					<div className="spinner"></div>
				</div>
			}
			metaFileDirectory={"."}
		>
			<App />
		</CacheBuster>
	</React.StrictMode>
);
