import { IBalance, IEstimation, IPayment, ISale } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"

class Sale {
    async list(companyId?: number, search?: string, start_date?: string, end_date?: string, companies?: Array<number>, page?: number, sort: string = "-updated_at", pageSize: number = 10): Promise<{ count: number, results: Array<IEstimation> }> {
        const endpoint = companyId ? "companies/" + companyId + "/sales" : "sales"
        const params = new URLSearchParams();
        params.append("page", page?.toString() ?? "1");
        params.append("page_size", pageSize.toString())
        params.append("search", search ?? "");
        params.append("ordering", sort);
        if (start_date) {
            params.append("start_date", start_date);
        }
        if (end_date) {
            params.append("end_date", end_date);
        }
        if (companies && companies.length > 0) {
            params.append("product__company__in", companies.join(","));
        }
        const response = await connectionsClient.client.get(endpoint, {
            params
        });
        return response.data;
    }

    async getOne(saleId: number, companyId?: number): Promise<IEstimation> {
        const endpoint = companyId ? "companies/" + companyId + "/sales/" + saleId : "sales/" + saleId
        const response = await connectionsClient.client.get(endpoint);
        return response.data;
    }

    async create(amount: number, emissions: number, start_date: string, end_date: string, productId: number, lcaDocument?: number, companyId?: number): Promise<ISale> {
        const endpoint = companyId ? "companies/" + companyId + "/historical-sales/" : "historical-sales/"
        const response = await connectionsClient.client.post(endpoint, { amount, emissions, start_date, end_date, product: productId, lca_document: lcaDocument });
        return response.data;
    }

    async update(saleId: number, amount: number, /*start_date: string, end_date: string,*/ companyId?: number): Promise<ISale> {
        const endpoint = companyId ? "companies/" + companyId + "/sales/" + saleId : "sales/" + saleId
        const response = await connectionsClient.client.patch(endpoint, { amount, /*start_date, end_date*/ });
        return response.data;
    }

    async delete(saleId: number, companyId?: number): Promise<ISale> {
        const endpoint = companyId ? "companies/" + companyId + "/sales/" + saleId : "sales/" + saleId
        const response = await connectionsClient.client.delete(endpoint);
        return response.data;
    }

    async getBalance(companies: Array<number>, companyId?: number, search?: string, start_date?: string, end_date?: string): Promise<IBalance> {
        const endpoint = companyId ? "companies/" + companyId + "/balance" : "balance"
        const params = new URLSearchParams();
        params.append("search", search ?? "");
        if (start_date) {
            params.append("start_date", start_date);
        }
        if (end_date) {
            params.append("end_date", end_date);
        }
        if (companies.length > 0) {
            params.append("companies", companies.join(","));
        }
        const response = await connectionsClient.client.get(endpoint, {
            params
        });
        return response.data;
    }

    async getPayments(companyId: number, pending: boolean): Promise<IPayment[]> {
        const endpoint = companyId ? "companies/" + companyId + "/payments" : "payments"
        const response = await connectionsClient.client.get(endpoint, {
            params: {
                status: pending ? "PENDING" : undefined,
                certification_process__isnull: true
            }
        });
        return response.data.results;
    }
}

export const sale = new Sale();