import * as React from "react";
import { Typography, Box, Button } from "@mui/material";
import { PropTypes } from "./DeleteConfirmation.types";
import { useTranslation } from "react-i18next";

export function DeleteConfirmation(props: PropTypes) {
	const { title, handleDeleteModalClose, callback, decertifying } = props;
	const { t } = useTranslation();

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				{decertifying
					? t("deleteConfirmation.decertificationRequest")
					: t("deleteConfirmation.deleteRequest")}
			</Typography>
			<Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
				{t("deleteConfirmation.item")}{" "}
				<span style={{ fontStyle: "italic" }}>{title}</span>{" "}
				{t("deleteConfirmation.willBe")}{" "}
				{decertifying
					? t("deleteConfirmation.requestedDecertification")
					: t("deleteConfirmation.deleted")}
				.
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="outlined"
					sx={{ marginTop: 2, maxWidth: 300, alignSelf: "end", marginRight: 2 }}
					onClick={() => {
						handleDeleteModalClose();
					}}
				>
					{t("deleteConfirmation.cancel")}
				</Button>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						backgroundColor: decertifying ? "#25406D" : "#e25757",
						"&:hover": {
							backgroundColor: decertifying ? "#25406D" : "#b44545",
						},
					}}
					onClick={() => {
						callback();
					}}
				>
					{decertifying
						? t("deleteConfirmation.requestDecertification")
						: t("deleteConfirmation.delete")}
				</Button>
			</Box>
		</Box>
	);
}
