import { useCallback, useContext, useEffect, useState } from "react";
import { PropTypes } from "./CreateLCAsTable.types";
import { ICompany, ILCA, ILCAVersion, IGetCertifiedStep } from "../../models";
import {
	Box,
	TextField,
	Button,
	Typography,
	Autocomplete,
	Tooltip,
	IconButton,
} from "@mui/material";
import { UploadFile } from "../UploadFile";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import {
	certification as certificationActions,
	company as companyActions,
	lca as lcaActions,
	lcaVersion as lcaVersionActions,
} from "../../controllers";
import { ICertification } from "../../models";
import { BasicModal } from "../BasicModal";
import { LCARecommendations } from "../LCARecommendations";
import InfoIcon from "@mui/icons-material/Info";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";

export function CreateLCAsTable({
	inModal,
	inModalCallback,
	selectedLCA,
	setShowLCAInfo,
	readMode,
	index,
	step,
	setCurrentStep,
	rows,
	setRows,
	defaultLCA,
	createLCAs,
	setCreateLCAs,
	setNextButtonLoading,
	visible = true,
	addingProducts,
	updatingLCA,
}: PropTypes) {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const [dateType, setDateType] = useState(["text"]);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
	const [loading, setLoading] = useState(false);
	const [showLCARecommendations, setLCARecommendations] = useState(false);
	const [showLCARejectedModal, setShowLCARejectedModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [rejectedReason, setRejectedReason] = useState<string | null>(null);
	const [wrongDates, setWrongDates] = useState<Array<number>>([]);
	const { t } = useTranslation();

	useEffect(() => {
		if (selectedLCA) {
			if (user?.is_superuser && selectedLCA.company) {
				setSelectedCompany(selectedLCA.company);
			}
			if (selectedLCA.lca_versions && selectedLCA.lca_versions.length > 0) {
				const currentIndex = index ?? 0;
				setRows([
					{
						id: selectedLCA.lca_versions[currentIndex].id,
						status: selectedLCA.lca_versions[currentIndex].status,
						title: selectedLCA.lca_versions[currentIndex].title,
						lca_document: selectedLCA.lca_versions[currentIndex].lca_document,
						certification_date:
							selectedLCA.lca_versions[currentIndex].certification_date?.split(
								"T"
							)[0],
						lca_provider: selectedLCA.lca_versions[currentIndex].lca_provider,
						number_of_products:
							selectedLCA.lca_versions[currentIndex].number_of_products,
						products: selectedLCA.lca_versions[currentIndex].products,
						product_category:
							selectedLCA.lca_versions[currentIndex].product_category,
						lca_standards: selectedLCA.lca_versions[currentIndex].lca_standards,
						certification_processes: new Array<ICertification>(),
						certification_process:
							selectedLCA.lca_versions[currentIndex].certification_process,
					},
				]);
				if (currentIndex === 0) {
					const auxRejectedReason =
						selectedLCA.lca_versions[currentIndex].reject_reason ?? null;

					setRejectedReason(auxRejectedReason);

					if (!readMode) {
						if (auxRejectedReason) {
							setShowLCARejectedModal(true);
							if (setShowLCAInfo) {
								setShowLCAInfo(false);
							}
						} else if (setShowLCAInfo) {
							setShowLCAInfo(true);
						}
					}
				}
			}
		} else {
			setSelectedCompany(null);
			setRejectedReason(null);
			if (!readMode && setShowLCAInfo) {
				setShowLCAInfo(false);
			}
		}
	}, [
		selectedLCA,
		setShowLCAInfo,
		user?.is_superuser,
		index,
		readMode,
		defaultLCA,
		setRows,
	]);

	const { isLoading, mutate } = useMutation(
		"bulk-lcas",
		({ lca }: { lca: ILCA }) =>
			lcaActions.create(
				lca!.title!,
				user?.is_superuser ? selectedCompany?.id ?? 0 : user?.company.id ?? 0
			),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingLCAVersion, mutate: mutateLCAVersion } =
		useMutation(
			"bulk-lca-versions",
			({ lcaVersion, lca }: { lcaVersion: ILCAVersion; lca: ILCA }) =>
				lcaVersionActions.create(
					lcaVersion.title!,
					lcaVersion.lca_document,
					lcaVersion.lca_provider!,
					lcaVersion.certification_date!,
					lcaVersion.number_of_products!,
					lcaVersion.lca_standards!,
					lcaVersion.product_category!,
					user?.is_superuser ? selectedCompany?.id ?? 0 : user?.company.id ?? 0,
					lca.id
				),
			{
				retry: false,
			}
		);

	const { mutate: createCertification } = useMutation(
		"certification",
		({
			lcaVersions,
			companyId,
		}: {
			lcaVersions: Array<number>;
			companyId: number;
		}) =>
			certificationActions.create({
				isClimeCo: true,
				name: t("lcaTable.new"),
				companyId: companyId,
				isHistorical: false,
				lcaVersions: lcaVersions,
				addingProducts: addingProducts,
				updatingLCA: updatingLCA,
			}),
		{
			retry: false,
		}
	);

	const filteredRows = rows.filter(
		(r) =>
			r.title &&
			r.lca_document &&
			r.certification_date &&
			r.lca_provider &&
			r.number_of_products
	);

	const bulkLCAs = useCallback(
		(certify: boolean) => {
			setLoading(true);
			let index = 0;
			let lcaVersions = Array<number>();
			const createLCAVersion = (
				lcaVersion: ILCAVersion,
				index: number,
				lca: ILCA
			) => {
				return new Promise((resolve, reject) => {
					mutateLCAVersion(
						{
							lcaVersion,
							lca,
						},
						{
							onSuccess: (res) => {
								resolve(res.id);
							},
							onError: (error: any) => {
								setLoading(false);
								enqueueSnackbar(
									t("lcaTable.lcaUploadError", {
										lcaVersionTitle: lcaVersion.title,
									}),
									{
										variant: "error",
									}
								);
								reject(-1);
							},
						}
					);
				});
			};
			const createLCA = async (lcaVersion: ILCAVersion, index: number) => {
				const lca: ILCA = {
					id: -1,
					title: lcaVersion.title,
				};
				if (!selectedLCA) {
					mutate(
						{
							lca: lca,
						},
						{
							onSuccess: async (res) => {
								const auxRows = [...filteredRows];
								auxRows[index].id = res.id;
								lca.id = res.id;
								setRows(auxRows);
								index += 1;
								const lcaVersionId = await createLCAVersion(
									lcaVersion,
									index,
									selectedLCA || lca
								);
								lcaVersions.push(lcaVersionId as number);

								if (index < filteredRows.length) {
									createLCA(filteredRows[index], index);
								} else {
									if (certify) {
										createCertification(
											{
												lcaVersions: lcaVersions,
												companyId: user?.is_superuser
													? selectedCompany?.id ?? 0
													: user?.company.id ?? 0,
											},
											{
												onSuccess: (res) => {
													navigate("/climeco-certificationss/" + res.id, {
														replace: true,
														state: null,
													});
												},
												onError: (error) => {
													setLoading(false);
													console.log(error);
													enqueueSnackbar(t("general.errorMessage"), {
														variant: "error",
													});
												},
											}
										);
									} else {
										setLoading(false);
										enqueueSnackbar(t("lcaTable.lcaCreated"), {
											variant: "success",
										});
										if (inModal) {
											setTimeout(() => {
												inModalCallback && inModalCallback(rows);
											}, 500);
										} else {
											navigate("/lcas", { replace: true, state: null });
										}
									}
								}
							},
							onError: (error: any) => {
								enqueueSnackbar(t("general.errorMessage"), {
									variant: "error",
								});

								index += 1;
								if (index < filteredRows.length) {
									createLCA(filteredRows[index], index);
								} else {
									setLoading(false);
									enqueueSnackbar(t("lcaTable.lcaCreated"), {
										variant: "success",
									});
									navigate("/lcas", { replace: true, state: null });
								}
							},
						}
					);
				} else {
					const lcaVersionId = await createLCAVersion(
						lcaVersion,
						index,
						selectedLCA || lca
					);

					index += 1;

					if (certify) {
						createCertification(
							{
								lcaVersions: [lcaVersionId as number],
								companyId: user?.is_superuser
									? selectedCompany?.id ?? 0
									: user?.company.id ?? 0,
							},
							{
								onSuccess: (res) => {
									navigate("/climeco-certificationss/" + res.id, {
										replace: true,
										state: null,
									});
								},
								onError: (error) => {
									setLoading(false);
									console.log(error);
									enqueueSnackbar(t("general.errorMessage"), {
										variant: "error",
									});
								},
							}
						);
					} else {
						setLoading(false);
						enqueueSnackbar(t("lcaTable.lcaCreated"), {
							variant: "success",
						});
						if (inModal) {
							setTimeout(() => {
								inModalCallback && inModalCallback(rows);
							}, 500);
						} else {
							navigate("/lcas", { replace: true, state: null });
						}
					}
				}
			};

			createLCA(filteredRows[index], index);
		},
		[
			createCertification,
			enqueueSnackbar,
			filteredRows,
			inModal,
			inModalCallback,
			mutate,
			mutateLCAVersion,
			navigate,
			rows,
			selectedCompany?.id,
			selectedLCA,
			setRows,
			t,
			user?.company.id,
			user?.is_superuser,
		]
	);

	const { refetch: refetchCompanies } = useQuery(
		"companies",
		() => companyActions.list(undefined, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const addFourYears = (dateString?: string) => {
		if (dateString) {
			const inputDate = new Date(dateString);

			const year = inputDate.getUTCFullYear();
			const month = inputDate.getUTCMonth();
			const day = inputDate.getUTCDate();

			return `${year + 4}-${("0" + String(month + 1)).slice(-2)}-${(
				"0" + String(day)
			).slice(-2)}`;
		} else {
			return "";
		}
	};

	useEffect(() => {
		if (user?.is_superuser) {
			refetchCompanies();
		}
	}, [refetchCompanies, user?.is_superuser]);

	const numberOfRejections = selectedLCA
		? (selectedLCA?.lca_versions ?? []).filter(
				(lcaVersion) =>
					lcaVersion.rejected_date && !lcaVersion.rejected_date_paid
		  ).length
		: 0;

	useEffect(() => {
		if (createLCAs) {
			setCreateLCAs(false);

			if (!selectedLCA) {
				if (!step || (step && step === IGetCertifiedStep.LCA_LIST)) {
					bulkLCAs(true);
				} else if (setCurrentStep) {
					setCurrentStep((currentStep: number) => {
						return currentStep + 1;
					});
				}
			} else if (
				selectedLCA &&
				selectedLCA.lca_versions &&
				((rejectedReason &&
					(selectedLCA.lca_versions[0].lca_document !== rows[0].lca_document ||
						selectedLCA.lca_versions[0].certification_date?.split("T")[0] !==
							rows[0].certification_date?.split("T")[0])) ||
					(!rejectedReason &&
						selectedLCA.lca_versions[0].lca_document !== rows[0].lca_document &&
						selectedLCA.lca_versions[0].certification_date?.split("T")[0] !==
							rows[0].certification_date?.split("T")[0]))
			) {
				setShowConfirmationModal(true);
			} else if (
				selectedLCA &&
				selectedLCA.lca_versions &&
				((rejectedReason &&
					selectedLCA.lca_versions[0].lca_document === rows[0].lca_document &&
					selectedLCA.lca_versions[0].certification_date?.split("T")[0] ===
						rows[0].certification_date?.split("T")[0]) ||
					(!rejectedReason &&
						((updatingLCA &&
							(selectedLCA.lca_versions[0].changes_in_version ===
								rows[0].changes_in_version ||
								(rows[0].changes_in_version ?? "").length < 10)) ||
							(selectedLCA.lca_versions[0].lca_document !==
								rows[0].lca_document &&
								selectedLCA.lca_versions[0].certification_date?.split(
									"T"
								)[0] === rows[0].certification_date?.split("T")[0]) ||
							(selectedLCA.lca_versions[0].lca_document ===
								rows[0].lca_document &&
								rows[0].lca_document &&
								selectedLCA.lca_versions[0].certification_date?.split(
									"T"
								)[0] !== rows[0].certification_date?.split("T")[0]))))
			) {
				enqueueSnackbar(
					rejectedReason
						? t("lcaTable.changeToFixRejection")
						: t("lcaTable.changeToStartRevision"),
					{ variant: "error" }
				);
			} else if (
				selectedLCA &&
				selectedLCA.lca_versions &&
				selectedLCA.lca_versions.length > 0 &&
				selectedLCA.lca_versions[0].certification_process
			) {
				navigate(
					"/climeco-certificationss/" +
						selectedLCA.lca_versions[0].certification_process.id,
					{ replace: true, state: null }
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createLCAs]);

	useEffect(() => {
		setNextButtonLoading(isLoading || isLoadingLCAVersion || loading);
	}, [isLoading, isLoadingLCAVersion, loading, setNextButtonLoading]);

	const currentIndex = index ?? 0;

	return (
		<Box
			sx={{
				height: visible ? "auto" : 0,
				maxHeight: "100%",
				width: "100%",
				gap: 2,
				padding: { xs: "20px 20px", md: "20px 60px" },
				visibility: visible ? "visible" : "hidden",
			}}
		>
			{rows.map((row, index) => (
				<Box
					key={row.id}
					sx={{
						visibility: currentIndex === index ? "visible" : "hidden",
						height: currentIndex === index ? "auto" : 0,
					}}
				>
					<Box
						key={row.id}
						sx={{
							padding: 2,
							display: "flex",
							visibility:
								currentIndex === index &&
								(!step || (step && step === IGetCertifiedStep.ADD_LCAS))
									? "visible"
									: "hidden",
							height:
								currentIndex === index &&
								(!step || (step && step === IGetCertifiedStep.ADD_LCAS))
									? "auto"
									: 0,
							flexDirection: "column",
							gap: 1,
							overflow: "auto",
							maxHeight: 400,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								minWidth: 200,
								flex: 1,
								justifyContent: "flex-start",
								alignItems: "flex-start",
							}}
						>
							<span
								style={{
									textTransform: "capitalize",
									fontSize: 14,
									marginBottom: "10px",
									fontWeight: 700,
								}}
							>
								{t("lcaTable.nameYourLCA")}
								<span style={{ color: "#B61313" }}>*</span>
							</span>
							<TextField
								autoFocus={!addingProducts && !updatingLCA}
								placeholder={t("lcaTable.lcaTitle")}
								sx={{
									display: "flex",
									alignSelf: "start",
									width: "100%",
								}}
								value={row.title}
								disabled={addingProducts || updatingLCA}
								onChange={(event) => {
									const auxRows = [...rows];
									auxRows[index].title = event.target.value;
									setRows(auxRows);
								}}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								flex: 1,
								flexDirection: { xs: "column", md: "row" },
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									{t("lcaTable.lcaProvider")}
									<span style={{ color: "#B61313" }}>*</span>
								</span>
								<Tooltip title={t("lcaTable.tooltipLcaProvider")} arrow>
									<Autocomplete
										freeSolo
										options={[
											"Planet FWD",
											"WAP Sustainability",
											"Carbon Pass",
											"Sustainabase",
											"Aspire Sustainability",
											"Zero Carbon Industry Operations Center (Shenzhen) Co., Ltd",
											"Shenzhen Zhongrui Standard Technical Services Co., Ltd",
											"Carbon Insight",
										]}
										value={row.lca_provider || ""}
										disabled={addingProducts || updatingLCA}
										onChange={(event, newValue) => {
											let providerValue;
											if (typeof newValue === "string") {
												providerValue = newValue;
											} else {
												providerValue = newValue ? newValue : "";
											}

											const auxRows = [...rows];
											auxRows[index].lca_provider = providerValue;
											setRows(auxRows);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label=""
												placeholder={t("lcaTable.lcaProvider")}
												onChange={(event) => {
													const auxRows = [...rows];
													auxRows[index].lca_provider = event.target.value;
													setRows(auxRows);
												}}
											/>
										)}
										sx={{ flex: 1, width: "100%" }}
									/>
								</Tooltip>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									{t("lcaTable.appliedLcaStandards")}
									<span style={{ color: "#B61313" }}>*</span>
								</span>
								<Autocomplete
									clearIcon={null}
									options={[
										"GHG Product Protocol",
										"ISO 14040",
										"ISO 14044",
										"ISO 14046",
										"ISO 14067",
										"ISO 14068",
										"PAS 2050",
										"PAS 2060",
										"OTHER",
									]}
									multiple
									value={row.lca_standards || []}
									disabled={addingProducts || updatingLCA}
									onChange={(event, newValue) => {
										let providerValue;
										if (typeof newValue === "object") {
											providerValue = newValue;
										} else {
											providerValue = newValue ? newValue : [];
										}

										const auxRows = [...rows];
										auxRows[index].lca_standards = providerValue;
										setRows(auxRows);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label=""
											placeholder={t("lcaTable.appliedLcaStandards")}
										/>
									)}
									sx={{
										flex: 1,
										width: "100%",
										"& .MuiAutocomplete-inputRoot": {
											padding: "0px 11.5px",
										},
									}}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								flex: 1,
								flexDirection: { xs: "column", md: "row" },
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									{t("lcaTable.productCategory")}
									<span style={{ color: "#B61313" }}>*</span>
								</span>
								<Autocomplete
									options={[
										"BEAUTY PRODUCTS",
										"HEALTH PRODUCTS",
										"COOKING PRODUCTS",
										"FOOD",
										"BABY CARE",
										"PET CARE",
										"FASHION",
										"TOYS",
										"ELECTRONICS",
										"ACCESSORY",
										"HOME/OFFICE DECOR",
										"OTHER",
									]}
									value={row.product_category || ""}
									disabled={addingProducts || updatingLCA}
									getOptionLabel={(option) => {
										const capitalizeFirstLetterOfEachWord = (
											input: string
										): string => {
											return input
												.split(" ")
												.map(
													(word) =>
														word.charAt(0).toUpperCase() +
														word.slice(1).toLowerCase()
												)
												.join(" ");
										};
										return capitalizeFirstLetterOfEachWord(option);
									}}
									onChange={(event, newValue) => {
										let providerValue;
										if (typeof newValue === "string") {
											providerValue = newValue;
										} else {
											providerValue = newValue ? newValue : "";
										}

										const auxRows = [...rows];
										auxRows[index].product_category = providerValue;
										setRows(auxRows);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label=""
											placeholder={t("lcaTable.productCategory")}
											onChange={(event) => {
												const auxRows = [...rows];
												auxRows[index].product_category = event.target.value;
												setRows(auxRows);
											}}
										/>
									)}
									sx={{ flex: 1, width: "100%" }}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									{t("lcaTable.totalProductsLca")}
									<span style={{ color: "#B61313" }}>*</span>
								</span>
								<NumericFormat
									name={"number_of_products"}
									placeholder={t("lcaTable.numberProductsLca")}
									customInput={TextField}
									type="text"
									thousandSeparator={","}
									suffix={""}
									allowNegative={false}
									value={row.number_of_products}
									onValueChange={({ value }) => {
										const auxRows = [...rows];
										auxRows[index].number_of_products = parseFloat(value);
										setRows(auxRows);
									}}
									style={{
										flex: 1,
										width: "100%",
										marginRight: 2,
									}}
									InputProps={{
										sx: {
											"& input": {
												border: addingProducts
													? "1px solid #34918E"
													: "1px solid #C9C9C9",
											},
										},
									}}
									disabled={updatingLCA}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								flex: 1,
								flexDirection: { xs: "column", md: "row" },
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									{t("lcaTable.dateCompleted")}
									<span style={{ color: "#B61313" }}>*</span>
								</span>
								<TextField
									autoFocus={addingProducts || updatingLCA}
									placeholder={t("lcaTable.lcaCompletionDate")}
									type={dateType[index]}
									variant="outlined"
									InputProps={{
										inputProps: {
											max: `${new Date().toISOString().split("T")[0]}`,
											min: "2021-01-01",
											style: { minWidth: "150px" },
										},
									}}
									sx={{
										flex: 1,
										width: "100%",
										minWidth: "150px",
										borderRadius:
											wrongDates.findIndex((d) => d === row.id) > -1 ? 1 : 0,
										"& input": {
											border:
												wrongDates.findIndex((d) => d === row.id) > -1
													? "1px solid #eb4343"
													: addingProducts
													? "1px solid #34918E"
													: undefined,
										},
									}}
									value={row.certification_date}
									onChange={(event) => {
										const auxRows = [...rows];
										let certificationDate = event.target.value;
										try {
											const inputDate = new Date(event.target.value);
											if (inputDate > new Date()) {
												certificationDate = new Date()
													.toISOString()
													.split("T")[0];
											}
										} catch (error) {
											console.log(error);
										}

										auxRows[index].certification_date = certificationDate;
										setRows(auxRows);
									}}
									onFocus={() => {
										const auxDateType = [...dateType];
										auxDateType[index] = "date";
										setDateType(auxDateType);
									}}
									onBlur={() => {
										if (!row.certification_date) {
											const auxDateType = [...dateType];
											auxDateType[index] = "text";
											setDateType(auxDateType);
										} else {
											const auxRows = [...rows];
											const auxWrongDates = [...wrongDates];
											try {
												const inputDate = new Date(row.certification_date);
												if (inputDate < new Date("2021-01-01")) {
													auxRows[index].certification_date = "";
													if (
														!auxWrongDates.find((d) => d === auxRows[index].id)
													) {
														auxWrongDates.push(auxRows[index].id);
														setWrongDates(auxWrongDates);
													}
													setRows(auxRows);
												} else {
													const found = auxWrongDates.findIndex(
														(d) => d === auxRows[index].id
													);
													if (found > -1) {
														auxWrongDates.splice(found, 1);
														setWrongDates(auxWrongDates);
													}
												}
											} catch (error) {
												console.log(error);
												if (
													!auxWrongDates.find((d) => d === auxRows[index].id)
												) {
													auxWrongDates.push(auxRows[index].id);
													setWrongDates(auxWrongDates);
												}
											}
										}
									}}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									marginTop: 1,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									{t("lcaTable.lcaExpirationDate")}
									<span style={{ color: "#B61313" }}>*</span>
									<Tooltip
										sx={{ padding: 0 }}
										title={t("lcaTable.autofillDate")}
									>
										<IconButton sx={{ height: 12 }}>
											<InfoIcon sx={{ fontSize: 14 }} />
										</IconButton>
									</Tooltip>
								</span>
								<TextField
									placeholder={t("lcaTable.lcaExpirationDate")}
									type={dateType[index]}
									variant="outlined"
									InputProps={{
										inputProps: {
											max: `${new Date().toISOString().split("T")[0]}`,
											style: { minWidth: "150px" },
										},
									}}
									sx={{
										flex: 1,
										minWidth: "150px",
										width: "100%",
									}}
									value={addFourYears(row.certification_date)}
									disabled
								/>
							</Box>
						</Box>
						{updatingLCA && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									marginTop: 2,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									{t("lcaTable.changesInVersion")}
								</span>
								<TextField
									placeholder={t("lcaTable.changesInVersionPlaceholder")}
									variant="outlined"
									sx={{
										flex: 1,
										minWidth: "150px",
										width: "100%",
										"& .MuiInputBase-root": {
											padding: 0,
										},
									}}
									multiline
									rows={4}
									value={row.changes_in_version}
									onChange={(event) => {
										const auxRows = [...rows];
										auxRows[index].changes_in_version = event.target.value;
										setRows(auxRows);
									}}
								/>
							</Box>
						)}
						{user?.is_superuser && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									marginTop: 2,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									{t("lcaTable.companies")}
								</span>
								<Autocomplete
									disablePortal
									id="companies"
									options={companies}
									value={
										companies.find(
											(company) => company.id === selectedCompany?.id
										) || null
									}
									getOptionKey={(option) => option?.id ?? ""}
									getOptionLabel={(option) => option.name}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.id} style={{ minWidth: 200 }}>
												{option.name}
											</li>
										);
									}}
									onChange={(_, value) => {
										if (value) {
											const auxRows = [...rows];
											setSelectedCompany(value);
											setRows(auxRows);
										}
									}}
									renderInput={(params) => (
										<TextField
											sx={{
												display: "flex",
												alignSelf: "start",
											}}
											placeholder="Company"
											{...params}
											variant="outlined"
										/>
									)}
									sx={{ flex: 1, width: "100%" }}
								/>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							visibility:
								currentIndex === index &&
								(!step || (step && step === IGetCertifiedStep.UPLOAD_LCAS))
									? "visible"
									: "hidden",
							height:
								currentIndex === index &&
								(!step || (step && step === IGetCertifiedStep.UPLOAD_LCAS))
									? "auto"
									: 0,
							flexDirection: "column",
							gap: 2,
							overflow: "hidden",
							alignSelf: "start",
							flex: 1,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: 2,
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									marginTop: 2,
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										alignItems: "flex-start",
									}}
								>
									<span
										style={{
											textTransform: "capitalize",
											fontSize: 14,
											marginBottom: "10px",
											fontWeight: 700,
										}}
									>
										{t("lcaTable.uploadYourLca")}
										<span style={{ color: "#B61313" }}>*</span>
									</span>
									<Box sx={{ width: { sx: 300, md: 500 } }}>
										<UploadFile
											key={row.id}
											padding={-1}
											flex={1}
											accept=".pdf,.docx,.doc"
											value={row.lca_document}
											onFileChange={(file) => {
												//if (file) {
												const auxRows = [...rows];
												auxRows[index].lca_document = file ?? undefined;
												setRows(auxRows);
												//}
											}}
											id={row.id}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			))}

			<BasicModal
				width={800}
				showModal={showLCARecommendations}
				handleClose={() => {
					setLCARecommendations(false);
				}}
				children={
					<LCARecommendations setLCARecommendations={setLCARecommendations} />
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showLCARejectedModal}
				handleClose={() => {
					setShowLCARejectedModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("lcaTable.note")}
							<br />
							<br />
							<b>{rejectedReason ?? t("lcaTable.invalidLca")}</b>
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									setShowLCARejectedModal(false);
								}}
							>
								{t("general.ok")}
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={600}
				showModal={showConfirmationModal}
				handleClose={() => {
					setShowConfirmationModal(false);
				}}
				children={
					<Box
						sx={{
							width: "100%",
							textAlign: "center",
							padding: 4,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h5"
							sx={{ fontWeight: "bold", marginBottom: 2, maxWidth: 500 }}
						>
							{t("lcaTable.newLCA")} {selectedLCA ? t("lcaTable.data") : ""}
						</Typography>

						<Typography variant="body1" sx={{ marginBottom: 4, maxWidth: 500 }}>
							{rejectedReason
								? numberOfRejections
									? t("lcaTable.rejectionsWithCharge", {
											count: numberOfRejections + 1,
									  })
									: t("lcaTable.firstChargeNotice")
								: selectedLCA &&
								  (selectedLCA.lca_versions ?? []).length > 0 &&
								  selectedLCA!.lca_versions![0].certification_process &&
								  selectedLCA!.lca_versions![0].certification_process.status !==
										"COMPLETED"
								? t("lcaTable.updatingCurrentVersion")
								: t("lcaTable.newCertificationProcess")}
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								gap: 2,
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									minHeight: 34,
								}}
								onClick={() => {
									setShowConfirmationModal(false);
								}}
							>
								{t("general.cancel")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									minHeight: 34,
								}}
								onClick={() => {
									setShowConfirmationModal(false);
									bulkLCAs(true);
								}}
							>
								{t("general.ok")}
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
