import { PropTypes } from "./MenuItem.types";
import { Box, Typography, Button } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/user";
import { BasicModal } from "../BasicModal";
import { DataContext } from "../../contexts/data";
import { useTranslation } from "react-i18next";

export function MenuItem({ title, icon, selected, link, callback }: PropTypes) {
	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const { isEditing, setIsEditing } = useContext(DataContext);

	const navigate = useNavigate();

	const blockedLinks = [
		"/certifications/",
		"/sales/",
		"/users/",
		"/notifications/",
	];

	const [showEditingModal, setShowEditingModal] = useState(false);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					cursor: "pointer",
				}}
				onClick={() => {
					if (callback) {
						callback();
					}
					if (
						!user?.is_superuser &&
						!user?.company?.historically_approved &&
						blockedLinks.find((blockedLink) => blockedLink === link)
					) {
					} else if (isEditing) {
						setShowEditingModal(true);
					} else {
						setIsEditing(false);
						navigate(link);
					}
				}}
			>
				<Box
					sx={{
						height: "50px",
						width: 5,
						backgroundColor: selected ? "#25406D" : "transparent",
						marginLeft: "-16px",
					}}
				></Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						padding: 2,
						alignItems: "center",
						flex: 1,
						marginLeft: 2,
					}}
				>
					{icon}

					<Typography
						color={selected ? "#25406D" : "inherit"}
						sx={{ fontWeight: selected ? 700 : 400 }}
						variant="body2"
					>
						{title}
					</Typography>
				</Box>
			</Box>
			<BasicModal
				width={600}
				showModal={showEditingModal}
				handleClose={() => {
					setShowEditingModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							{t("menuItems.unsavedChanges")}
						</Typography>
						<Typography sx={{ textAlign: "center", marginBottom: 2 }}>
							{t("menuItems.unsavedWarning")}
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								gap: 2,
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
								}}
								onClick={() => {
									setShowEditingModal(false);
									setIsEditing(false);
									navigate(link);
								}}
							>
								{t("menuItems.navigateAnyway")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
								}}
								onClick={() => {
									setShowEditingModal(false);
								}}
							>
								{t("menuItems.continueEditing")}
							</Button>
						</Box>
					</Box>
				}
			/>
		</>
	);
}
